/* eslint-disable no-nested-ternary */
import { SortingRule } from 'react-table';
import axios from './index';

const getScheduledReports = (
  insOid: string,
  insCode: string,
  companyType: string,
  year: string,
) =>
  axios.get('sharepoint/getReportList', {
    params: {
      insOid,
      insCode,
      companyType,
      year,
    },
  });

const getOnDemandReports = (
  type: string,
  insOid: string,
  insCode: string,
  companyType: string,
  year?: string,
  pageSize?: number,
  page?: number,
  sortBy?: SortingRule<any>,
) =>
  axios.get(`sharepoint/reports/on-demand/${type}`, {
    params: {
      insOid,
      insCode,
      companyType,
      year,
      sortBy: sortBy?.id,
      sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      pageSize,
      page,
    },
  });

const getOnDemandReportsYear = (
  type: string,
  insOid: string,
  insCode: string,
  companyType: string,
) =>
  axios.get(`sharepoint/reports/on-demand/${type}/years`, {
    params: {
      insOid,
      insCode,
      companyType,
    },
  });

const downloadReports = (data: string[]) =>
  axios.post('sharepoint/reports/on-demand/download', data, {
    responseType: 'blob',
  });

export default {
  getScheduledReports,
  getOnDemandReports,
  getOnDemandReportsYear,
  downloadReports,
};

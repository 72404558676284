/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  makeStyles,
  createStyles,
  Theme,
  Grid,
  Tabs,
  Tab,
  Container,
} from '@material-ui/core';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ReactGA from 'react-ga';
import { RootState } from '../../../store';
import TabPanel, { a11yProps } from '../../../components/TabPanel';
import TabListOfEntities from './TabListOfEntities';
import './EntityManagementTab.css';
import Entity from '../../../models/Entity';
import RecentDocuments from './RecentDocuments';
import { selectCurrentEntity } from './duck/selector';
import SelectEntityDialog from './SelectEntityDialog';
import HeaderGroup from '../../../components/HeaderGroup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //   flexGrow: 1,
    },
    headerContainer: {
      backgroundColor: '#fff',
      padding: '0 16px',
    },
    contentContainer: {
      padding: '28px 32px',
    },
    companyConatiner: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    company: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      color: '#262626',
    },
    incorpatedIn: {
      fontSize: '1.2rem',
      fontWeight: 500,
      color: '#b4b4b4',
    },
    wrapper: {
      flexDirection: 'row',
      '& svg': {
        marginBottom: '0px !important',
        marginRight: 6,
      },
    },
    indicator: {
      display: 'none',
    },
  }),
);

const TABS = [
  { label: 'list_of_entities', icon: FormatAlignJustifyIcon },
  // { label: 'my_requests', icon: ListIcon },
  { label: 'recent_documents', icon: NotificationsIcon },
];

interface Props {
  currentEntity?: Entity;
}

export const EntityManagementTab: React.FC<Props> = ({ currentEntity }) => {
  const classes = useStyles();
  const { t } = useTranslation('entity_management');
  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const tabNames = [
      'list-of-entities',
      // 'my-requests',
      'recent-documents',
    ];
    ReactGA.pageview(`/entity-management/${tabNames[value]}`);
  }, [value]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.headerContainer}>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <div className={classes.company}>
            <div>{currentEntity?.name}</div>
            <div className={classes.incorpatedIn}>
              {t('incorpated_in', {
                country: currentEntity?.jurisdiction
                  ? t(
                      currentEntity.jurisdiction
                        .replace(/\s/g, '_')
                      .toLowerCase() || '',
                  )
                  : '',
              })}
            </div>
          </div>
          <SelectEntityDialog />
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <HeaderGroup />
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            aria-label="scrollable auto tabs"
            classes={{ indicator: classes.indicator }}
          >
            {TABS.map((item, index) => (
              <Tab
                {...a11yProps(index)}
                key={item.label}
                value={index}
                label={t(item.label)}
                disableFocusRipple
                disableTouchRipple
                disableRipple
                classes={{ wrapper: classes.wrapper }}
                className={index === value ? 'filetab selected' : 'filetab'}
              />
            ))}
          </Tabs>
        </Grid>
      </Grid>
      <Container
        maxWidth="xl"
        disableGutters
        className={classes.contentContainer}
      >
        <TabPanel value={value} index={0}>
          <TabListOfEntities />
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
        <MyRequests />
      </TabPanel> */}
        <TabPanel value={value} index={1}>
          <RecentDocuments />
        </TabPanel>
      </Container>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentEntity: selectCurrentEntity(state),
});

export default connect(mapStateToProps)(EntityManagementTab);

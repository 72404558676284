/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-wrap-multilines */
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import AuthService from '../../api/auth';
import LoginContainer from './LoginContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: '100%',
      marginTop: 25,
      fontSize: '1.8rem',
      fontWeight: 600,
      color: theme.palette.common.white,
      textTransform: 'capitalize',
      borderRadius: 7,
      backgroundColor: '#0909B7',
      '&:hover': {
        backgroundColor: '#001A5B',
      },
    },
    label: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      color: '#000',
    },
    description: {
      fontSize: '1.2rem',
      lineHeight: 1.17,
      color: '#575757',
      margin: '10px 0 18px 0',
    },
  }),
);

interface ResetPasswordValues {
  email: string;
}

const ForgetPassword: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [resetSuccess, setResetSuccess] = React.useState(false);
  const [reCaptchaToken, setReCaptchaToken] = React.useState<string>();

  const onVerify = React.useCallback((token: string) => {
    setReCaptchaToken(token);
  }, []);

  const validate = (values: ResetPasswordValues) => {
    const errors: Partial<ResetPasswordValues> = {};
    if (!values.email) errors.email = 'Required 必须填写';
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email))
      errors.email = 'Invalid email address 电邮地址无效';

    if (!reCaptchaToken) errors.email = 'Required 必须填写';
    return errors;
  };

  return (
    <LoginContainer canBack>
      {resetSuccess ? (
        <>
          <div className={classes.label}>Reset Password 重置密码</div>
          <div className={classes.description}>
            {t('login:reset_password_success_description')}
          </div>
        </>
      ) : (
        <Formik
          initialValues={{ email: '' }}
          validate={validate}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            if (!reCaptchaToken) return;
            AuthService.verifyReCaptcha(reCaptchaToken)
              .then(() => AuthService.resetPassword(values.email))
              .then(() => {
                setResetSuccess(true);
              })
              .catch((e) => {
                if (e.response.data?.errorCode === 10007)
                  setErrors({ email: 'Wrong Captcha' });
                else setResetSuccess(true);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <>
              <div className={classes.label}>Reset Password 重置密码</div>
              <div className={classes.description}>
                {
                  'Enter your registered email below to receive password reset instruction\n请在下框输入您的登记电邮以收取密码重置的指示'
                }
              </div>
              <Form>
                <Field fullWidth component={TextField} name="email" />
                <div>
                  <GoogleReCaptcha onVerify={onVerify} />
                </div>
              </Form>
              <Button
                type="submit"
                variant="contained"
                disableElevation
                disableFocusRipple
                disableRipple
                className={classes.button}
                onClick={submitForm}
                disabled={isSubmitting}
              >
                Submit 提交
              </Button>
            </>
          )}
        </Formik>
      )}
    </LoginContainer>
  );
};

export default ForgetPassword;

/* eslint-disable react/jsx-wrap-multilines */
import { Button, Container, Grid, Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { issuerPortal, loginBg } from '../../assets';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      minHeight: '100vh',
      backgroundColor: '#f4f5f5',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    gridContainer: {
      height: '100vh',
      [theme.breakpoints.down('xs')]: {
        height: 'unset',
      },
    },
    grid: {
      display: 'flex',
      justifyContent: 'center',
    },
    square: {
      width: '44vw',
      minHeight: '44vw',
      [theme.breakpoints.up('lg')]: {
        width: '36vw',
        minHeight: '36vw',
      },
      [theme.breakpoints.down('xs')]: {
        margin: '0 5% 0 5%',
        width: '90vw',
        minHeight: '90vw',
      },
    },
    squareLeft: {
      float: 'right',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#f4f5f5',
    },
    logo: {
      width: '30%',
      cursor: 'pointer',
    },
    issuerPortal: {
      width: '40%',
      cursor: 'pointer',
    },
    loginBg: {
      width: '100%',
    },
    title: {
      fontSize: '2rem',
      lineHeight: 1.2,
      color: '#454545',
      marginBottom: 20,
      [theme.breakpoints.up('lg')]: {
        fontSize: '3rem',
      },
    },
    formContainer: {
      width: '80%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      justifyContent: 'center',
    },
    forgetPassword: {
      alignSelf: 'flex-end',
      color: '#B2B2B2',
      textDecoration: 'unset',
      fontStyle: 'italic',
      fontSize: '1.4rem',
      marginTop: 8,
    },
    button: {
      width: '100%',
      marginTop: 25,
      fontSize: '1.8rem',
      fontWeight: 600,
      color: theme.palette.common.white,
      textTransform: 'capitalize',
      borderRadius: 7,
      backgroundColor: theme.palette.text.hint,
      '&:hover': {
        backgroundColor: theme.palette.text.hint,
      },
    },
    buttonBack: {
      alignSelf: 'flex-start',
      '&:hover': {
        backgroundColor: 'unset',
      },
      '& svg': {
        height: 12,
        width: 12,
      },
    },
    back: {
      width: 'unset',
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: 1.2,
      color: '#454545',
      marginBottom: 48,
      textTransform: 'capitalize',
    },
  }),
);

interface Props {
  children: React.ReactNode;

  canBack?: boolean;

  toPath?: string;
}

const LoginContainer: React.FC<Props> = ({ children, canBack, toPath }) => {
  const { t } = useTranslation('login');
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container className={classes.root} disableGutters maxWidth={false}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-evenly"
        className={classes.gridContainer}
        spacing={3}
      >
        <Grid item xs={12} sm={6} lg={5}>
          <Paper
            elevation={0}
            className={clsx(classes.square, classes.squareLeft)}
          >
            <img
              src={issuerPortal}
              className={classes.issuerPortal}
              alt="Vistra logo"
              onClick={() => history.replace('/')}
            />
            {/* <img
              src={issuerPortal}
              className={classes.issuerPortal}
              alt="tricor logo"
              onClick={() => history.replace('/')}
            /> */}
            <div className="f1" />
            <img
              src={loginBg}
              className={classes.loginBg}
              alt="login background"
            />
            {/* <div className={classes.title}>{t('title')}</div> */}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} lg={5} className={classes.grid}>
          <Paper className={classes.square}>
            <div className={classes.formContainer}>
              {canBack && (
                <Button
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                  aria-label="back"
                  onClick={() =>
                    toPath ? history.replace('/') : history.goBack()
                  }
                  startIcon={
                    <ArrowBackIosRoundedIcon
                      fontSize="small"
                      htmlColor="#454545"
                    />
                  }
                  classes={{ root: classes.buttonBack, label: classes.back }}
                >
                  Go Back 返回
                </Button>
              )}
              {children}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LoginContainer;

import { createContext, Dispatch } from 'react';
import { CompanyUser } from '../../../../models/CompanyUser';

export interface UserReducerState {
  user?: CompanyUser;
  application?: any;
}

export interface SetUserAction {
  type: 'SET_USER';
  payload: { user: CompanyUser };
}

export interface ContextProps {
  state: UserReducerState;
  dispatch: Dispatch<any>;
}

export const userReducer = (state: UserReducerState, action: any) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'SET_APPLICATION':
      return {
        ...state,
        application: action.payload.application,
      };

    default:
      return state;
  }
};

export const UserReducerContext = createContext<ContextProps>(
  {} as ContextProps,
);

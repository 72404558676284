/* eslint-disable react/jsx-wrap-multilines */
import {
  Checkbox,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  DialogContent as MuiDialogContent,
  Theme,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AdminService from '../../../api/admin';
import { DialogActions, DialogTitle } from '../../../components/Dialog';
import { CompanyUser } from '../../../models/CompanyUser';

export const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    minHeight: 426,
  },
  searchContainer: {
    padding: 24,
    backgroundColor: '#fff',
    borderBottom: 'solid 1px #cccccc',
  },
  searchInput: {
    width: '100%',
    border: '0.5px solid #707070',
    borderRadius: 8,
    padding: 8,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 24px',
    fontSize: 14,
    lineHeight: 1.7,
    color: '#575757',
  },
  bottomContainer: {
    width: '100%',
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderTop: 'solid 1px #cccccc',
  },
  buttonSelect: {
    backgroundColor: '#0909B7',
    marginRight: 48,
    '&:hover': {
      backgroundColor: '#001A5B',
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelSelect: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  buttonCancel: {
    backgroundColor: '#0909B7',
    '&:hover': {
      backgroundColor: '#001A5B',
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelCancel: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
  },
  error: {
    textAlign: 'center',
    backgroundColor: '#0909B7',
    color: '#fff',
    padding: '4px 0',
  },
  nameColumn: {
    flex: 1,
    flexDirection: 'column',
    padding: '8px 0',
  },
  name: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    marginBottom: 4,
  },
  email: {
    fontSize: '1.2rem',
  },
  pagination: {
    margin: '12px 0',
    alignSelf: 'center',
    '& .MuiPaginationItem-root': {
      fontSize: '1rem',
      fontWeight: 'bold',
      borderRadius: 4,
      '&.Mui-selected': {
        backgroundColor: '#262626',
        color: '#fff',
      },
    },
  },
  checkboxContainer: {
    minWidth: 'unset',
  },
}));

interface Props {
  buttonText: string;
  currentUsers: CompanyUser[];
  onSave: (
    users: { [userId: string]: boolean },
    onSuccess: () => void,
    onError: () => void,
  ) => void;
}

const SelectUserDialog: React.FC<Props> = ({
  buttonText,
  currentUsers,
  onSave,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('manage_company');
  const [open, setOpen] = React.useState(false);

  const [users, setUsers] = React.useState<CompanyUser[]>([]);
  const [pageCount, setPageCount] = React.useState(0);
  const [pageIndex, setPageIndex] = React.useState(1);
  const fetchIdRef = React.useRef(0);

  const [query, setQuery] = React.useState('');
  const [selectedUsers, setSelectedUsers] = React.useState<{
    [userId: string]: boolean;
  }>({});
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    if (open) {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      AdminService.getCompanyUsers(10, pageIndex, query).then((res) => {
        if (fetchId === fetchIdRef.current) {
          setUsers(res.data);
          setPageCount(res.totalPage);
          setLoading(false);
        }
      });
    }
  }, [open, query, pageIndex]);

  const onExited = () => {
    setPageCount(0);
    setPageIndex(1);
    setUsers([]);
    setSelectedUsers({});
    setError(undefined);
    setQuery('');
    setLoading(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleToggle = (user: CompanyUser) => {
    const checked = selectedUsers[user.id];
    const defaultChecked =
      currentUsers.findIndex((item) => item.id === user.id) !== -1;
    if (defaultChecked) {
      if (checked === undefined) {
        setSelectedUsers({ ...selectedUsers, [user.id]: false });
      } else if (checked === false) {
        const newSelectedUser = { ...selectedUsers };
        delete newSelectedUser[user.id];
        setSelectedUsers(newSelectedUser);
      }
    } else if (checked) {
      const newSelectedUser = { ...selectedUsers };
      delete newSelectedUser[user.id];
      setSelectedUsers(newSelectedUser);
    } else {
      setSelectedUsers({ ...selectedUsers, [user.id]: true });
    }
  };

  const onClickSelect = () => {
    onSave(
      selectedUsers,
      () => {
        handleClose();
      },
      () => {
        setLoading(false);
        setError('Assign users fail.');
      },
    );
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        endIcon={<AddRoundedIcon />}
        className="button-create"
        onClick={() => setOpen(true)}
      >
        {buttonText}
      </Button>
      <Dialog
        onClose={handleClose}
        onExited={onExited}
        aria-labelledby="select-users-title"
        open={open}
      >
        <DialogTitle id="select-users-title" onClose={handleClose}>
          {t('select_users')}
        </DialogTitle>
        <div className={classes.searchContainer}>
          <Input
            disableUnderline
            className={classes.searchInput}
            placeholder="Search"
            inputProps={{ 'aria-label': 'description' }}
            startAdornment={
              <InputAdornment position="start">
                <SearchRoundedIcon htmlColor="#707070" />
              </InputAdornment>
            }
            onChange={(event) => setQuery(event.target.value)}
          />
        </div>
        <DialogContent className={classes.content}>
          <List>
            {users.map((user) => {
              const labelId = `checkbox-list-label-${user.id}`;
              const checked =
                selectedUsers[user.id] ||
                (selectedUsers[user.id] === undefined &&
                  currentUsers.findIndex((item) => item.id === user.id) !== -1);
              return (
                <ListItem
                  key={user.id}
                  role={undefined}
                  dense
                  button
                  onClick={() => handleToggle(user)}
                >
                  <div className={classes.nameColumn}>
                    <div className={classes.name}>{user.name}</div>
                    <div className={classes.email}>{user.username}</div>
                  </div>
                  <ListItemIcon className={classes.checkboxContainer}>
                    <Checkbox
                      checked={checked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <Pagination
          count={pageCount}
          defaultPage={1}
          page={pageIndex}
          showFirstButton
          showLastButton
          onChange={(e, value) => setPageIndex(value)}
          size="small"
          className={classes.pagination}
        />
        {error && <div className={classes.error}>{error}</div>}
        <DialogActions disableSpacing>
          <div className={classes.bottomContainer}>
            <Button
              variant="contained"
              color="primary"
              classes={{
                root: classes.buttonSelect,
                label: classes.labelSelect,
              }}
              onClick={onClickSelect}
              disabled={Object.keys(selectedUsers).length === 0 || loading}
            >
              {t('common:select')}
            </Button>
            <Button
              variant="outlined"
              classes={{ label: classes.labelCancel }}
              onClick={handleClose}
              disabled={loading}
            >
              {t('common:cancel')}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SelectUserDialog;

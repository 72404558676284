import {
  configureStore,
  getDefaultMiddleware,
  combineReducers,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import {
  persistReducer,
  createTransform,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import ReactGA from 'react-ga';

import AuthReducer, {
  SliceState as AuthState,
  logout,
} from '../pages/auth/duck/authDuck';
import EntityReducer from '../pages/client/entityManagement/duck/entityDuck';
import SettingsReducer, {
  SliceState as SettingsState,
} from '../pages/settings/duck';

import axios from '../api';
import i18n from '../locales';

const persistToken = createTransform(
  (inboundState) => inboundState,
  (outboundState: AuthState) => {
    const { token, companyUser, staff } = outboundState;
    if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    if (companyUser?.username) {
      ReactGA.set({ userId: companyUser?.username });
    } else if (staff?.mail) {
      ReactGA.set({ userId: staff?.mail });
    }
    return outboundState;
  },
  { whitelist: ['auth'] },
);

const persistLang = createTransform(
  (inboundState) => inboundState,
  (outboundState: SettingsState) => {
    const { lang } = outboundState;
    i18n.changeLanguage(lang);
    return outboundState;
  },
  { whitelist: ['settings'] },
);

const encryptor: any = createEncryptor({
  secretKey: 'tricor',
  onError(error) {
    // Handle the error.
  },
});

const rootReducer = combineReducers({
  auth: AuthReducer,
  entity: EntityReducer,
  settings: SettingsReducer,
});

const persistedReducer = persistReducer(
  {
    key: 'tricor',
    version: 1,
    storage,
    transforms: [persistToken, encryptor, persistLang],
  },
  rootReducer,
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { status, config } = error.response;
      if (
        status === 401 &&
        config.url !== '/auth/otp' &&
        config.url !== '/auth/otp/verify' &&
        config.url !== 'auth/_logout' &&
        store.getState().auth.token
      )
        store.dispatch(logout());
    }
    return Promise.reject(error);
  },
);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;

/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  createStyles,
  Dialog,
  Grid,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosRounded';
import { Field, Formik, FormikHelpers } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AdminService from '../../../api/admin';
import { boardfolio1 } from '../../../assets';
import { DialogContent, DialogTitle } from '../../../components/Dialog';
import { useAppDispatch } from '../../../store';
import { makerRequestSuccess } from '../../auth/duck/authDuck';
import { selectStaffInfo, selectUserInfo } from '../../auth/duck/selector';
import { selectLang } from '../../settings/duck/selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //   flexGrow: 1,
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    backContainer: {
      padding: '0 !important',
    },
    backButton: {
      fontSize: '1.2rem',
      color: '#707070',
      textTransform: 'none',
      '& svg': {
        fontSize: '1.25rem !important',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        opacity: 1,
      },
    },
    name: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      color: '#262626',
      paddingBottom: '0px !important',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.16)',
      backgroundColor: '#ffffff',
      '& span': {
        fontSize: 12,
        color: '#262626',
      },
      '& svg': {
        fill: '#0909B7',
      },
    },

    errorMessage: {
      fontSize: '1.4rem',
      lineHeight: 1.43,
      textAlign: 'center',
      color: '#707070',
      whiteSpace: 'pre-line',
      margin: '24px 0',
    },
  }),
);

interface FormValues {
  entityManagement: boolean;
  registerOfMember: boolean;
  ccassHolding: boolean;
  reports: boolean;
  ownershipAnalysis: boolean;
  industryAnalysis: boolean;
  eAGM: boolean;
  eProxy: boolean;
  boardPortal: boolean;
  marketIntelligence: boolean;
  invoiceManagement: boolean;
  thoughtLeadership: boolean;
  makerCheckerFunc: boolean;
  checkAcctNoLimit: boolean;
  acctNoLimitation: number;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ManageCompanyAccess: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('manage_company_access');
  const [data, setData] = React.useState<any>();
  const { id } = useParams();
  let query = useQuery();
  const staffInfo = useSelector(selectStaffInfo);
  const userInfo = useSelector(selectUserInfo);

  const history = useHistory();

  const lang = useSelector(selectLang);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    AdminService.getCompanyAccess({
      id,
      tickerNo: query.get('tickerNo'),
      romOid: query.get('romOid'),
    }).then((res) => {
      setData(res);
    });
  }, [id, query]);

  const validate = (values: FormValues) => {
    const errors: { [name: string]: any } = {};

    return errors;
  };

  const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    AdminService.updateCompanyAccess(data.id, values)
      .then(() => {
        console.log('success');
        dispatch(makerRequestSuccess());
      })
      .catch((e) => {
        let errorMsgKey = 'message';
        if (lang === 'zh') errorMsgKey = 'messageZH';
        else if (lang === 'cn') errorMsgKey = 'messageCN';
        setError(e.response.data[errorMsgKey]);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const disableEdit = staffInfo?.role === 'TricorChecker';
  const isClientChecker = userInfo?.role === 'ClientChecker';

  return (
    <div className={classes.root}>
      <Dialog
        onClose={() => setError('')}
        aria-labelledby="error-dialog-title"
        open={Boolean(error)}
      >
        <DialogTitle id="error-dialog-title" onClose={() => setError('')}>
          Error
        </DialogTitle>
        <DialogContent>
          <div className={classes.errorMessage}>{error}</div>
        </DialogContent>
      </Dialog>
      {data && (
        <Formik
          initialValues={data}
          validate={validate}
          onSubmit={onSubmit}
          validateOnBlur
        >
          {({ submitForm, isSubmitting, setFieldValue, values }) => (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.backContainer}>
                  <Button
                    className={classes.backButton}
                    startIcon={<ArrowBackIcon htmlColor="#707070" />}
                    disableFocusRipple
                    disableTouchRipple
                    disableRipple
                    onClick={() => history.goBack()}
                  >
                    {t('back')}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={8} className={classes.name}>
                  <div>{data?.companyName}</div>
                </Grid>
                <Grid container item xs={12} direction="row" spacing={2}>
                  {!isClientChecker && (
                    <Grid item xs={6}>
                      <div className={classes.column}>
                        <div className="section-container">
                          <img
                            src={boardfolio1}
                            className="section-icon"
                            alt=""
                          />
                          <span className="section-title f1">
                            Access Rights
                          </span>
                        </div>
                      </div>
                      <Paper className={classes.paper}>
                        <Field
                          component={CheckboxWithLabel}
                          name="entityManagement"
                          Label={{ label: t('tabbar:entity_management') }}
                          type="checkbox"
                          disabled={disableEdit}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          name="registerOfMember"
                          Label={{ label: t('tabbar:register_of_members') }}
                          type="checkbox"
                          disabled={disableEdit}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          name="ccassHolding"
                          Label={{ label: t('tabbar:ccass_holding') }}
                          type="checkbox"
                          disabled={disableEdit}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          name="reports"
                          Label={{ label: t('tabbar:reports') }}
                          type="checkbox"
                          disabled={disableEdit}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          name="ownershipAnalysis"
                          Label={{ label: t('tabbar:shareholder_analytics') }}
                          type="checkbox"
                          disabled={disableEdit}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          name="marketIntelligence"
                          Label={{ label: t('tabbar:market_intelligence') }}
                          type="checkbox"
                          disabled={disableEdit}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          name="industryAnalysis"
                          Label={{ label: t('tabbar:industry_analyst_report') }}
                          type="checkbox"
                          disabled={disableEdit}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          name="eAGM"
                          Label={{ label: t('tabbar:eagm') }}
                          type="checkbox"
                          disabled={disableEdit}
                        />
                        {/* <Field
                        component={CheckboxWithLabel}
                        name="eProxy"
                        Label={{ label: t('tabbar:eproxy') }}
                        type="checkbox"
                        disabled={disableEdit}
                      /> */}
                        <Field
                          component={CheckboxWithLabel}
                          name="boardPortal"
                          Label={{ label: t('tabbar:board_portal') }}
                          type="checkbox"
                          disabled={disableEdit}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          name="invoiceManagement"
                          Label={{ label: t('tabbar:invoice_management') }}
                          type="checkbox"
                          disabled={disableEdit}
                        />
                        <Field
                          component={CheckboxWithLabel}
                          name="thoughtLeadership"
                          Label={{ label: t('tabbar:thought_leadership') }}
                          type="checkbox"
                          disabled={disableEdit}
                        />
                      </Paper>
                      {!disableEdit && (
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="button-create"
                          style={{ marginTop: 16, minWidth: 125 }}
                          onClick={submitForm}
                          disabled={isSubmitting}
                        >
                          {t('common:save')}
                        </Button>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <div className={classes.column}>
                      <div className="section-container">
                        <Settings className="section-icon" />
                        <span className="section-title f1">Settings</span>
                      </div>
                    </div>
                    <Paper className={classes.paper}>
                      <Field
                        component={CheckboxWithLabel}
                        name="makerCheckerFunc"
                        Label={{ label: t('maker_checker_function') }}
                        type="checkbox"
                      />
                      {!isClientChecker && (
                        <>
                          <Field
                            component={CheckboxWithLabel}
                            name="checkAcctNoLimit"
                            Label={{ label: t('max_number_of_acc') }}
                            type="checkbox"
                          />
                          <Field
                            component={TextField}
                            name="acctNoLimitation"
                            type="number"
                            inputProps={{ disabled: !values.checkAcctNoLimit }}
                            style={{ margin: '0 8px', width: 80 }}
                            onBlur={(e: any) => {
                              const count = parseInt(e.target.value, 10);
                              if (isNaN(count) || count <= 0) {
                                setFieldValue(e.target.name, 50);
                              }
                            }}
                          />
                        </>
                      )}
                    </Paper>
                    {isClientChecker && !disableEdit && (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="button-create"
                        style={{ marginTop: 16, minWidth: 125 }}
                        onClick={submitForm}
                        disabled={isSubmitting}
                      >
                        {t('common:save')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      )}
    </div>
  );
};

export default ManageCompanyAccess;

import { format } from 'date-fns';
import axios from './index';

const getTopNHolders = (
  insOid: string,
  insCode: string,
  companyType: string,
  targetDate: string,
  count: number,
) =>
  axios.get('ccass/holders', {
    params: {
      insOid,
      insCode,
      companyType,
      count,
      targetDate,
    },
  });

const getShareHoldingByHolders = (
  insOid: string,
  insCode: string,
  companyType: string,
  targetDate: string,
) =>
  axios.get('ccass/shareholding', {
    params: {
      insOid,
      insCode,
      companyType,
      targetDate,
    },
  });

const getSouthboundHolding = (
  insOid: string,
  insCode: string,
  companyType: string,
  startDate: string,
  endDate: string,
) =>
  axios.get('ccass/southboundHolding', {
    params: {
      insOid,
      insCode,
      companyType,
      startDate,
      endDate,
    },
  });

const getTopNDailyChange = (
  insOid: string,
  insCode: string,
  companyType: string,
  targetDate: string,
  count: number,
) =>
  axios
    .get('ccass/dailyChanges', {
      params: {
        insOid,
        insCode,
        companyType,
        targetDate,
        count,
      },
    })
    .then(({ data }) => {
      const holdings = [...data];
      if (data.length === 0) return [];
      while (holdings.length < 10) {
        holdings.push({
          participantName: 'N/A',
        });
      }
      return holdings;
    });

const getTopNHoldersThreshold = () =>
  axios.get('ccass/topHoldersThreshold').then((res) => res.data);

export default {
  getTopNHolders,
  getShareHoldingByHolders,
  getSouthboundHolding,
  getTopNDailyChange,
  getTopNHoldersThreshold,
};

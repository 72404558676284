/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
import { SortingRule } from 'react-table';
import { Article } from '../models/Article';
import { PaginationResponse } from '../models/PaginationResponse';
import axios from './index';

export interface CreateArticleValue {
  title: string;
  description: string;
  webLink: string;
  isFeatured: boolean;
  imageUrl: string;
  imageFile?: File;
}

const getThoughtLeadership = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
) => {
  return axios
    .get<PaginationResponse<Article>>('leadership', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: 'updatedAt',
        sortOrder: 'desc',
      },
    })
    .then((res) => res.data);
};

const createArticle = (params: CreateArticleValue) =>
  axios.post('leadership', { ...params });

const editArticle = (articleId: string | number, params: any) =>
  axios.put(`leadership/${articleId}`, { ...params });

const deleteArticle = (articleId: string | number) =>
  axios.delete(`leadership/${articleId}`);

export default {
  getThoughtLeadership,
  createArticle,
  editArticle,
  deleteArticle,
};

/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
import {
  AppBar,
  Container,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { isBoolean } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import {
  boardPortal,
  ccass,
  eagm,
  entityManagement,
  eproxy,
  home,
  iam,
  industryAnalystReport,
  industryAnalystReportPlaceholder,
  invoiceManagement,
  marketIntelligence,
  marketIntelligencePlaceholder,
  registerOfMember,
  reports,
  shareholderAnalytics,
} from '../../assets';
import AskFeedbackModal from '../../components/AskFeedbackModal';
import Drawer from '../../components/Drawer';
import TimeoutDialog from '../../components/TimeoutDialog';
import { AccessRights, CompanyUser } from '../../models/CompanyUser';
import { ListedCompany } from '../../models/ListedCompany';
import { AppDispatch, RootState } from '../../store';
import { logout as ActionLogout, getProfile } from '../auth/duck/authDuck';
import {
  selectIsAuthorized,
  selectStaffInfo,
  selectUserAccessRights,
  selectUserInfo,
} from '../auth/duck/selector';
import Boardfolio from './boardfolio/Boardfolio';
import CcassHolding from './ccassHolding';
import ContactUs from './ContactUs';
import EAgm from './eagm/EAgm';
import EntityManagementTab from './entityManagement/EntityManagementTab';
import EProxy from './eproxy/EProxy';
import Home from './home';
import RegisterOfMembers from './registerOfMembers/RegisterOfMembers';
import Reports from './reports';
import ShareholderAnalytics from './shareholderAnalytics/ShareholderAnalytics';
import ThoughtLeadership from './thoughtLeadership';

const ROUTES = [
  {
    title: 'Main',
    data: [
      {
        key: 'home',
        path: '/',
        translateKey: 'home',
        icon: home,
        main: (props: any) => <Home {...props} />,
      },
      {
        key: 'registerOfMember',
        path: '/register-of-members',
        translateKey: 'register_of_members',
        icon: registerOfMember,
        main: (props: any) => <RegisterOfMembers {...props} />,
      },
      {
        key: 'ownershipAnalysis',
        path: '/shareholder-analytics',
        translateKey: 'shareholder_analytics',
        icon: shareholderAnalytics,
        main: (props: any) => <ShareholderAnalytics {...props} />,
      },
      {
        key: 'entityManagement',
        path: '/entity-management',
        translateKey: 'entity_management',
        icon: entityManagement,
        main: () => <EntityManagementTab />,
      },
      {
        key: 'ccassHolding',
        path: '/ccass-holding',
        translateKey: 'ccass_holding',
        icon: ccass,
        main: (props: any) => <CcassHolding {...props} />,
      },
      {
        key: 'reports',
        path: '/reports',
        translateKey: 'reports',
        icon: reports,
        main: (props: any) => <Reports {...props} />,
      },
    ],
  },
  {
    title: 'Meeting Management',
    data: [
      {
        key: 'eAGM',
        path: '/eagm',
        translateKey: 'eagm',
        icon: eagm,
        // iconRight: spotWhite,
        // iconRightOff: spot,
        main: () => <EAgm />,
      },
      {
        key: 'eProxy',
        path: '/eproxy',
        translateKey: 'eproxy',
        icon: eproxy,
        // iconRight: spotWhite,
        // iconRightOff: spot,
        main: () => <EProxy />,
      },
      {
        key: 'boardPortal',
        path: '/board-portal',
        translateKey: 'board_portal',
        icon: boardPortal,
        // iconRight: boardfolio,
        // iconRightOff: boardfolio,
        main: () => <Boardfolio />,
      },
    ],
  },
  {
    title: 'Analytics',
    data: [
      {
        key: 'marketIntelligence',
        path: '/market-intelligence',
        translateKey: 'market_intelligence',
        icon: marketIntelligence,
        main: () => (
          <ContactUs
            message="You Are Not Subscribed to Market Intelligence Services"
            image={marketIntelligencePlaceholder}
          />
        ),
      },
      {
        key: 'industryAnalysis',
        path: '/industry-analyst-report',
        translateKey: 'industry_analyst_report',
        icon: industryAnalystReport,
        main: () => (
          <ContactUs
            message="You Are Not Subscribed to Industry Analysis Services"
            image={industryAnalystReportPlaceholder}
          />
        ),
      },
    ],
  },
  {
    title: 'Others',
    data: [
      {
        key: 'thoughtLeadership',
        path: '/thought-leadership',
        translateKey: 'thought_leadership',
        icon: invoiceManagement,
        main: () => <ThoughtLeadership />,
      },
      {
        key: 'adminPortal',
        path: '/admin',
        translateKey: 'admin_portal',
        icon: iam,
        main: () => <div />,
      },
    ],
  },
];

const ROUTES_ARR = ROUTES.reduce(
  (acc: any[], route) => [...acc, ...route.data],
  [],
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    container: {
      flexGrow: 1,
      width: '100%',
    },
    appbar: {
      background: theme.palette.primary.dark,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 'unset',
      paddingTop: 8,
      overflowX: 'visible',
      overflowY: 'hidden',
      backgroundColor: '#f5f5f5',
    },
    contentMobile: {
      marginTop: 24,
      marginBottom: 24,
    },
    toolbar: theme.mixins.toolbar,
  }),
);

interface Props {
  isAuthorized: string | boolean;
  user?: CompanyUser | null;
  accessRights?: AccessRights;
  logout: () => void;
}

export const Dashboard: React.FC<Props> = ({
  isAuthorized,
  user,
  accessRights,
  logout,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const matched = useMediaQuery('(min-width:920px)');
  const history = useHistory();
  const { t } = useTranslation('tabbar');

  const [open, setOpen] = React.useState(true);
  const [company, setCompany] = React.useState<ListedCompany>();
  const [canSelectCompany, setCanSelectCompany] = React.useState(false);
  const isStaff = useSelector(selectStaffInfo);

  React.useEffect(() => {
    dispatch(getProfile());
  }, []);

  React.useEffect(() => {
    if (!matched && window.innerWidth < 920) setOpen(false);
  }, [matched]);

  const routes = React.useMemo(() => {
    if (isStaff) {
      return ROUTES.map((section) => ({
        ...section,
        data: section.data.filter((route) =>
          accessRights ? (accessRights as any)[route.key] : true,
        ),
      })).filter((section) => section.data.length > 0);
    }

    const combineAccessRight = accessRights
      ? Object.keys(accessRights).reduce((acc, key) => {
          if (isBoolean((accessRights as any)[key])) {
            let enabled = (accessRights as any)[key];
            if (!isStaff) {
              enabled =
                (accessRights as any)[key] &&
                (company as any)?.accessRights?.[key] !== false;
            }

            return {
              ...acc,
              [key]: enabled,
            };
          }
          return acc;
        }, [])
      : undefined;

    return ROUTES.map((section) => ({
      ...section,
      data: section.data.filter((route) =>
        combineAccessRight ? (combineAccessRight as any)[route.key] : true,
      ),
    })).filter((section) => section.data.length > 0);
  }, [accessRights, company]);

  if (!isAuthorized) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  const currentRoute = ROUTES_ARR.find((item) =>
    history.location.pathname.startsWith(item.path),
  );
  const title = currentRoute ? t(currentRoute.translateKey) : '';

  return (
    <div className={classes.root}>
      <TimeoutDialog msTimeout={1000 * 60 * 20} onIdle={logout} />
      <Drawer
        routes={routes}
        user={user}
        open={open}
        toggleDrawer={() => setOpen((state) => !state)}
      />
      {!matched && (
        <AppBar position="fixed" className={classes.appbar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              aria-label="menu"
              onClick={() => setOpen(true)}
            >
              <MenuIcon htmlColor="#fff" fontSize="large" />
            </IconButton>
            <Typography variant="h6">{title}</Typography>
          </Toolbar>
        </AppBar>
      )}
      <Container
        disableGutters
        className={clsx(classes.content, {
          [classes.contentMobile]: !matched,
        })}
      >
        <div className={clsx({ [classes.toolbar]: !matched })} />
        <Switch>
          {routes.reduce((acc: JSX.Element[], section) => {
            const data = section.data.map((route) => (
              <Route
                key={route.translateKey}
                path={route.path}
                exact
                // exact={route.exact}
                // eslint-disable-next-line react/no-children-prop
                children={
                  <route.main
                    setCompany={setCompany}
                    company={company}
                    canSelectCompany={canSelectCompany}
                    setCanSelectCompany={setCanSelectCompany}
                  />
                }
              />
            ));

            return [...acc, ...data];
          }, [])}
        </Switch>
      </Container>
      <AskFeedbackModal />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUserInfo(state),
  accessRights: selectUserAccessRights(state),
  isAuthorized: selectIsAuthorized(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  logout: () => dispatch(ActionLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

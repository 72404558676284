import { format, isSameMonth } from 'date-fns';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import cnLocale from 'date-fns/locale/zh-CN';
import zhLocale from 'date-fns/locale/zh-TW';
import { useSelector } from 'react-redux';
import { selectLang } from '../../settings/duck/selector';

interface Props {
  data: any;
  subdata: any;
  unit: 'day' | 'month';
}

const ChartSouthbound = ({ data, subdata, unit }: Props) => {
  const { t } = useTranslation('ccass_holding');
  const lang = useSelector(selectLang);

  return (
    <Bar
      data={{
        datasets: [
          {
            type: 'line',
            label: t('shareholding'),
            borderColor: '#d0dcec',
            borderWidth: 2,
            fill: true,
            backgroundColor: '#d0dcec',
            lineTension: 0.1,
            pointRadius: 0,
            data,
            yAxisID: 'first-y-axis',
          },
          {
            type: 'line',
            label: t('percent_holding'),
            borderColor: '#e84637',
            borderWidth: 2,
            backgroundColor: '#e84637',
            pointRadius: 0,
            data: subdata,
            yAxisID: 'second-y-axis',
            fill: false,
          },
        ],
      }}
      options={{
        legend: {
          position: 'bottom',
          onClick: () => {},
        },
        tooltips: {
          intersect: false,
          callbacks: {
            title(tooltipItem: any, dataItem: any) {
              const [{ datasetIndex, index }] = tooltipItem;
              return format(
                dataItem.datasets[datasetIndex].data[index].t,
                'dd/MM/yyyy',
              );
            },
            label(tooltipItem: any) {
              const { datasetIndex, value } = tooltipItem;
              return datasetIndex === 1
                ? `${value}%`
                : parseInt(value, 10).toLocaleString();
            },
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: { display: false, zeroLineWidth: 0 },
              ticks: {
                autoSkip: true,
                maxRotation: 90,
                minRotation: 90,
                callback(value: any, index: number, values: any) {
                  if (unit === 'month') {
                    return format(values[index].value, 'MMM yyyy', {
                      locale:
                        lang === 'cn'
                          ? cnLocale
                          : lang === 'zh'
                          ? zhLocale
                          : undefined,
                    });

                    // if (index === 0) {
                    //   return format(data[index].t, 'MMM yyyy', {
                    //     locale: cnLocale,
                    //   });
                    // }

                    // if (
                    //   !isSameMonth(
                    //     new Date(values[index].value),
                    //     new Date(values[index - 1].value),
                    //   )
                    // ) {
                    //   return format(new Date(values[index].value), 'MMM yyyy', {
                    //     locale: cnLocale,
                    //   });
                    // }

                    // return isSameMonth(
                    //   new Date(values[index].value),
                    //   new Date(values[index - 1].value),
                    // )
                    //   ? ' '
                    //   : format(new Date(values[index].value), 'MMM yyyy', {
                    //       locale: cnLocale,
                    //     });
                  }

                  return value;
                },
              },
              type: 'time',
              time: {
                displayFormats: {
                  day: 'DD/MM/yyyy',
                },
                unit,
              },
            },
          ],
          yAxes: [
            {
              id: 'first-y-axis',
              position: 'left',
              type: 'linear',
              gridLines: {
                zeroLineWidth: 0,
                fontColor: '#262626',
                fontStyle: 'bold',
              },
              ticks: {
                min: 0,
                fontColor: '#262626',
                fontStyle: 'bold',
                crossAlign: 'far',
                callback(value: any) {
                  return value.toLocaleString();
                },
              },
            },
            {
              id: 'second-y-axis',
              position: 'right',
              type: 'linear',
              gridLines: {
                zeroLineWidth: 0,
                fontColor: '#262626',
                fontStyle: 'bold',
                drawOnChartArea: false,
              },
              ticks: {
                min: 0,
                fontColor: '#262626',
                fontStyle: 'bold',
                callback(value: any) {
                  return `${value}%`;
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
      }}
    />
  );
};

export default ChartSouthbound;

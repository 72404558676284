import { makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import AdminService from '../../../api/admin';
import PaginationTable from '../../../components/PaginationTable';
import { CompanyApplication } from '../../../models/CompanyApplication';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 12,
    '& .react-table .td': {
      minHeight: 34,
      lineHeight: 'unset',
      height: 'unset',
      whiteSpace: 'break-spaces',
      display: 'flex',
      alignItems: 'center',
    },
  },
  titleIcon: {
    '& g': {
      '& g': {
        '& path': {
          fill: '#000 !important',
        },
      },
    },
  },
  buttonAccept: {
    backgroundColor: '#0909B7',
    color: '#fff',
    fontWeight: 600,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#001A5B',
    },
  },
  buttonReject: {
    backgroundColor: '#fff',
    color: '#0909B7',
    borderColor: '#0909B7',
    fontWeight: 600,
    textTransform: 'none',
  },
}));

const TabCompanyRejected: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('manage_applications');
  const history = useHistory();

  const [data, setData] = React.useState<CompanyApplication[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      AdminService.getCompanyRejectedApplications(
        pageSize,
        pageIndex,
        query,
        sortBy,
      ).then((res) => {
        if (fetchId === fetchIdRef.current) {
          setData(res.data);
          setPageCount(res.totalPage);
          setLoading(false);
        }
      });
    },
    [],
  );

  const COLUMNS = React.useMemo<Array<Column<CompanyApplication>>>(
    () => [
      {
        Header: 'manage_user:rom_stock_codes',
        accessor: 'insCode',
        disableFilters: true,
        maxWidth: 200,
      },
      {
        Header: 'manage_user:hk_ticker',
        accessor: 'tickerNo',
        disableFilters: true,
        maxWidth: 200,
      },
      {
        Header: 'entity_management:company_name',
        accessor: 'companyName',
        disableFilters: true,
        minWidth: 600,
      },
      {
        Header: 'tabbar:entity_management',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        accessor: 'entityManagement',
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:register_of_members',
        maxWidth: 100,
        accessor: 'registerOfMember',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:ccass_holding',
        accessor: 'ccassHolding',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:reports',
        accessor: 'reports',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:shareholder_analytics',
        accessor: 'ownershipAnalysis',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:market_intelligence',
        accessor: 'marketIntelligence',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:industry_analyst_report',
        accessor: 'industryAnalysis',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:eagm',
        accessor: 'eAGM',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:board_portal',
        accessor: 'boardPortal',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },

      {
        Header: 'tabbar:invoice_management',
        accessor: 'invoiceManagement',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'tabbar:thought_leadership',
        accessor: 'thoughtLeadership',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'manage_company_access:maker_checker_function',
        accessor: 'makerCheckerFunc',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) => (value ? <DoneIcon /> : <CloseIcon />),
      },
      {
        Header: 'manage_company_access:acc_number_limitation',
        accessor: 'acctNoLimitation',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'manage_applications:date',
        accessor: 'requestedAt',
        disableFilters: true,
        Cell: ({ value }: any) => format(new Date(value), 'yyyy-MM-dd'),
      },
      {
        Header: 'manage_applications:requested_by',
        accessor: 'requestedBy',
        disableFilters: true,
      },
    ],
    [t],
  );

  const onClickRow = (item: CompanyApplication) => {
    if (item.id) {
      history.push(`/admin/manage_company_application/${item.id}`);
    }
  };

  return (
    <div className={classes.container}>
      <PaginationTable
        defaultPageSize={10}
        columns={COLUMNS}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by company name"
        placeholder={'No company requests'}
        onClickRow={onClickRow}
        renderHeaderLeft={
          <>
            <CancelIcon className={clsx('section-icon', classes.titleIcon)} />
            <span className="section-title">
              {t('manage_applications:rejected')}
            </span>
          </>
        }
      />
    </div>
  );
};

export default TabCompanyRejected;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { Row } from 'react-table';
import { Collapse, makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      fontSize: '1.2rem',
    },
  }),
);

interface Props<T extends object> {
  row: Row<T>;
  selected?: boolean;
  style?: React.CSSProperties;
  onClickRow?: () => void;
  renderActions?: (row: T, selected?: boolean) => JSX.Element | null;
  renderExpandDetail?: (row: T, hideDetail: () => void) => JSX.Element | null;
  showIndex: boolean;
  index: number;
}

const PaginationTableRow = <T extends object>({
  row,
  style,
  selected,
  onClickRow,
  renderActions,
  renderExpandDetail,
  showIndex,
  index,
}: Props<T>): JSX.Element => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    setOpen(false);
  }, [row]);

  const onClickOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen((state) => !state);
  };

  return (
    <>
      <div
        className={selected ? 'tr selected' : 'tr'}
        onClick={onClickRow}
        style={style}
      >
        {showIndex && (
          <div
            style={{
              ...row.cells?.[0]?.getCellProps()?.style,
              width: 40,
              flex: 'unset',
            }}
            className="td"
          >
            {index}
          </div>
        )}
        {row.cells.map((cell, index) => {
          let tdStyle = { ...cell.getCellProps().style };
          if (cell.column.maxWidth && cell.column.maxWidth < 9999)
            tdStyle = { ...tdStyle, maxWidth: cell.column.maxWidth };

          if (renderActions && index === row.cells.length - 1) {
            tdStyle = {
              ...tdStyle,
              display: 'flex',
              flexDirection: 'row',
              overflow: 'hidden',
            };

            return (
              <div
                {...cell.getCellProps()}
                className={selected ? 'td selected' : 'td'}
                style={tdStyle}
              >
                <div
                  style={{
                    flex: 1,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {cell.render('Cell')}
                </div>
                {renderActions(cell.row.original, selected)}
              </div>
            );
          }

          return (
            <div
              {...cell.getCellProps()}
              className={selected ? 'td selected' : 'td'}
              style={tdStyle}
              onClick={cell.column.id === 'noOfCerts' ? onClickOpen : undefined}
            >
              {cell.render('Cell')}
            </div>
          );
        })}
      </div>
      {renderExpandDetail && (
        <div>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {renderExpandDetail(row.original, () => setOpen((state) => !state))}
          </Collapse>
        </div>
      )}
    </>
  );
};

export default PaginationTableRow;

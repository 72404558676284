/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import EntityService from '../../../../api/entity';
import PaginationTable from '../../../../components/PaginationTable';
import SelectColumnFilter, {
  ColumnProps,
} from '../../../../components/SelectCloumnFilter';
import Entity from '../../../../models/Entity';
import { EntityResponse } from '../../../../models/EntityResponse';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 16,
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
  buttonOff: {
    width: 64,
    marginBottom: 4,
    textTransform: 'capitalize',
  },
  buttonOn: {
    width: 64,
    color: '#0909B7',
    backgroundColor: '#fff',
    border: '1px solid #0909B7',
    '&:hover': {
      backgroundColor: '#d0d4de',
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
    marginBottom: 4,
    textTransform: 'capitalize',
  },
}));

const COLUMNS: Array<Column<Entity>> = [
  {
    Header: 'manage_company:file_code',
    accessor: 'fileCode',
    disableFilters: true,
  },
  {
    Header: 'manage_company:name',
    accessor: 'name',
    width: 300,
    disableFilters: true,
  },
  {
    Header: 'manage_company:status',
    accessor: 'status',
    filter: 'includes',
    Filter: (props: ColumnProps) => (
      <SelectColumnFilter
        {...props}
        customOptions={[
          { baseString: 'Active', en: 'Active', zh: 'Active', cn: 'Active' },
          {
            baseString: 'Pending',
            en: 'Pending',
            zh: 'Pending',
            cn: 'Pending',
          },
          {
            baseString: 'Closing',
            en: 'Closing',
            zh: 'Closing',
            cn: 'Closing',
          },
          { baseString: 'Closed', en: 'Closed', zh: 'Closed', cn: 'Closed' },
          {
            baseString: 'Marked for Deletion',
            en: 'Marked for Deletion',
            zh: 'Marked for Deletion',
            cn: 'Marked for Deletion',
          },
        ]}
      />
    ),
  },
  {
    Header: 'entity_management:date_of_incorporation',
    accessor: 'incorpDateString',
    disableFilters: true,
  },
];

interface Props {
  emCodes: string[];

  onChange: (ids: string[]) => void;
}

const TabEntityManagement: React.FC<Props> = ({ emCodes, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [entities, setEntities] = React.useState<Entity[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy, filters }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      EntityService.getEntities(
        pageSize,
        pageIndex,
        query,
        sortBy?.id === 'incorpDateString'
          ? { ...sortBy, id: 'incorporateDate' }
          : sortBy,
        filters?.[0]?.id,
        filters?.[0]?.value,
      ).then(({ data: { data, totalPage } }) => {
        if (fetchId === fetchIdRef.current) {
          setEntities(data.map((item: EntityResponse) => new Entity(item)));
          setPageCount(totalPage);
          setLoading(false);
        }
      });
    },
    [],
  );

  const renderActions = (item: Entity) => {
    const assigned = emCodes?.includes(item.id);

    return (
      <div className="table-row-actions">
        <Button
          aria-label="assign"
          variant="contained"
          className={assigned ? classes.buttonOn : classes.buttonOff}
          onClick={() => {
            if (assigned) {
              onChange(emCodes.filter((id) => id !== item.id));
            } else {
              onChange([...emCodes, item.id]);
            }
          }}
        >
          {assigned ? 'Unassign' : 'Assign'}
        </Button>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <PaginationTable
        defaultPageSize={10}
        columns={COLUMNS}
        data={entities}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by name, relationship manager"
        placeholder={t('manage_user:entity_management_placeholder')}
        renderActions={renderActions}
      />
    </div>
  );
};

export default TabEntityManagement;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectStaffInfo } from '../../../auth/duck/selector';
import AccessRightForm from '../../users/AccessRightForm';

const TabStaffInfo = () => {
  const staffInfo = useSelector(selectStaffInfo);
  return (
    <div>
      <AccessRightForm role={staffInfo?.role} showRequired />
    </div>
  );
};

export default TabStaffInfo;

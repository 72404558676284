import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  AppBar,
  Container,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Drawer from '../../components/Drawer';
import {
  entityManagement,
  userPortal,
  eagm,
  reports,
  key,
  applications,
  feedback,
  newsletter,
} from '../../assets';
import ManageCompany from './companyManagement/ManageCompany';
import SelectAccessMaintenanceCompany from './accessMaintenance/SelectCompany';
import ManageRom from './romManagement/ManageCompany';
import SelectUser from './users/SelectUser';
import ManageUser from './users/ManageUser';
import { RootState, AppDispatch } from '../../store';
import {
  selectStaffInfo,
  selectIsAuthorized,
  selectAdminAccessRight,
} from '../auth/duck/selector';
import { Staff } from '../../models/Staff';
import { logout as ActionLogout } from '../auth/duck/authDuck';
import TimeoutDialog from '../../components/TimeoutDialog';
import SelectStaff from './staffManagement/SelectStaff';
import ManageStaff from './staffManagement/staffDetail/ManageStaff';
import ManageFeedback from './feedbackManagement/ManageFeedback';
import ManageThoughtLeadership from './thoughtLeadership/ManageThoughtLeadership';
import HeaderGroup from '../../components/HeaderGroup';
import ManageCompanyAccess from './accessMaintenance/ManageCompanyAccess';
import ManageReports from './reports/ManageReports';
import Applictaions from './applications/Applications';
import ManageApplication from './applications/ApplicationDetail/ManageUser';
import CompanyApplicationDetail from './applications/CompanyApplicationDetail';
import RequestSuccessDialog from '../../components/RequestSuccessDialog';

const ROUTES = [
  {
    data: [
      {
        key: 'manageStaff',
        path: '/admin/manage_staff',
        translateKey: 'manage_staff',
        icon: entityManagement,
        main: () => <SelectStaff />,
      },
      {
        key: 'manageClient',
        path: '/admin/manage_client',
        translateKey: 'manage_client',
        icon: eagm,
        main: () => <SelectUser />,
      },
      {
        key: 'manageCompanyAccess',
        path: '/admin/company_access',
        translateKey: 'manage_company_access',
        icon: key,
        main: () => <SelectAccessMaintenanceCompany />,
      },
      {
        key: 'manageApplication',
        path: '/admin/applications',
        translateKey: 'manage_applications',
        icon: applications,
        main: () => <Applictaions />,
      },
      {
        key: 'manageReports',
        path: '/admin/reports',
        translateKey: 'reports',
        icon: reports,
        main: () => <ManageReports />,
      },
      {
        key: 'manageFeedback',
        path: '/admin/manage_feedback',
        translateKey: 'manage_feedback',
        icon: feedback,
        main: () => <ManageFeedback />,
      },
      {
        key: 'manageThoughtleadership',
        path: '/admin/manage_thoughtleadership',
        translateKey: 'manage_thought_leadership',
        icon: newsletter,
        main: () => <ManageThoughtLeadership />,
      },
      {
        key: 'userPortal',
        path: '/',
        translateKey: 'user_portal',
        icon: userPortal,
        main: () => <div />,
      },
    ],
  },
];

const ROUTES_ARR = ROUTES.reduce(
  (acc: any[], route) => [...acc, ...route.data],
  [],
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    container: {
      flex: 1,
      width: '100%',
    },
    appbar: {
      background: theme.palette.primary.dark,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    content: {
      backgroundColor: 'rgba(245, 245, 245, 0.5)',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 'unset',
    },
    contentMobile: {
      marginTop: 24,
      marginBottom: 24,
    },
    offset: theme.mixins.toolbar,
    routeContent: {
      padding: '28px 32px',
      minHeight: 'calc(100vh - 46px)',
      position: 'relative',
    },
  }),
);

interface Props {
  isAuthorized: string | boolean;
  accessRights: { [key: string]: boolean };
  staff: Staff | null;
  logout: () => void;
}

export const AdminDashboard: React.FC<Props> = ({
  isAuthorized,
  staff,
  accessRights,
  logout,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation('tabbar');
  const matched = useMediaQuery('(min-width:920px)');
  const [open, setOpen] = React.useState(false);

  const routes = React.useMemo(() => {
    return ROUTES.map((section) => ({
      ...section,
      data: section.data.filter((route) =>
        accessRights ? (accessRights as any)[route.key] : true,
      ),
    })).filter((section) => section.data.length > 0);
  }, [accessRights]);

  if (!isAuthorized) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  if (isAuthorized === 'user') {
    return <Redirect to={{ pathname: '/' }} />;
  }

  if (isAuthorized === 'staff') {
    return <Redirect to={{ pathname: '/staff' }} />;
  }

  if (location.pathname === '/admin') {
    return (
      <Redirect
        to={{
          pathname:
            isAuthorized === 'ClientChecker' || isAuthorized === 'ClientMaker'
              ? '/admin/manage_client'
              : '/admin/manage_staff',
        }}
      />
    );
  }

  const currentRoute = ROUTES_ARR.find((item) =>
    location.pathname.startsWith(item.path),
  );
  const title = currentRoute ? t(currentRoute.translateKey) : '';

  return (
    <div className={classes.root}>
      <TimeoutDialog msTimeout={1000 * 60 * 20} onIdle={logout} />
      <RequestSuccessDialog />
      <Drawer
        routes={routes}
        staff={staff}
        open={open}
        toggleDrawer={() => setOpen((state) => !state)}
      />
      {!matched && (
        <AppBar position="fixed" className={classes.appbar}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              aria-label="menu"
              onClick={() => setOpen(true)}
            >
              <MenuIcon htmlColor="#fff" fontSize="large" />
            </IconButton>
            <Typography variant="h6">{title}</Typography>
          </Toolbar>
        </AppBar>
      )}
      <Container
        disableGutters
        className={clsx(classes.content, { [classes.contentMobile]: !matched })}
      >
        <div className={clsx({ [classes.offset]: !matched })} />
        <HeaderGroup isAdmin />
        <Container
          disableGutters
          className={classes.routeContent}
          maxWidth="xl"
        >
          <Switch>
            <Route path="/admin/manage_company/:id" component={ManageCompany} />
            <Route path="/admin/manage_rom/:id" component={ManageRom} />
            <Route path="/admin/manage_user/:id" component={ManageUser} />
            <Route path="/admin/manage_staff/:id" component={ManageStaff} />
            <Route
              path="/admin/manage_application/:id"
              component={ManageApplication}
            />
            <Route
              path="/admin/manage_company_application/:id"
              component={CompanyApplicationDetail}
            />
            <Route
              path="/admin/company_access/:id"
              component={ManageCompanyAccess}
            />
            {routes.reduce((acc: JSX.Element[], section) => {
              const data = section.data.map((route) => (
                <Route
                  key={route.translateKey}
                  path={route.path}
                  // eslint-disable-next-line react/no-children-prop
                  children={<route.main />}
                />
              ));

              return [...acc, ...data];
            }, [])}
          </Switch>
        </Container>
      </Container>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  staff: selectStaffInfo(state),

  accessRights: selectAdminAccessRight(state),
  isAuthorized: selectIsAuthorized(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  logout: () => dispatch(ActionLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);

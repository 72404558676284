import React, { ReactElement } from 'react';
import {
  Container,
  Grid,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isSafari } from 'react-device-detect';
import AuthService from '../../../api/auth';
import HeaderGroup from '../../../components/HeaderGroup';
import { ListedCompany } from '../../../models/ListedCompany';
import { selectLang } from '../../settings/duck/selector';
import SelectCompanyDialog from '../registerOfMembers/SelectCompanyDialog';
import RomService from '../../../api/rom';
import { selectUserInfo } from '../../auth/duck/selector';

const FACTSET_URL = isSafari
  ? `https://login.factset.com/services/saml2/initialize?idpid=${process.env.REACT_APP_FACTSET_KEY}&apphost=tricor.factset.com`
  : `https://ondemand.factset.com/views/navigator/ownership/company-summary/{{insCode}}-HK?lang=en-US&hideTools=true&hideToolbar=true&idpid=${process.env.REACT_APP_FACTSET_KEY}`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      backgroundColor: 'rgba(245, 245, 245, 0.5)',
    },
    headerContainer: {
      backgroundColor: '#fff',
      padding: '0 16px',
    },
    contentContainer: {
      padding: '28px 0px',
      height: '90%',
    },
    company: {
      verticalAlign: 'middle',
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#262626',
      marginRight: 8,
      marginBottom: 8,
    },
    indicator: {
      display: 'none',
    },
    wrapper: {
      flexDirection: 'row',
      '& svg': {
        marginBottom: '0px !important',
        marginRight: 6,
        height: '1em',
        width: '1em',
        '& path': {
          fill: '#f50057',
          opacity: 1,
        },
      },
    },
  }),
);

interface Props {
  company?: ListedCompany;

  setCompany: (company: ListedCompany) => void;

  canSelectCompany: boolean;

  setCanSelectCompany: (enable: boolean) => void;
}

function ShareholderAnalytics({
  company,
  setCompany,
  canSelectCompany,
  setCanSelectCompany,
}: Props): ReactElement {
  const classes = useStyles();
  const lang = useSelector(selectLang);
  const user = useSelector(selectUserInfo);
  const { t } = useTranslation();
  const instrument = company?.instruments?.[0];

  React.useEffect(() => {
    if (!company && user) {
      RomService.getUserRom(user.id, 1, 1).then((res) => {
        setCompany(res.data.data[0]);
        setCanSelectCompany(res.data.totalPage > 1);
      });
    }
  }, [user, company]);

  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (instrument)
      AuthService.resetFactsetToken(
        instrument?.insCode?.toString().replace(/^0+(?!$)/, ''),
      ).then(() => {
        setLoaded(true);
      });
  }, [instrument]);

  if (!loaded) return <div />;

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.headerContainer}>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <span className={classes.company}>
            {lang === 'en'
              ? company?.cmpEngName
              : company?.cmpChiName || company?.cmpEngName}
            {instrument?.insCode ? `(${instrument?.insCode})` : ''}
          </span>
          {canSelectCompany && (
            <SelectCompanyDialog
              selectCompany={(item: ListedCompany) => {
                setCompany(item);
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <HeaderGroup />
        </Grid>
      </Grid>
      <Container
        maxWidth="xl"
        disableGutters
        className={classes.contentContainer}
      >
        <iframe
          src={t(FACTSET_URL, {
            insCode: instrument?.insCode?.toString().replace(/^0+(?!$)/, ''),
          })}
          title="Ownership Analytics"
          style={{ height: '100%', width: '100%' }}
        />
      </Container>
    </div>
  );
}

export default ShareholderAnalytics;

import { makeStyles, Theme } from '@material-ui/core';
import { Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CreateArticleValue } from '../../../api/thoughtLeadership';
import { SimpleFileUpload } from '../../../components/SimpleFileUpload';

const useStyles = makeStyles((theme: Theme) => ({
  column: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 24,
    '& .MuiFormControlLabel-label': {
      fontSize: '1.2rem',
      color: '#838383',
    },
  },
  label: {
    color: '#838383',
    fontSize: '1.3rem',
    fontWeight: 'bold',
    marginTop: 20,
  },
  fileInput: {
    '& input::file-selector-button': {
      backgroundColor: '#0909B7',
      color: '#FFF',
      '&:hover': {
        backgroundColor: '#001A5B',
      },
    },
  },
}));

const ArticleForm: React.FC<{
  values: CreateArticleValue;
}> = ({ values }) => {
  const classes = useStyles();
  const { t } = useTranslation('manage_thought_leadership');

  return (
    <Form>
      <Field fullWidth component={TextField} name="title" label={t('title')} />
      <Field
        fullWidth
        component={TextField}
        name="description"
        label={t('description')}
        multiline
      />
      <Field
        fullWidth
        component={TextField}
        name="webLink"
        label={t('weblink')}
      />
      <Field
        fullWidth
        component={SimpleFileUpload}
        name="imageFile"
        label={t('image')}
        accept="image/jpeg,image/gif,image/png"
        InputProps={{
          currentImage: values.imageUrl,
          className: classes.fileInput,
        }}
      />
    </Form>
  );
};

export default ArticleForm;

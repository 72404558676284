/* eslint-disable react/jsx-wrap-multilines */
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';

import AuthService from '../../api/auth';
import LoginContainer from './LoginContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      width: '100%',
      marginTop: 25,
      fontSize: '1.8rem',
      fontWeight: 600,
      color: theme.palette.common.white,
      textTransform: 'capitalize',
      borderRadius: 7,
      backgroundColor: '#0909B7',
      '&:hover': {
        backgroundColor: '#001A5B',
      },
    },
    label: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      color: '#000',
    },
    description: {
      fontSize: '1.2rem',
      lineHeight: 1.17,
      color: '#575757',
      margin: '10px 0 18px 0',
    },
  }),
);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

interface SetPasswordValues {
  password: string;
  confirmPassword: string;
}

const SetPassword: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();

  const validate = (values: SetPasswordValues) => {
    const errors: Partial<SetPasswordValues> = {};
    if (!values.password) errors.password = 'Required 必须填写';
    if (!values.confirmPassword) errors.confirmPassword = 'Required 必须填写';
    if (Object.keys(errors).length === 0) {
      if (values.password !== values.confirmPassword) {
        errors.password = 'Password mismatch. 密码不匹配';
      } else if (
        !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/.test(
          values.confirmPassword,
        )
      ) {
        errors.password = t('login:set_password_description');
      }
    }

    return errors;
  };

  if (!query.get('n')) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <LoginContainer>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validate={validate}
        onSubmit={(values, actions) => {
          AuthService.setPassword(
            values.confirmPassword,
            query.get('n') as string,
          )
            .then(() => {
              history.replace('/recover/success');
            })
            .catch((error) => {
              if (error.response) {
                const { errorCode, message } = error.response.data;

                if (errorCode === 11002)
                  actions.setErrors({ password: message });
                else history.replace('/recover/expire');
              }
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <>
            <div className={classes.label}>{t('login:set_password_title')}</div>
            <div className={classes.description}>
              {t('login:set_password_description')}
            </div>
            <Form>
              <Field
                fullWidth
                component={TextField}
                label={t('login:new_password')}
                name="password"
                type="password"
              />
              <Field
                fullWidth
                component={TextField}
                name="confirmPassword"
                label={t('login:confirm_password')}
                type="password"
              />
            </Form>
            <Button
              type="submit"
              variant="contained"
              disableElevation
              disableFocusRipple
              disableRipple
              className={classes.button}
              onClick={submitForm}
              disabled={isSubmitting}
            >
              Submit 提交
            </Button>
          </>
        )}
      </Formik>
    </LoginContainer>
  );
};

export default SetPassword;

import { Button, IconButton, makeStyles, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import DeleteIcon from '@material-ui/icons/DeleteForeverRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AdminService from '../../../api/admin';
import { DialogContent, DialogTitle } from '../../../components/Dialog';
import { Staff } from '../../../models/Staff';
import { useAppDispatch } from '../../../store';
import { makerRequestSuccess } from '../../auth/duck/authDuck';

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonRemove: {
    marginRight: 48,
    backgroundColor: '#0909B7',
    '&:hover': {
      backgroundColor: '#001A5B',
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelRemove: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  labelCancel: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#707070',
  },
}));

interface Props {
  user: Staff;

  small?: boolean;

  onDeleteSuccess?: () => void;

  onDeleteFailed?: (message: string) => void;
}

const DeleteStaffDialog: React.FC<Props> = ({
  user,
  onDeleteSuccess,
  onDeleteFailed,
  small,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('manage_staff');
  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClickOpen = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    AdminService.deleteStaff(user.id)
      .then(() => {
        handleClose();
        setLoading(false);
        dispatch(makerRequestSuccess());
        if (onDeleteSuccess) onDeleteSuccess();
      })
      .catch((e) => {
        handleClose();
        if (onDeleteFailed)
          onDeleteFailed(
            e.response?.data?.errorCode === 11010
              ? 'User with both CS and IS services assigned, please unassign one of the service before the deletion can be proceed'
              : e.message,
          );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {small ? (
        <IconButton aria-label="clear" onClick={handleClickOpen}>
          <ClearRoundedIcon htmlColor="#a5a5a5" />
        </IconButton>
      ) : (
        <Button
          variant="contained"
          color="primary"
          size="small"
          endIcon={<DeleteIcon />}
          className="button-create"
          onClick={handleClickOpen}
        >
          {t('delete_user')}
        </Button>
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="delete-user-dialog-title"
        open={open}
      >
        <DialogTitle id="delete-user-dialog-title" onClose={handleClose}>
          {t('delete_user')}
        </DialogTitle>
        <DialogContent>
          <div className={classes.message}>
            {t('delete_user_message', { mail: user.mail })}
          </div>
        </DialogContent>
        <div className={classes.bottomContainer}>
          <Button
            variant="contained"
            color="primary"
            classes={{
              root: classes.buttonRemove,
              label: classes.labelRemove,
            }}
            onClick={handleClickDelete}
            disabled={loading}
          >
            {t('common:delete')}
          </Button>
          <Button
            variant="outlined"
            classes={{ label: classes.labelCancel }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClose();
            }}
            disabled={loading}
          >
            {t('common:cancel')}
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default DeleteStaffDialog;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
import { SortingRule } from 'react-table';
import { ListedCompany } from '../models/ListedCompany';
import { Meeting } from '../models/Meetings';
import { PaginationResponse } from '../models/PaginationResponse';

import axios from './index';

const getMeetings = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  filterField?: string,
  filterValue?: string,
) => {
  const mapSortBy = sortBy;
  //   if (mapSortBy) {
  //     if (sortBy?.id === 'name') mapSortBy.id = 'cmpEngName';
  //     else if (sortBy?.id === 'stockCode') mapSortBy.id = 'insCode';
  //   }
  return axios
    .get<PaginationResponse<Meeting>>('spot/meetings', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: mapSortBy?.id,
        sortOrder: mapSortBy ? (mapSortBy.desc ? 'desc' : 'asc') : undefined,
        filterField,
        filterValue,
      },
    })
    .then((res) => res.data)
    .then((res) => ({
      ...res,
      data: res.data.map((item) => ({
        ...item,
        meetingType: {
          en: item.meetingTypeEng,
          zh: item.meetingTypeTChi,
          cn: item.meetingTypeSChi,
        },
        insName: {
          en: item.insNameEng,
          zh: item.insNameTChi,
          cn: item.insNameSChi,
        },
      })) as Meeting[],
    }));
};

const getMeetingStockCodeOptions = () =>
  axios.get('spot/meetings/stock-codes').then((res) => res.data);

const getMeetingUrl = (meetingOid: string | number, username: string) =>
  axios.get(`spot/meetings/${meetingOid}/users/${username}`);

export default {
  getMeetings,
  getMeetingStockCodeOptions,
  getMeetingUrl,
};

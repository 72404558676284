/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
import { SortingRule } from 'react-table';
import { Certificate } from '../models/Certificate';
import { CompanyUser } from '../models/CompanyUser';
import Holder from '../models/Holder';
import { ListedCompany } from '../models/ListedCompany';
import { PaginationResponse } from '../models/PaginationResponse';
import { ShareMovementHistory } from '../models/ShareMovementHistory';
import axios from './index';
import store from '../store';
import {
  selectUserAccessRights,
  selectUserInfo,
} from '../pages/auth/duck/selector';

const getCompanies = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  viewUser?: number,
) => {
  const mapSortBy = sortBy;
  if (mapSortBy) {
    if (sortBy?.id === 'name') mapSortBy.id = 'cmpEngName';
    else if (sortBy?.id === 'stockCode') mapSortBy.id = 'insCode';
  }
  return axios
    .get<PaginationResponse<ListedCompany>>('client/v2', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: mapSortBy?.id,
        sortOrder: mapSortBy ? (mapSortBy.desc ? 'desc' : 'asc') : undefined,
        viewUser,
      },
    })
    .then((res) => res.data);
};

const getCompanyHolders = (
  insOid: string,
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  filterV3?: { [field: string]: string[] },
) =>
  axios
    .get<PaginationResponse<Holder>>('shareholder', {
      params: {
        insOid,
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
        filterV3,
      },
    })
    .then((res) => res.data)
    .then(({ data, ...otherProps }) => ({
      ...otherProps,
      data: data.map((holder: any) => ({
        ...holder,
        hdrName: `${holder.hdrName1} ${holder.hdrName2} ${holder.hdrName3} ${holder.hdrName4}`.trim(),
        address: `${holder.addr1} ${holder.addr2} ${holder.addr3} ${holder.addr4}`.trim(),
      })),
    }));

const getCertificates = (
  insOid: string,
  folioNo: string,
  pageSize?: number,
  page?: number,
  sortBy?: SortingRule<any>,
) =>
  axios
    .get<PaginationResponse<Certificate>>('/shareholder/certificate', {
      params: {
        insOid,
        FolioNo: folioNo,
        pageSize,
        page,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      },
    })
    .then((res) => res.data);

const getHolderListFilterOptions = (insOid: string) => {
  const fields = ['autopayInd', 'annualRpt', 'mcuCode', 'divPlan', 'mcoName'];

  return Promise.all(
    ['autopayInd', 'annualRpt', 'mcuCode', 'divPlan', 'mcoName'].map((field) =>
      axios.get('/shareholder/options', { params: { insOid, field } }),
    ),
  )
    .then((res) =>
      res.reduce(
        (acc, { data }, index) => ({
          ...acc,
          [fields[index]]: data,
        }),
        {},
      ),
    )
    .then((options: any) => ({ ...options, payInst: options.autopayInd }));
};

const getInstruments = (cmpOid: string) =>
  axios.get(`client/v2/${cmpOid}/instrument`);

const getHoldersReport = (insOid: string, params: any) =>
  axios.get(`${process.env.REACT_APP_BASE_URL}/shareholder/topN/report`, {
    responseType: 'blob',
    params: { insOid, ...params },
  });

const getActiveHoldersReport = (insOid: string, params: any) =>
  axios.get(`${process.env.REACT_APP_BASE_URL}/shareholder/report`, {
    responseType: 'blob',
    params: { insOid, ...params },
  });

const getShareMovementHistoryReport = (
  insOid: string,
  startDate: string,
  endDate: string,
  params: {},
) =>
  axios.get(`${process.env.REACT_APP_BASE_URL}/shareholder/history/report`, {
    responseType: 'blob',
    params: {
      ...params,
      insOid,
      startDate,
      endDate,
    },
  });

const getAnalysisReport = (insOid: string, type: string, lang: string) => {
  let path = '';
  const params: Record<string, string> = { insOid, lang };
  switch (type) {
    case 'overseas_holders_analysis':
      path = 'shareholder/geo/report';
      params.sortBy = 'totalHolders';
      params.sortOrder = 'desc';
      break;
    case 'means_of_communication':
      path = 'shareholder/analysis/communication/report';
      break;
    case 'autopay_instruction':
      path = 'shareholder/analysis/payment/report?type=payment';
      break;
    case 'dividend_election_instruction':
      path = 'shareholder/analysis/payment/report?type=election';
      break;
    case 'currency_election':
      path = 'shareholder/analysis/currency/report';
      break;
    default:
      break;
  }

  return axios.get(`${process.env.REACT_APP_BASE_URL}/${path}`, {
    responseType: 'blob',
    params,
  });
};

const getShareMovementHistory = (
  insOid: string,
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  startDate?: string,
  endDate?: string,
  filterV3?: { [field: string]: string },
) =>
  axios
    .get<PaginationResponse<ShareMovementHistory>>('shareholder/history', {
      params: {
        insOid,
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
        startDate,
        endDate,
        filterV3,
      },
    })
    .then((res) => res.data);

const getShareMovementHistoryFilterOptions = (
  insOid: string,
  startDate: string,
  endDate: string,
) =>
  axios.get('shareholder/history/options', {
    params: { insOid, startDate, endDate },
  });

const getGeoAnalysis = (insOid: string) =>
  axios
    .get('shareholder/geo', {
      params: {
        insOid,
        sortBy: 'totalHolders',
        sortOrder: 'desc',
      },
    })
    .then(({ data }) => ({
      columns: [
        'country_area',
        'total_holders',
        'percentage_of_holders',
        'total_holdings',
        'percentage_of_issued_securities',
      ],
      data: data.map((item: any) => [
        item.country,
        item.totalHolders,
        (item.percentageOfHolders * 100).toLocaleString(undefined, {
          maximumFractionDigits: 4,
          minimumFractionDigits: 4,
        }),
        item.totalHoldings,
        (item.percentageOfSecurities * 100).toLocaleString(undefined, {
          maximumFractionDigits: 4,
          minimumFractionDigits: 4,
        }),
      ]),
    }));

const getCommunicationAnalysis = (insOid: string) =>
  axios
    .get('shareholder/analysis/communication', { params: { insOid } })
    .then(({ data }) => ({
      columns: [
        'means_of_receiving_corporate_communications',
        'number_of_registered_holders',
      ],
      data: [
        ['new_holders', data.new_Holder_Total, '#ded32d'],
        ['special', data.not_Notify_Total, '#d0dcec'],
        [
          'email_notification_on_publication_of_corporate_communications',
          data.website_Email,
          '#1166a7',
        ],
        [
          'physical_otification_on_publication_of_corporate_communications',
          data.website_Mail,
          '#e84637',
        ],
        ['physical_copy_in_english_html', data.hardcopy_Eng, '#a5cabd'],
        ['physical_copy_in_chinese_html', data.hardcopy_Chi, '#f5a922'],
        [
          'physical_copy_in_english_and_chinese_html',
          data.hardcopy_Both,
          '#ca398c',
        ],
      ],
    }));

const getPaymentAnalysis = (insOid: string) =>
  axios
    .get('shareholder/analysis/payment', { params: { insOid } })
    .then(({ data }) => ({
      columns: ['autopay_instruction', 'number_of_registered_holders'],
      data: [
        ['automatic_payment_html', data.with_Autopay_Instruction],
        ['cheque_html', data.without_Autopay_Instruction],
      ],
    }));

const getDividenElectionAnalysis = (insOid: string) =>
  axios
    .get('shareholder/analysis/payment', { params: { insOid } })
    .then(({ data }) => ({
      columns: [
        'dividend_election_instruction',
        'number_of_registered_holders',
      ],
      data: [
        ['to_receive_dividend_in_scrip_html', data.permanent_Scrip],
        ['to_receive_dividend_in_cash_html', data.permanent_Cash],
        ['no_instruction_given_html', data.no_Election_Instruction],
      ],
    }));

const getCurrencyAnalysis = (insOid: string) =>
  axios
    .get('shareholder/analysis/currency', { params: { insOid } })
    .then(({ data }) => ({
      columns: ['currency_election', 'number_of_registered_holders'],
      data: data
        .map(({ total, mcu_Code }: any) => [
          mcu_Code || 'no_instruction_html',
          total,
        ])
        .filter((item: any) => item[1] > 0),
    }));

const getMemberAnalysis = (insOid: string) =>
  Promise.all([
    getGeoAnalysis(insOid),
    getCommunicationAnalysis(insOid),
    getPaymentAnalysis(insOid),
    getDividenElectionAnalysis(insOid),
    getCurrencyAnalysis(insOid),
  ]).then((res) => {
    const [
      overseasHoldersAnalysis,
      meansOfCommunication,
      autopayInstruction,
      dividendElectionInstruction,
      currencyElection,
    ] = res;

    return {
      overseasHoldersAnalysis,
      meansOfCommunication,
      autopayInstruction,
      dividendElectionInstruction,
      currencyElection,
    };
  });

const getUsers = (companyId: string) =>
  axios
    .get<PaginationResponse<CompanyUser>>('user', {
      params: {
        filterValue: companyId,
        filterField: 'romCompanyOid',
      },
    })
    .then((res) => res.data.data);

const addUser = (userId: string | number, romCompanyOid: string) =>
  axios.put(`user/${userId}/rom/bind?romCompanyOid=${romCompanyOid}`);

const removeUser = (userId: string | number, romCompanyOid: string) =>
  axios.put(`user/${userId}/rom/unbind?romCompanyOid=${romCompanyOid}`);

const getUserRom = (
  userId: string | number,
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  filterField?: string,
  filterValue?: string,
) =>
  axios.get<PaginationResponse<ListedCompany>>(`user/${userId}/rom`, {
    params: {
      pageSize,
      page,
      fullTextSearch: query,
      sortBy: sortBy?.id,
      sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      filterField,
      filterValue,
    },
  });

const getStockPrice = (insOid: string, insCode: string, companyType: string) =>
  axios.get('client/stockprice', {
    params: {
      insOid,
      insCode,
      companyType,
      forceRefresh: true,
    },
  });

const getRangeOfHoldings = (insOid: string) =>
  axios.post('shareholder/analysis/frequencies', {
    insOid,
    lang: 'cn',
    ranges: [
      { from: 1, to: 10000 },
      { from: 10001, to: 100000 },
      { from: 100001, to: 1000000 },
      { from: 1000001, to: 10000000 },
      { from: 10000001, to: 100000000 },
      { from: 100000001, to: 1000000000 },
      { from: 1000000001, to: 10000000000 },
      { from: 10000000001, to: 100000000000 },
      { from: 100000000001, to: 1000000000000 },
      { from: 1000000000001 },
    ],
  });

const getRangeOfHoldingsReport = (insOid: string, lang: string, ranges: any) =>
  axios.post(
    'shareholder/analysis/frequencies/report',
    {
      insOid,
      lang,
      ranges: ranges
        .map(({ from, to }: any) => ({
          from: from ? from.toString() : undefined,
          to: to ? to.toString() : undefined,
        }))
        .filter((item: any) => item.from || item.to),
    },
    { responseType: 'blob' },
  );

export default {
  getCompanies,
  getInstruments,
  getCompanyHolders,
  getHolderListFilterOptions,
  getCertificates,
  getShareMovementHistory,
  getShareMovementHistoryFilterOptions,
  getStockPrice,

  getHoldersReport,
  getActiveHoldersReport,
  getShareMovementHistoryReport,
  getAnalysisReport,

  getGeoAnalysis,
  getCommunicationAnalysis,
  getPaymentAnalysis,
  getDividenElectionAnalysis,
  getCurrencyAnalysis,
  getMemberAnalysis,

  getUsers,
  addUser,
  removeUser,

  getUserRom,

  getRangeOfHoldings,
  getRangeOfHoldingsReport,
};

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from '../store';
import {
  selectPrimaryManager,
  selectManagementTeam,
  selectUserInfo,
} from '../pages/auth/duck/selector';
import StaffAvatar from './StaffAvatar';
import { TricorStaff } from '../models/TricorStaff';
import AuthService from '../api/auth';
import { CompanyUser } from '../models/CompanyUser';

const formatPhoneNumber = (value: string) => {
  if (/[0-9]{8}/.test(value)) {
    return `${value.substr(0, 4)} ${value.substr(4, 4)}`;
  }
  return value;
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
    },
    open: {
      padding: '4px 18px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 0,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.16)',
      backgroundColor: '#fff',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#262626',
      },
    },
    close: {
      padding: '4px 18px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: 8,
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.16)',
      backgroundColor: '#fff',
      textTransform: 'capitalize',
    },
    avatarMini: {
      height: 30,
      width: 30,
      marginRight: 12,
    },
    avatarSmall: {
      display: 'inline-flex',
      height: 40,
      width: 40,
    },
    avatar: {
      display: 'inline-flex',
      height: 50,
      width: 50,
    },
    column: {
      height: 60,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      overflow: 'hidden',
    },
    clientManager: {
      fontSize: '1.2rem',
      lineHeight: 1.4,
      color: '#262626',
      fontWeight: 'bold',
      textAlign: 'start',
    },
    name: {
      fontSize: '1.2rem',
      lineHeight: 1.4,
      color: '#262626',
      textAlign: 'start',
    },
    contactUs: {
      fontSize: '1.4rem',
      color: '#000',
      fontWeight: 'bold',
      marginLeft: 4,
    },
    typography: {
      padding: theme.spacing(2),
    },
    dropdown: {
      height: 24,
      width: 24,
    },
    paper: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      boxShadow: '1px 6px 10px 0 rgba(0, 0, 0, 0.16)',
      borderTop: 'solid 1px #d9d9d9',
    },
    expanded: {
      padding: '12px 0',
    },
    detailContainer: {
      flex: 1,
      marginRight: 18,
      textAlign: 'start',
    },
    label: {
      marginTop: 8,
      fontSize: '1.4rem',
      lineHeight: 1.2,
      color: ' #262626',
      fontWeight: 'bold',
    },
    value: {
      fontSize: '1.4rem',
      lineHeight: 1.2,
      color: ' #262626',
      wordBreak: 'break-all',
    },
    contactButton: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: theme.palette.text.hint,
      },
    },
    primaryRMContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: '0 12px 12px 12px',
    },
    sectionTitle: {
      margin: 12,
      fontSize: '1.2rem',
      lineHeight: 1.2,
      color: ' #262626',
      fontWeight: 'bold',
    },
    gridList: {
      padding: '0 12px',
    },
    teamMember: {
      display: 'flex',
      flexDirection: 'row',
      padding: '8px 12px',
    },
    teamName: {
      color: ' #262626',
      fontWeight: 'bold',
      overflowWrap: 'anywhere',
      marginBottom: 4,
    },
    teamMail: {
      overflowWrap: 'anywhere',
    },
    phoneNo: {
      marginTop: 4,
    },
  }),
);

interface Props {
  user: CompanyUser | null;
}

export const RmContact: React.FC<Props> = ({ user }) => {
  const classes = useStyles();
  const { t } = useTranslation('relationship_manager');
  const ref = React.useRef<HTMLButtonElement | null>(null);
  const [manager, setManager] = React.useState<TricorStaff>();
  const [team, setTeam] = React.useState<TricorStaff[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  React.useEffect(() => {
    if (user) {
      AuthService.getUserContactPoint(user.id).then((res) => {
        setManager(res.data.find((item: any) => item.isPrimary)?.staff);
        setTeam(
          res.data
            .filter((item: any) => !item.isPrimary)
            .map((item: any) => item.staff),
        );
      });
    }
  }, [user]);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div className={classes.container}>
      <Button
        fullWidth
        disableFocusRipple
        disableTouchRipple
        disableRipple
        className={open ? classes.open : classes.close}
        onClick={handleClick}
        ref={ref}
      >
        {!open && (
          <StaffAvatar staffId={manager?.mail} className={classes.avatarMini} />
        )}
        <div className={classes.column}>
          <div className={classes.clientManager}>{t('client_director')}</div>
          <div className={classes.name}>
            {manager?.displayName || manager?.name}
          </div>
        </div>
        <span className={classes.contactUs}>{t('contact_us')}</span>
        {open ? (
          <ArrowDropUpIcon htmlColor="#262626" className={classes.dropdown} />
        ) : (
          <ArrowDropDownIcon htmlColor="#262626" className={classes.dropdown} />
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.paper }}
      >
        <div
          className={classes.expanded}
          style={{ width: ref.current?.getBoundingClientRect().width || 0 }}
        >
          <div
            className={classes.primaryRMContainer}
            style={{
              borderBottom: team.length > 0 ? 'solid 1px #d9d9d9' : undefined,
            }}
          >
            <StaffAvatar staffId={manager?.mail} className={classes.avatar} />
            <div style={{ padding: '0 12px' }}>
              <div>
                <Typography className={classes.label}>
                  {manager?.displayName || manager?.name}
                </Typography>
              </div>
              <div style={{ display: 'inline-block', marginRight: 12 }}>
                <Typography className={classes.label}>{t('email')}</Typography>
                <Typography className={classes.value}>
                  {manager?.userPrincipalName ? (
                    <a
                      href={`mailto:${manager.userPrincipalName}`}
                      className={classes.teamMail}
                    >
                      {manager.userPrincipalName}
                    </a>
                  ) : (
                    '-'
                  )}
                </Typography>
              </div>
              <div style={{ display: 'inline-block' }}>
                <Typography className={classes.label}>{t('phone')}</Typography>
                <Typography className={classes.value}>
                  {manager?.phones?.[0] ? (
                    <a href={`tel:${manager.phones?.[0]}`}>
                      {formatPhoneNumber(manager.phones?.[0])}
                    </a>
                  ) : (
                    '-'
                  )}
                </Typography>
              </div>
            </div>
          </div>
          {team.length > 0 && (
            <>
              <div className={classes.sectionTitle}>
                {t('client_management_team')}
              </div>
              {team.map((staff) => (
                <div className={classes.teamMember} key={staff.id}>
                  <StaffAvatar
                    staffId={staff.mail}
                    className={classes.avatarSmall}
                  />
                  <div style={{ padding: '0 12px' }}>
                    <div>
                      <Typography className={classes.label}>
                        {staff.displayName || staff.name}
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.label}>
                        {t('email')}
                      </Typography>
                      <Typography className={classes.value}>
                        {staff?.userPrincipalName ? (
                          <a
                            href={`mailto:${staff.userPrincipalName}`}
                            className={classes.teamMail}
                          >
                            {staff.userPrincipalName}
                          </a>
                        ) : (
                          '-'
                        )}
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.label}>
                        {t('phone')}
                      </Typography>
                      <Typography className={classes.value}>
                        {staff.phones?.[0] ? (
                          <a href={`tel:${staff.phones?.[0]}`}>
                            {formatPhoneNumber(staff.phones?.[0])}
                          </a>
                        ) : (
                          '-'
                        )}
                      </Typography>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </Popover>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUserInfo(state),
  manager: selectPrimaryManager(state),
  team: selectManagementTeam(state),
});

export default connect(mapStateToProps)(RmContact);

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DialogTitle, DialogContent } from '../../../../components/Dialog';
import CertificateList from './CertificateList';

interface Props {
  instrumentId: string;
  folioNo: string;
}

const CertificateDetailModal: React.FC<Props> = ({ instrumentId, folioNo }) => {
  const { t } = useTranslation('register_of_members');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span style={{ textDecoration: 'underline' }} onClick={handleClickOpen}>
        {t('details')}
      </span>
      <Dialog
        onClose={handleClose}
        aria-labelledby="download-holder-report-dialog-title"
        open={open}
      >
        <DialogTitle
          id="download-holder-report-dialog-title"
          onClose={handleClose}
        >
          {t('certificates_detail')}
        </DialogTitle>
        <DialogContent>
          <CertificateList instrumentId={instrumentId} folioNo={folioNo} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CertificateDetailModal;

/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/indent */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import ReactGA from 'react-ga';
import AuthApi from '../../../api/auth';
import { Staff } from '../../../models/Staff';
import { LoginAction } from '../../../models/Login';
import { CompanyUser } from '../../../models/CompanyUser';
import { clearToken } from '../../../api';
import { selectIsMaker, selectUserInfo } from './selector';

export const login = createAsyncThunk<any, LoginAction>(
  'auth/LOGIN',
  async (payload) => {
    const { email, password } = payload;
    const { data } = await AuthApi.login(email, password);

    return data;
  },
);

export const logout = createAsyncThunk('auth/LOGOUT', async (payload) => {
  await AuthApi.logout();
  ReactGA.set({ userId: undefined });
});

export const getProfile = createAsyncThunk<any>(
  'auth/GET_PROFILE',
  async (payload, thunkAPI) => {
    const user = selectUserInfo(thunkAPI.getState() as any);
    if (!user?.id) throw Error('User id not found');
    const { data } = await AuthApi.getProfile(user?.id);

    return { user: data };
  },
);

export const verifyOTP = createAsyncThunk<
  any,
  { channel: string; code: string; onSuccess: () => void; onError: () => void }
>('auth/VERIFY_OTP', async (payload) => {
  const { code, channel } = payload;
  const { data } = await AuthApi.verifyOtp(channel, code);
  return data;
});

export const acceptTnc = createAsyncThunk<boolean>(
  'auth/ACCEPT_TNC',
  async (payload, thunkAPI) => {
    const user = selectUserInfo(thunkAPI.getState() as any);
    if (!user?.id) throw Error('User id not found');
    await AuthApi.acceptTnc(user?.id);
    return true;
  },
);

export const makerRequestSuccess = createAsyncThunk(
  'auth/MAKER_REQUEST_SUCCESS',
  async (payload, thunkAPI) => {
    const isMaker = selectIsMaker(thunkAPI.getState() as any);
    if (!isMaker) throw Error('Not maker');

    return true;
  },
);

export type SliceState = {
  staff: Staff | null;
  companyUser: CompanyUser | null;
  token: string | null;
  needOTP?: boolean;
  makerRequestSuccess: boolean;
};

const initialState: SliceState = {
  staff: null,
  companyUser: null,
  token: null,
  makerRequestSuccess: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    hideFeedback(state) {
      if (state.companyUser) {
        state.companyUser.hideFeedback = true;
      }
    },

    clearMakerRequest(state) {
      state.makerRequestSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, { meta }) => {
      state.needOTP = undefined;
      meta.arg.formActions.setSubmitting(true);
    });
    builder.addCase(login.fulfilled, (state, { payload, meta }) => {
      meta.arg.formActions.setSubmitting(false);
      state.needOTP = payload.needOTP;
      state.token = payload.token;
      state.companyUser = payload.user;
      if (payload.user?.userType === 'S') {
        state.staff = payload.user;
        if (state.companyUser?.accessRights) {
          state.companyUser.accessRights.adminPortal = true;
        }
      }
      ReactGA.set({ userId: meta.arg.email });
    });
    builder.addCase(login.rejected, (state, { error, meta }) => {
      const {
        arg: { formActions },
      } = meta;

      formActions.setSubmitting(false);
      formActions.setErrors({
        email: error.message,
      });
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.companyUser = null;
      state.staff = null;
      state.token = null;
      clearToken();
    });
    builder.addCase(logout.rejected, (state) => {
      state.companyUser = null;
      state.staff = null;
      state.token = null;
      clearToken();
    });
    builder.addCase(getProfile.fulfilled, (state, { payload }) => {
      state.companyUser = payload.user;
      if (payload.user?.userType === 'S') {
        state.staff = payload.user;
        if (state.companyUser?.accessRights) {
          state.companyUser.accessRights.adminPortal = true;
        }
      }
      ReactGA.set({ userId: payload.user.username });
    });
    builder.addCase(verifyOTP.fulfilled, (state, { payload, meta }) => {
      state.token = payload.token;
      if (payload.staff) {
        state.staff = payload.staff;
      } else if (payload.companyUser) {
        state.companyUser = payload.companyUser;
      }
      state.needOTP = payload.needOTP;
      meta.arg.onSuccess();
    });
    builder.addCase(verifyOTP.rejected, (state, { meta }) => {
      meta.arg.onError();
    });
    builder
      .addCase(acceptTnc.fulfilled, (state) => {
        if (state.companyUser)
          state.companyUser.acceptedTermsAndConditions = true;
      })
      .addCase(makerRequestSuccess.fulfilled, (state) => {
        state.makerRequestSuccess = true;
      });
  },
});

const { reducer, actions } = authSlice;

export const { clearMakerRequest, hideFeedback } = actions;

export default reducer;

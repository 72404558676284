import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../store';
import Entity from '../../../../models/Entity';
import MyRequest from '../../../../models/MyRequest';

const selectEntity = (state: RootState) => state.entity;

export const selectCurrentEntityId = createSelector(
  selectEntity,
  (state) => state.currentEntityId,
);

export const selectEntities = createSelector(selectEntity, (state) =>
  Object.keys(state.entities).map(
    (entityId) => new Entity(state.entities[entityId]),
  ),
);

export const selectEntitiesChart = createSelector(selectEntities, (state) => [
  {
    name: 'Chow Sang Sang Holdings International Limited',
    attributes: { id: '123' },
    children: [
      {
        name: 'Chow Sang Sang Holdings Limited',
        attributes: { id: '124' },
        children: [
          {
            name: 'Chow Sang Sang Jewellery Company Limited',
            attributes: { id: '125' },
          },
          {
            name: 'Chow Sang Sang Securities Limited',
            attributes: { id: '126' },
          },
          {
            name: 'Chow Sang Sang Futures Limited',
            attributes: { id: '127' },
          },
          {
            name: 'Chow Sang Sang Bullion Dealers Limited',
            attributes: { id: '128' },
          },
        ],
      },
    ],
  },
]);

export const selectRequests = createSelector(
  selectEntity,
  (state) => state.requests,
);

export const selectEntityRequests = createSelector(
  selectCurrentEntityId,
  selectRequests,
  (entityId, requests) =>
    entityId
      ? requests[entityId]?.map((request) => new MyRequest(request)) || []
      : [],
);

export const selectDocuments = createSelector(
  selectEntity,
  (state) => state.documents,
);

export const selectEntityDocuments = createSelector(
  selectCurrentEntityId,
  selectDocuments,
  (entityId, documents) => (entityId ? documents[entityId] || [] : []),
);

export const selectCurrentEntity = createSelector(
  selectEntities,
  selectCurrentEntityId,
  (entities, id) => entities.find((entity) => entity.id === id),
);

/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
import {
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Theme,
  Tooltip,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosRounded';
import DownloadIcon from '@material-ui/icons/GetApp';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import clsx from 'clsx';
import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import cnLocale from 'date-fns/locale/zh-CN';
import zhLocale from 'date-fns/locale/zh-TW';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import AuthService from '../../../api/auth';
import ReportsService from '../../../api/reports';
import LearnMoreDialog from '../../../components/LearnMoreDialog';
import PaginationTable from '../../../components/PaginationTable';
import { CompanyInstrument } from '../../../models/CompanyInstrument';
import { ListedCompany } from '../../../models/ListedCompany';
import { saveAsFile } from '../../../utils';
import { selectUserInfo } from '../../auth/duck/selector';
import { selectLang } from '../../settings/duck/selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customRoot: {
      '& .MuiIconButton-label': {
        color: '#0909B7',
      },
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    menuItem: {
      backgroundColor: '#838383 !important',
      fontSize: 14,
      fontWeight: 'bold',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#838383 !important',
      },
    },
    selected: {
      backgroundColor: '#838383',
      fontSize: 14,
      fontWeight: 'bold',
      color: 'red',
    },
    selectYear: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#262626',
      marginRight: 8,
    },
    buttonSelect: {
      //   borderRadius: 24,
      backgroundColor: '#0909B7',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: '#838383',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#0909B7',
      },
    },
    buttonSelectOn: {
      backgroundColor: '#0909B7',
      color: '#fff',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#0909B7',
      },
    },
    buttonDownload: {
      padding: '6px 16px',
      //   borderRadius: 24,
      backgroundColor: '#0909B7',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: '#fff',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#001A5B',
      },
    },
    all: {
      fontSize: '1.4rem',
    },
    placeholder: {
      fontSize: '2rem',
      lineHeight: 1.2,
      textAlign: 'center',
      color: '#b4b4b4',
      marginTop: 120,
    },
    backButton: {
      fontSize: '1.2rem',
      color: '#707070',
      textTransform: 'none',
      '& svg': {
        fontSize: '1.25rem !important',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        opacity: 1,
      },
    },
    tooltip: {
      margin: 0,
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      padding: 0,
    },
    listItemGutters: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    listItemClass: {
      fontSize: '1.6rem !important',
      fontWeight: 'bold',
      color: '#b4b4b4',
    },
    buttonSelectClass: {
      background: '#fff',
      borderRadius: 4,
      border: 'solid 2px #0909B7',
      height: 33,
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#0909B7',
      textTransform: 'none',
    },
    classOfSecurities: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      color: '#262626',
      marginRight: 8,
    },
  }),
);

interface Props {
  company?: ListedCompany;

  instrument?: CompanyInstrument;

  onSelectInsturment: (data: CompanyInstrument) => void;

  reportType: string;

  onPressBack: () => void;
}

const OnDemandReport: React.FC<Props> = ({
  company,
  instrument,
  reportType,
  onPressBack,
  onSelectInsturment,
}) => {
  const classes = useStyles();
  const { t } = useTranslation('reports');
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [years, setYears] = React.useState<number[]>([]);
  const [year, setYear] = React.useState<string>();
  const [data, setData] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const [allChecked, setAllChecked] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const lang = useSelector(selectLang);
  const user = useSelector(selectUserInfo);

  const locale = {
    en: enLocale,
    zh: zhLocale,
    cn: cnLocale,
  }[lang];

  React.useEffect(() => {
    setSelected([]);
  }, [year, allChecked]);

  React.useEffect(() => {
    if (!company || !instrument) return;
    const { insOid, insCode } = instrument;

    if (reportType === 'Other Report') {
      setYear('2021');
      return;
    }

    ReportsService.getOnDemandReportsYear(
      reportType,
      insOid,
      insCode,
      company.companyType || '',
    ).then((res) => {
      const sortedYears = res.data.sort((a: number, b: number) => b - a);
      setYears(sortedYears);
      if (sortedYears[0]) {
        setYear(sortedYears[0].toString());
      }
    });
  }, [reportType, company, instrument]);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, sortBy }) => {
      if (!company || !instrument || !year) return;
      const fetchId = ++fetchIdRef.current;

      setLoading(true);

      const { insOid, insCode } = instrument;
      ReportsService.getOnDemandReports(
        reportType,
        insOid,
        insCode,
        company.companyType || '',
        allChecked ? undefined : year,
        pageSize,
        pageIndex,
        sortBy || {
          id: 'publishDate',
          desc: ![
            'Transfer Journal',
            'Allotment Report',
            'Buyback Report',
          ].includes(reportType),
        },
      )
        .then((res) => {
          if (fetchId === fetchIdRef.current) {
            setData(res.data.data);
            setPageCount(res.data.totalPage);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [reportType, company, instrument, year, allChecked],
  );

  const columns = React.useMemo<Array<Column<any>>>(() => {
    return [
      reportType === 'Other Report'
        ? {
            Header: t('file_name') as any,
            accessor: 'fileName',
            disableFilters: true,
          }
        : {
            Header: ([
              'Transfer Journal',
              'Allotment Report',
              'Buyback Report',
            ].includes(reportType)
              ? t('date_or_period')
              : t('date')) as string,
            accessor: 'publishDate',
            disableFilters: true,
            Cell: ({ value, row: { original } }) => {
              if (original.publishDateTo) {
                return `${format(
                  new Date(value),
                  lang === 'en' ? 'dd MMMM yyyy' : 'yyyy年MM月dd日',
                  { locale },
                )} ${t('common:to').toLowerCase()} ${format(
                  new Date(original.publishDateTo),
                  lang === 'en' ? 'dd MMMM yyyy' : 'yyyy年MM月dd日',
                  { locale },
                )}`;
              }

              return value
                ? format(
                    new Date(value),
                    lang === 'en' ? 'dd MMMM yyyy' : 'yyyy年MM月dd日',
                    { locale },
                  )
                : '-';
            },
          },
      {
        Header: (
          <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            {t('historical_on_demand_report')}
          </div>
        ),
        accessor: 'downloadUrl',
        disableFilters: true,
        disableSortBy: true,
        maxWidth: 300,
        Cell: ({ value }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              className={clsx(classes.buttonSelect, {
                [classes.buttonSelectOn]: selected.includes(value),
              })}
              onClick={() => {
                setSelected((state) =>
                  state.includes(value)
                    ? state.filter((item) => item !== value)
                    : [...state, value],
                );
              }}
              disableElevation
            >
              {t('common:select')}
            </Button>
          </div>
        ),
      },
    ];
  }, [t, selected]);

  const onClickDownload = () => {
    if (selected.length === 0) {
      alert(t('please_select_required_report'));
      return;
    }
    if (selected.length === 1) {
      const link = document.createElement('a');
      link.href = selected[0];
      link.setAttribute(
        'download',
        data.find((item) => item.downloadUrl === selected[0])?.fileName,
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      ReportsService.downloadReports(selected)
        .then((res) => {
          saveAsFile(new Blob([res.data]), 'reports.zip');
        })
        .catch((e) => {
          alert('Fail to download file');
        });
    }
  };

  return (
    <div>
      <LearnMoreDialog
        open={!!message}
        handleClose={() => {
          setMessage('');
        }}
        message={message}
      />
      <div
        className={classes.row}
        style={{ justifyContent: 'flex-end', marginBottom: 12 }}
      >
        <span className={classes.classOfSecurities}>
          {t('register_of_members:class_of_securities')}
        </span>
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div>
            <Tooltip
              interactive
              placement="bottom-start"
              classes={{ tooltip: classes.tooltip }}
              PopperProps={{ disablePortal: true }}
              onClose={() => setOpen(false)}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <List>
                  {company?.instruments
                    ?.filter((item) => item.insOid !== instrument?.insOid)
                    .map((item) => (
                      <ListItem
                        button
                        key={item.insOid}
                        onClick={() => {
                          setOpen(false);
                          onSelectInsturment(item);
                        }}
                        classes={{ gutters: classes.listItemGutters }}
                      >
                        <ListItemText
                          classes={{ primary: classes.listItemClass }}
                          primary={(item?.mitName as any)?.[lang]}
                        />
                      </ListItem>
                    ))}
                </List>
              }
            >
              <Button
                variant="outlined"
                onClick={() => setOpen(true)}
                className={classes.buttonSelectClass}
              >
                {(instrument?.mitName as any)?.[lang] || ''}
              </Button>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </div>
      <div className={classes.row} style={{ marginBottom: 12 }}>
        <Button
          className={classes.backButton}
          startIcon={<ArrowBackIcon htmlColor="#707070" />}
          disableFocusRipple
          disableTouchRipple
          disableRipple
          onClick={() => onPressBack()}
        >
          {t('common:back')}
        </Button>
        <div style={{ flex: 1 }} />
        <div>
          <Button
            style={{ marginTop: 8 }}
            className={classes.buttonDownload}
            endIcon={<MailOutlineRoundedIcon htmlColor="#fff" />}
            onClick={() => {
              if (user && company)
                AuthService.requestNewReport(user.id, company.cmpEngName).then(
                  (res) => {
                    setMessage(t('common:learn_more_request_new_report'));
                  },
                );
            }}
          >
            {t('request_new_report')}
          </Button>
        </div>
      </div>
      <div className={classes.selectYear}>
        {t(reportType.replace(/\s/g, '_').toLowerCase())}
      </div>
      <FormControlLabel
        classes={{ label: classes.all, root: classes.customRoot }}
        control={
          <Checkbox
            checked={allChecked}
            name="all"
            onChange={(event) => {
              setAllChecked(event.target.checked);
            }}
          />
        }
        label={t('all')}
      />
      <div className={classes.row}>
        {years.length > 0 && year && (
          <>
            <FormControlLabel
              classes={{ label: classes.all }}
              control={
                <Checkbox
                  checked={!allChecked}
                  name="year"
                  onChange={(event) => {
                    setAllChecked(!event.target.checked);
                  }}
                />
              }
              label={t('select_year')}
            />
            <Select
              disableUnderline
              value={year}
              defaultValue={year}
              onChange={(e: any) => {
                setAllChecked(false);
                setYear(e.target.value);
                setPageCount(0);
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
                getContentAnchorEl: null,
              }}
            >
              {years.map((item) => (
                <MenuItem
                  value={item.toString()}
                  classes={{
                    root: classes.menuItem,
                    selected: classes.selected,
                  }}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        <div style={{ flex: 1 }} />
        <Button
          className={classes.buttonDownload}
          endIcon={<DownloadIcon htmlColor="#fff" />}
          onClick={onClickDownload}
        >
          {t('common:download')}
        </Button>
      </div>
      <PaginationTable
        key={year}
        defaultPageSize={10}
        disableSearch
        columns={columns}
        data={data}
        fetchData={fetchData}
        pageCount={pageCount}
        loading={loading}
        placeholder={t('not_available')}
        onClickRow={(item) => {}}
      />
    </div>
  );
};

export default OnDemandReport;

/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  Dialog,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBackIosRounded';
import CheckIcon from '@material-ui/icons/CheckRounded';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AdminService from '../../../api/admin';
import { boardfolio1 } from '../../../assets';
import { DialogContent, DialogTitle } from '../../../components/Dialog';
import { CompanyApplication } from '../../../models/CompanyApplication';
import { selectStaffInfo } from '../../auth/duck/selector';
import { selectLang } from '../../settings/duck/selector';
import RejectCompanyDialog from './RejectCompanyDialog';

const formatDate = (date: string) => format(new Date(date), 'yyyy-MM-dd HH:mm');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //   flexGrow: 1,
    },
    backContainer: {
      padding: '0 !important',
    },
    backButton: {
      fontSize: '1.2rem',
      color: '#707070',
      textTransform: 'none',
      '& svg': {
        fontSize: '1.25rem !important',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        opacity: 1,
      },
    },
    name: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      color: '#262626',
      paddingBottom: '0px !important',
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      padding: 12,
      backgroundColor: '#fff',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      alignItems: 'center',
    },

    buttonAccept: {
      backgroundColor: '#0909B7',
      color: '#fff',
      fontWeight: 600,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#001A5B',
      },
      marginLeft: 12,
    },
    buttonReject: {
      backgroundColor: '#fff',
      color: '#0909B7',
      borderColor: '#0909B7',
      fontWeight: 600,
      textTransform: 'none',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.16)',
      backgroundColor: '#ffffff',
      '& span': {
        fontSize: 12,
        color: '#262626',
      },
    },
    table: {
      '& .MuiTableCell-root': {
        borderBottom: 'unset',
      },
    },
    label: {
      fontSize: '1.4rem',
      color: '#707070',
      flex: 1,
    },
    value: {
      flex: 2,
      fontSize: '1.4rem',
      fontWeight: 'bold',
      color: '#707070',
      whiteSpace: 'pre-line',
    },
    errorMessage: {
      fontSize: '1.4rem',
      lineHeight: 1.43,
      textAlign: 'center',
      color: '#707070',
      whiteSpace: 'pre-line',
      margin: '24px 0',
    },
  }),
);

const CompanyApplicationDetail: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('manage_user');
  const [loading, setLoading] = React.useState(false);
  const [application, setApplication] = React.useState<CompanyApplication>();
  const [company, setCompany] = React.useState<CompanyApplication>();
  const staffInfo = useSelector(selectStaffInfo);
  const { id } = useParams();
  const history = useHistory();

  const lang = useSelector(selectLang);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    AdminService.getCompanyApplication(id)
      .then((data) => {
        setApplication(data);

        return AdminService.getCompanyAccess({
          id: data.companyAccessId,
          tickerNo: data.tickerNo,
          romOid: data.romOid,
        });
      })
      .then((res) => {
        setCompany(res);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const onPressApprove = React.useCallback(() => {
    setLoading(true);
    AdminService.approveCompanyApplications([id])
      .then(() => {
        history.goBack();
      })
      .catch((e) => {
        setLoading(false);
      });
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.backContainer}>
          <Button
            className={classes.backButton}
            startIcon={<ArrowBackIcon htmlColor="#707070" />}
            disableFocusRipple
            disableTouchRipple
            disableRipple
            onClick={() => history.goBack()}
          >
            {t('back_company')}
          </Button>
        </Grid>
        <Grid item xs={12} md={6} lg={8} className={classes.name}>
          <div>{application?.companyName}</div>
        </Grid>
        <Grid container item xs={12} direction="row" spacing={2}>
          <Grid item xs={6}>
            <div className={classes.column}>
              <div className="section-container">
                <img src={boardfolio1} className="section-icon" alt="" />
                <span className="section-title f1">Access Rights</span>
              </div>
            </div>
            <Paper className={classes.paper}>
              <Table
                className={classes.table}
                aria-label="user infomation table"
              >
                <TableBody>
                  {[
                    {
                      label: t('tabbar:entity_management'),
                      value: company?.entityManagement,
                      pending: application?.entityManagement,
                    },
                    {
                      label: t('tabbar:register_of_members'),
                      value: company?.registerOfMember,
                      pending: application?.registerOfMember,
                    },
                    {
                      label: t('tabbar:ccass_holding'),
                      value: company?.ccassHolding,
                      pending: application?.ccassHolding,
                    },
                    {
                      label: t('tabbar:reports'),
                      value: company?.reports,
                      pending: application?.reports,
                    },
                    {
                      label: t('tabbar:shareholder_analytics'),
                      value: company?.ownershipAnalysis,
                      pending: application?.ownershipAnalysis,
                    },
                    {
                      label: t('tabbar:market_intelligence'),
                      value: company?.marketIntelligence,
                      pending: application?.marketIntelligence,
                    },
                    {
                      label: t('tabbar:industry_analyst_report'),
                      value: company?.industryAnalysis,
                      pending: application?.industryAnalysis,
                    },
                    {
                      label: t('tabbar:eagm'),
                      value: company?.eAGM,
                      pending: application?.eAGM,
                    },
                    {
                      label: t('tabbar:board_portal'),
                      value: company?.boardPortal,
                      pending: application?.boardPortal,
                    },
                    {
                      label: t('tabbar:invoice_management'),
                      value: company?.invoiceManagement,
                      pending: application?.invoiceManagement,
                    },
                    {
                      label: t('tabbar:thought_leadership'),
                      value: company?.thoughtLeadership,
                      pending: application?.thoughtLeadership,
                    },
                  ].map(({ label, value, pending }) => (
                    <TableRow key={`row-${label}`}>
                      <TableCell component="td" className={classes.label}>
                        {label}
                      </TableCell>
                      <TableCell component="td" className={classes.value}>
                        <CheckIcon
                          htmlColor={pending ? '#0909B7' : 'rgba(0,0,0,0.3)'}
                          fontSize="large"
                          style={{
                            backgroundColor:
                              pending !== value ? 'yellow' : undefined,
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableRow style={{ verticalAlign: 'top' }}>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.label}
                      colSpan={1}
                    >
                      {t('requested_by')}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.value}
                      colSpan={2}
                    >
                      {application?.requestedBy}
                    </TableCell>
                  </TableRow>
                  {application?.approvedAt && (
                    <TableRow style={{ verticalAlign: 'top' }}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.label}
                        colSpan={1}
                      >
                        Approval Date
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.value}
                        colSpan={2}
                      >
                        {formatDate(application.approvedAt)}
                      </TableCell>
                    </TableRow>
                  )}
                  {application?.rejectedAt && (
                    <TableRow style={{ verticalAlign: 'top' }}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.label}
                        colSpan={1}
                      >
                        Approval Date
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.value}
                        colSpan={2}
                      >
                        {formatDate(application.rejectedAt)}
                      </TableCell>
                    </TableRow>
                  )}
                  {application?.rejectReason && (
                    <TableRow style={{ verticalAlign: 'top' }}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.label}
                        colSpan={1}
                      >
                        Reject Reason
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.value}
                        colSpan={2}
                      >
                        {application.rejectReason}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.column}>
              <div className="section-container">
                <Settings className="section-icon" />
                <span className="section-title f1">Settings</span>
              </div>
            </div>
            <Paper className={classes.paper}>
              <Table
                className={classes.table}
                aria-label="setting infomation table"
              >
                <TableBody>
                  <TableRow>
                    <TableCell component="td" className={classes.label}>
                      {t('manage_company_access:maker_checker_function')}
                    </TableCell>
                    <TableCell component="td" className={classes.value}>
                      <CheckIcon
                        htmlColor={
                          application?.makerCheckerFunc
                            ? '#0909B7'
                            : 'rgba(0,0,0,0.3)'
                        }
                        fontSize="large"
                        style={{
                          backgroundColor:
                            application?.makerCheckerFunc !==
                            company?.makerCheckerFunc
                              ? 'yellow'
                              : undefined,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="td" className={classes.label}>
                      {t('manage_company_access:max_number_of_acc')}
                    </TableCell>
                    <TableCell component="td" className={classes.value}>
                      <CheckIcon
                        htmlColor={
                          application?.checkAcctNoLimit
                            ? '#0909B7'
                            : 'rgba(0,0,0,0.3)'
                        }
                        fontSize="large"
                        style={{
                          backgroundColor:
                            application?.checkAcctNoLimit !==
                            company?.checkAcctNoLimit
                              ? 'yellow'
                              : undefined,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="td" className={classes.label} />
                    <TableCell
                      component="th"
                      scope="row"
                      className={classes.value}
                      colSpan={2}
                      style={{
                        backgroundColor:
                          company?.acctNoLimitation !==
                          application?.acctNoLimitation
                            ? 'yellow'
                            : undefined,
                      }}
                    >
                      {application?.acctNoLimitation}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/*
             
              <Field
                component={TextField}
                name="acctNoLimitation"
                type="number"
                // inputProps={{ disabled: !values.checkAcctNoLimit }}
                style={{ margin: '0 8px', width: 80 }}
                disabled
              /> */}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {application?.status === 'Pending' &&
        (staffInfo?.role === 'TricorSuperAdmin' ||
          staffInfo?.role === 'TricorChecker') && (
          <div className={classes.footer}>
            <div style={{ flex: 1 }} />
            <RejectCompanyDialog
              applicationIds={[id]}
              onRejected={() => {
                history.goBack();
              }}
              onRejectError={(e) => {
                let errorMsgKey = 'message';
                if (lang === 'zh') errorMsgKey = 'messageZH';
                else if (lang === 'cn') errorMsgKey = 'messageCN';
                setError(e.response.data[errorMsgKey]);
              }}
            />
            <Button
              disabled={loading}
              variant="contained"
              classes={{
                contained: classes.buttonAccept,
              }}
              onClick={onPressApprove}
            >
              {t('manage_applications:button_approve')}
            </Button>
          </div>
        )}
      <Dialog
        onClose={() => setError('')}
        aria-labelledby="error-dialog-title"
        open={Boolean(error)}
      >
        <DialogTitle id="error-dialog-title" onClose={() => setError('')}>
          Error
        </DialogTitle>
        <DialogContent>
          <div className={classes.errorMessage}>{error}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CompanyApplicationDetail;

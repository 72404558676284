import { createContext, Dispatch } from 'react';
import { Staff } from '../../../models/Staff';

export interface UserReducerState {
  user?: Staff;
}

export interface SetUserAction {
  type: 'SET_USER';
  payload: { user: Staff };
}

export interface ContextProps {
  state: UserReducerState;
  dispatch: Dispatch<SetUserAction>;
}

export const userReducer = (state: UserReducerState, action: SetUserAction) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

export const UserReducerContext = createContext<ContextProps>(
  {} as ContextProps,
);

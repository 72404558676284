/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */

import { SortingRule } from 'react-table';
import { red } from '@material-ui/core/colors';
import axios from './index';
import { AccessRights, CompanyUser } from '../models/CompanyUser';
import { PaginationResponse } from '../models/PaginationResponse';
import { MyRequestResponse } from '../models/MyRequestResponse';
import { Staff } from '../models/Staff';
import { TricorStaff } from '../models/TricorStaff';
import MyRequest from '../models/MyRequest';
import { Feedback } from '../models/Feedback';
import { ListedCompany } from '../models/ListedCompany';
import { EntityResponse } from '../models/EntityResponse';
import { CompanyApplication } from '../models/CompanyApplication';

const getStaffs = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  filterField?: string,
  filterValue?: string,
) => {
  return axios.get<PaginationResponse<Staff>>('staff', {
    params: {
      pageSize,
      page,
      fullTextSearch: query,
      sortBy: sortBy?.id,
      sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      filterField,
      filterValue,
    },
  });
};

const getStaff = (id: string | number) => axios.get<Staff>(`staff/${id}`);

const createStaff = (data: any) => axios.post('staff/create', data);

const deleteStaff = (id: string | number) => axios.delete(`staff/${id}`);

const getTricorStaffAvatar = (staffId: string) =>
  axios.get<string>(`staff/avatar/?mail=${encodeURIComponent(staffId)}`);

const updateStaffRole = (id: string, role: string) =>
  axios.put(`staff/${id}/role`, { role });

const getAllTricorCheckers = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  viewUser?: number,
) =>
  axios
    .get<PaginationResponse<Staff>>('staff/getAllChecker', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
        viewUser,
      },
    })
    .then((res) => res.data);

const getAllUserCheckers = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  viewUser?: number,
) =>
  axios
    .get<PaginationResponse<CompanyUser>>('user/getAllChecker', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
        viewUser,
      },
    })
    .then((res) => res.data);

const assignClientChecker = (
  userId: string | number,
  checkerId: string | number,
) => axios.put(`user/${userId}/assignChecker/${checkerId}`);

const unassignClientChecker = (
  userId: string | number,
  checkerId: string | number,
) => axios.delete(`user/${userId}/unassignChecker/${checkerId}`);

const getUserAssignedChecker = (
  userId: string | number,
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
) =>
  axios
    .get<PaginationResponse<any>>(`user/${userId}/checker`, {
      params: {
        pageSize,
        page,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      },
    })
    .then((res) => res.data);

const getCompanyAccessList = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
) =>
  axios
    .get<PaginationResponse<any>>('company/access/list', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      },
    })
    .then((res) => res.data);

const getCompanyAccess = ({
  id,
  romOid,
  tickerNo,
}: {
  id?: string | number | null;
  romOid?: string | null;
  tickerNo?: string | null;
}) =>
  romOid
    ? axios.get(`company/access/romOid/${romOid}`).then((res) => res.data)
    : tickerNo
    ? axios.get(`company/access/tickerNo/${tickerNo}`).then((res) => res.data)
    : axios.get(`company/access/${id}`).then((res) => res.data);

const updateCompanyAccess = (id: string, data: any) =>
  axios.put(`company/access/${id}/update`, data);

const getCompanyUsers = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
) =>
  axios
    .get<PaginationResponse<CompanyUser>>('user', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      },
    })
    .then((res) => res.data);

const getCompanyUser = (userId: string | number) =>
  axios.get<CompanyUser>(`user/${userId}`).then((res) => res.data);

const createCompanyUser = (data: any) => axios.post('user/client', data);

const deleteCompanyUser = (userId: number) => axios.delete(`user/${userId}`);

const updateProfile = (userId: number | string, data: any) =>
  axios.put(`user/${userId}/update`, data);

const updateAccessRights = (
  userId: string,
  areaCode: string,
  phoneNo: string,
  accessRights: AccessRights,
) => axios.put(`user/${userId}/right`, { areaCode, phoneNo, accessRights });

const getClientRequests = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  filterField?: string,
  filterValue?: string,
) => {
  const mapSortBy = { ...sortBy } as SortingRule<any>;
  if (mapSortBy) {
    if (sortBy?.id === 'entityName') mapSortBy.id = 'companyEntity.name';
  }

  return axios
    .get<PaginationResponse<MyRequestResponse>>('request', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: mapSortBy?.id,
        sortOrder: mapSortBy ? (mapSortBy.desc ? 'desc' : 'asc') : undefined,
        filterField,
        filterValue,
      },
    })
    .then((res) => res.data)
    .then(({ data, ...others }) => ({
      ...others,
      data: data.map((item: any) => ({
        ...item,
        status: item?.latestLog?.postWorkFlow || '',
      })),
    }));
};

const updateClientRequestStatus = (requestId: string, status: string) =>
  axios.put(`request/${requestId}/status`, { status });

const assignRM = (
  staffId: string | number,
  userId: string | number,
  isPrimary?: boolean,
) =>
  axios.put(
    `staff/${staffId}/assign/${userId}?isPrimary=${
      isPrimary ? 'true' : 'false'
    }`,
  );

const getTricorStaffs = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  filterField?: string,
  filterValue?: string,
  viewUser?: number,
) => {
  return axios.get<PaginationResponse<TricorStaff>>('staff/query', {
    params: {
      pageSize,
      page,
      fullTextSearch: query,
      sortBy: sortBy?.id,
      sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      filterField,
      filterValue,
      viewUser,
    },
  });
};

const unassignRM = (staffId: string | number, userId: string | number) =>
  axios.delete(`staff/${staffId}/unassign/${userId}`);

const getContactPersons = (userId: string) =>
  axios.get(`user/${userId}/contact`);

const assignContactPerson = (
  tricorId: string | number,
  userId: string | number,
  isPrimary?: boolean,
) =>
  axios.post(`user/${userId}/contact`, {
    userId,
    tricorId,
    isPrimary,
  });

const unassignContactPerson = (
  tricorId: string | number,
  userId: string | number,
) => axios.delete(`user/${userId}/contact/${tricorId}`);

const getFeedbacks = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  filterField?: string,
  filterValue?: string,
) => {
  return axios
    .get<PaginationResponse<Feedback>>('user/feedback', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
        filterField,
        filterValue,
      },
    })
    .then((res) => res.data);
};

const getFeedbacksReport = () =>
  axios.get(`${process.env.REACT_APP_BASE_URL}/user/feedback/report`, {
    responseType: 'blob',
  });

const getUserReport = () =>
  axios.get('/user/report', {
    responseType: 'blob',
  });

const getUserTickers = (userId: number, sortBy?: SortingRule<any>) =>
  axios.get(`user/${userId}/cscompanies`, {
    params: {
      sortBy: sortBy?.id,
      sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
    },
  });

const addTicker = (userId: number, tickerId: string) =>
  axios.post(`user/${userId}/cscompanies/HK/${tickerId}`);

const removeTicker = (userId: number, tickerId: string) =>
  axios.delete(`user/${userId}/cscompanies/HK/${tickerId}`);

const checkTicker = (tickerRegion: string, tickerNo: string) =>
  axios
    .put(`user/cscompanies/validate/${tickerRegion}/${tickerNo}`)
    .then((res) => res.data);

const getAreaCodes = () => axios.get('user/areaCodes').then((res) => res.data);

const importClient = (file: File) => {
  const formData = new FormData();
  formData.append('uploadFile', file);

  return axios.post('user/importExcel', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

const getAuditReport = (fromDate: string, toDate: string) =>
  axios.get('user/auditLogReport', {
    params: { fromDate, toDate },
    responseType: 'blob',
  });

const getPendingCompanyApprovals = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
) =>
  axios
    .get<PaginationResponse<any>>('company/access/application/pending', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      },
    })
    .then((res) => res.data);

const getCompanyApprovedApplications = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
) =>
  axios
    .get<PaginationResponse<any>>('company/access/application/approved', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      },
    })
    .then((res) => res.data);

const getCompanyRejectedApplications = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
) =>
  axios
    .get<PaginationResponse<any>>('company/access/application/rejected', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      },
    })
    .then((res) => res.data);

const approveCompanyApplications = (requestIds: number[]) =>
  axios.post('company/access/application/approve', {
    requestIds,
  });

const rejectCompanyApplications = (requestIds: number[], reason: string) =>
  axios.post('company/access/application/reject', {
    requestIds,
    rejectReason: reason,
  });

const getPendingApprovals = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
) =>
  axios
    .get<PaginationResponse<any>>('application', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      },
    })
    .then((res) => res.data);

const getApprovedApplications = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
) =>
  axios
    .get<PaginationResponse<any>>('application/approved', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      },
    })
    .then((res) => res.data);

const getRejectedApplications = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
) =>
  axios
    .get<PaginationResponse<any>>('application/rejected', {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      },
    })
    .then((res) => res.data);

const getCompanyApplication = (applicationId: string | number) =>
  axios
    .get<CompanyApplication>(`company/access/application/${applicationId}`)
    .then((res) => res.data);

const approveApplications = (requestIds: number[]) =>
  axios.post('application/approve', {
    requestIds,
  });

const rejectApplications = (requestIds: number[], reason: string) =>
  axios.post('application/reject', {
    requestIds,
    rejectReason: reason,
  });

const cancelApplications = (requestIds: number[]) =>
  axios.post('application/cancel', {
    requestIds,
  });

const sendPendingApprovalEmails = (
  applicationIds: number[],
  checkerIds: number[],
  isCompanyApplication?: boolean,
) =>
  axios.post(
    isCompanyApplication
      ? 'company/access/application/sendPendingApprovalEmails'
      : 'application/sendPendingApprovalEmails',
    {
      applicationIds,
      checkerIds,
    },
  );

const getApplication = (applicationId: string | number) =>
  axios
    .get<CompanyUser>(`application/${applicationId}`)
    .then((res) => res.data);

const getApplicationRom = (
  applicationId: number,
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
) => {
  const mapSortBy = sortBy;
  if (mapSortBy) {
    if (sortBy?.id === 'name') mapSortBy.id = 'cmpEngName';
    else if (sortBy?.id === 'stockCode') mapSortBy.id = 'insCode';
  }
  return axios
    .get<PaginationResponse<ListedCompany>>(
      `application/${applicationId}/rom`,
      {
        params: {
          pageSize,
          page,
          fullTextSearch: query,
          sortBy: mapSortBy?.id,
          sortOrder: mapSortBy ? (mapSortBy.desc ? 'desc' : 'asc') : undefined,
        },
      },
    )
    .then((res) => res.data);
};

const getApplicationEntities = (
  applicationId: number,
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  filterField?: string,
  filterValue?: string,
) =>
  axios.get<PaginationResponse<EntityResponse>>(
    `application/${applicationId}/em`,
    {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
        filterField,
        filterValue,
      },
    },
  );

const getApplicationHkTicker = (
  applicationId: number,
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  filterField?: string,
  filterValue?: string,
) =>
  axios.get<PaginationResponse<any>>(
    `application/${applicationId}/cscompanies`,
    {
      params: {
        pageSize,
        page,
        fullTextSearch: query,
        sortBy: sortBy?.id,
        sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
        filterField,
        filterValue,
      },
    },
  );

const getApplicationContactPoint = (applicationId: number) =>
  axios.get(`application/${applicationId}/contact`);

const getApplicationChecker = (
  applicationId: number,
  pageSize?: number,
  page?: number,
) =>
  axios.get<PaginationResponse<CompanyUser>>(
    `application/${applicationId}/checker`,
    {
      params: {
        pageSize,
        page,
      },
    },
  );

export default {
  getStaffs,
  getStaff,
  createStaff,
  deleteStaff,
  getTricorStaffAvatar,
  updateStaffRole,

  getCompanyUsers,
  getCompanyUser,

  createCompanyUser,
  deleteCompanyUser,
  updateAccessRights,

  getClientRequests,
  updateClientRequestStatus,
  assignRM,
  unassignRM,

  getTricorStaffs,
  getContactPersons,
  assignContactPerson,
  unassignContactPerson,

  getFeedbacks,
  getFeedbacksReport,
  getUserReport,

  getUserTickers,
  addTicker,
  removeTicker,
  checkTicker,
  getAreaCodes,

  updateProfile,

  getAllUserCheckers,
  getUserAssignedChecker,
  assignClientChecker,
  unassignClientChecker,

  getAllTricorCheckers,
  getCompanyAccessList,
  getCompanyAccess,
  updateCompanyAccess,
  importClient,

  getAuditReport,

  getPendingCompanyApprovals,
  getCompanyApprovedApplications,
  getCompanyRejectedApplications,
  approveCompanyApplications,
  rejectCompanyApplications,
  getCompanyApplication,

  getPendingApprovals,
  getApprovedApplications,
  getRejectedApplications,

  approveApplications,
  rejectApplications,
  cancelApplications,
  sendPendingApprovalEmails,
  getApplication,
  getApplicationRom,

  getApplicationEntities,
  getApplicationContactPoint,
  getApplicationChecker,
  getApplicationHkTicker,
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
import { SortingRule } from 'react-table';
import axios from './index';
import {
  CODFormValues,
  DPCFormValues,
  RSRFormValues,
  TSFormValues,
} from '../models/RequestForm';
import { EntitiySummaryResponse } from '../models/EntitiySummaryResponse';
import { Director } from '../models/Director';
import { MyRequestResponse } from '../models/MyRequestResponse';
import { CompanyUser } from '../models/CompanyUser';
import { EntityResponse } from '../models/EntityResponse';
import { PaginationResponse } from '../models/PaginationResponse';
import { Document } from '../models/Document';
import { CompanySecretary } from '../models/CompanySecretary';

const getEntities = (
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  filterField?: string,
  filterValue?: string,
  viewUser?: number,
) =>
  axios.get<PaginationResponse<EntityResponse>>('/company', {
    params: {
      pageSize,
      page,
      fullTextSearch: query,
      sortBy: sortBy?.id,
      sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      filterField,
      filterValue,
      viewUser,
    },
  });

const getEntitiesFilterOptions = () => {
  const fields = ['status', 'jurisdiction'];
  return Promise.all(
    fields.map((field) => axios.get('/company/options', { params: { field } })),
  ).then((res) =>
    res.reduce(
      (acc, { data }, index) => ({ ...acc, [fields[index]]: data }),
      {},
    ),
  );
};

const getEntitySummary = (fileCode: string) =>
  axios.get<EntitiySummaryResponse[]>('viewpoint/summary', {
    params: { fileCode },
  });

const getEntitySecretary = (fileCode: string) =>
  axios.get<CompanySecretary[]>('viewpoint/compsec', {
    params: { fileCode },
  });

const getEntityDirectors = (fileCode: string) =>
  axios.get<Director[]>('viewpoint/directors', {
    params: { fileCode },
  });

const getEntityShareholders = (fileCode: string) =>
  axios.get<Director[]>('viewpoint/shareholders', {
    params: { fileCode },
  });

const getEntity = (fileCode: string) =>
  Promise.all([
    axios.get<{ data: EntityResponse[] }>('company', {
      params: { filterField: 'fileCode', filterValue: fileCode },
    }),
    getEntitySummary(fileCode),
    getEntityDirectors(fileCode),
  ]).then((res) => {
    const [res1, res2, res3] = res;
    return { ...res1.data.data?.[0], ...res2.data[0], directors: res3.data };
  });

const getDownlineEntities = (fileCode: string) =>
  axios.get('viewpoint/structure', { params: { fileCode, isActive: true } });

const getEntityDocuments = (
  fileCode: string,
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  filterField?: string,
  filterValue?: string,
) =>
  axios.get<PaginationResponse<Document>>('viewpoint/documents', {
    params: {
      fileCode,
      pageSize,
      page,
      fullTextSearch: query,
      sortBy: sortBy?.id,
      sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      filterField,
      filterValue,
    },
  });

const getEntityDocumentsOptions = (fileCode: string) =>
  axios
    .get('/viewpoint/documents/options', { params: { fileCode } })
    .then((res) => ({ cabinet: res.data }));

const getEntityRequests = (fileCode: string) =>
  axios.get<MyRequestResponse[]>(`request/company/${fileCode}`);

const createRequestCod = (fileCode: string, data: CODFormValues) =>
  axios.post(`request/company/${fileCode}/changeOfDirector`, {
    ...data,
    effectiveDateOfNewDirector: data.effectiveDateOfNewDirector.toISOString(),
    effectiveDateOfResignation: data.effectiveDateOfResignation.toISOString(),
  });

const createRequestDPC = (fileCode: string, data: DPCFormValues) =>
  axios.post(`request/company/${fileCode}/directorParticularChange`, {
    ...data,
    effectiveDate: data.effectiveDate.toISOString(),
  });

const createRequestRSR = (fileCode: string, data: RSRFormValues) =>
  axios.post(`request/company/${fileCode}/reviewOfStatutory`, {
    ...data,
    preferredDateTime:
      data.reviewType === 'Onsite Visit' ? data.preferredDateTime : undefined,
  });

const createRequestTS = (fileCode: string, data: TSFormValues) =>
  axios.post(`request/company/${fileCode}/transfershares`, {
    ...data,
    effectiveDate: data.effectiveDate.toISOString(),
  });

const getUsers = (fileCode: string) =>
  axios.get<CompanyUser[]>(`company/${fileCode}/user`);

const addUser = (userId: string | number, fileCode: string) =>
  axios.put(`user/${userId}/em/bind?fileCode=${fileCode}`);

const removeUser = (userId: string | number, fileCode: string) =>
  axios.put(`user/${userId}/em/unbind?fileCode=${fileCode}`);

const getUserEntities = (
  userId: string | number,
  pageSize?: number,
  page?: number,
  query?: string,
  sortBy?: SortingRule<any>,
  filterV2?: string,
  filterValue?: string,
) =>
  axios.get<PaginationResponse<EntityResponse>>(`user/${userId}/em`, {
    params: {
      pageSize,
      page,
      fullTextSearch: query,
      sortBy: sortBy?.id,
      sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
      filterV2,
      filterValue,
    },
  });

const getSummaryReport = (fileCode: string, lang: string) =>
  axios.get('viewpoint/summary/report', {
    responseType: 'blob',
    params: { fileCode, lang },
  });

const getShareholdersReport = (fileCode: string, lang: string) =>
  axios.get('viewpoint/shareholders/report', {
    responseType: 'blob',
    params: { fileCode, lang },
  });

const getDirectorsReport = (fileCode: string, lang: string) =>
  axios.get('viewpoint/directors/report', {
    responseType: 'blob',
    params: { fileCode, lang },
  });

export default {
  getEntities,
  getEntitiesFilterOptions,
  getEntity,
  getDownlineEntities,
  getEntitySummary,
  getEntitySecretary,
  getEntityDirectors,
  getEntityShareholders,
  getEntityDocuments,
  getEntityDocumentsOptions,
  getEntityRequests,
  createRequestCod,
  createRequestDPC,
  createRequestRSR,
  createRequestTS,

  getUsers,
  addUser,
  removeUser,

  getUserEntities,

  getSummaryReport,
  getShareholdersReport,
  getDirectorsReport,
};

/* eslint-disable no-case-declarations */
import { format } from 'date-fns';
import {
  CODFormValues,
  DPCFormValues,
  TSFormValues,
  RSRFormValues,
} from './RequestForm';
import { MyRequestResponse } from './MyRequestResponse';
import { EntityResponse } from './EntityResponse';

const fileTypeFields: { [key: string]: string } = {
  SalesAndPurchaseAgreement: 'sale_and_purchase_agreement',
  LatestStatement: 'latest_consolidated_statesment',
  LatestManagementAccount: 'latest_management_accounts',
  IDCardOrPassport: 'certified_id_copy',
  AddressProof: 'proof_of_address',
  ConsiderationToBeReceivedPaid: 'consideration_to_be_received_or_paid',
};

const formatDate = (date?: Date, dateFormat = 'yyyy-MM-dd') => {
  if (!date) return '-';
  return format(new Date(date), dateFormat);
};

export default class MyRequest {
  id: number;

  entity: EntityResponse;

  requestType: string;

  requestTypeCode: string;

  requestCod: CODFormValues | null;

  requestDPC: DPCFormValues | null;

  requestTS: TSFormValues | null;

  requestRSR: RSRFormValues | null;

  createdAt: string;

  status: string;

  files: {
    blobName: string;
    type: string;
    id: number;
  }[];

  constructor(parameters: MyRequestResponse) {
    this.id = parameters.id;
    this.entity = parameters.companyEntity;
    this.requestType = parameters.requestType.displayName;
    this.requestTypeCode = parameters.requestType.code;
    this.requestCod = parameters.codRequest;
    this.requestDPC = parameters.dpcRequest;
    this.requestTS = parameters.tsRequest;
    this.requestRSR = parameters.rsrRequest;
    this.createdAt = parameters.createdAt;
    this.status =
      parameters.status === 'Created' ? 'Submitted' : parameters.status;
    this.files = parameters.files;
  }

  public setStatus(status: string): void {
    this.status = status;
  }

  public get entityName(): string {
    return this.entity?.name;
  }

  public get requestDetail(): {
    section: string;
    data: { field: string; value: string | number | undefined }[];
  }[] {
    switch (this.requestTypeCode) {
      case 'COD':
        const sectionsCOD = [
          {
            section: 'entity_management:change_of_directors',
            data: [
              {
                field: 'entity_management:name_in_chinese',
                value: this.requestCod?.directorNameZh,
              },
              {
                field: 'entity_management:name_in_english',
                value: this.requestCod?.directorNameEn,
              },
              {
                field: 'entity_management:alias_in_chinese',
                value: this.requestCod?.aliasZh,
              },
              {
                field: 'entity_management:alias_in_chinese',
                value: this.requestCod?.aliasEn,
              },
              {
                field: 'entity_management:email_address',
                value: this.requestCod?.email,
              },
              {
                field: 'entity_management:residential_address',
                value: `${this.requestCod?.address1} ${this.requestCod?.address2}`,
              },
              {
                field: 'entity_management:hk_id_card_number',
                value: this.requestCod?.idCardNo,
              },
              {
                field: 'entity_management:passport_issuing_country',
                value: this.requestCod?.passportCountry,
              },
              {
                field: 'entity_management:passport_number',
                value: this.requestCod?.passportNo,
              },
              {
                field: 'entity_management:effective_date_of_change',
                value: formatDate(this.requestCod?.effectiveDateOfNewDirector),
              },
            ],
          },
          {
            section: 'entity_management:resignation_of_director',
            data: [
              {
                field: 'entity_management:resignation_of_director',
                value: this.requestCod?.nameOfResignedDirector,
              },
              {
                field: 'entity_management:effective_date_of_change',
                value: formatDate(this.requestCod?.effectiveDateOfResignation),
              },
              {
                field: 'entity_management:reason_of_cessation',
                value: this.requestCod?.reasonOfCessation,
              },
            ],
          },
        ];
        if (
          this.requestCod?.alternativeTo &&
          this.requestCod?.alternativeDirector
        ) {
          sectionsCOD[0].data.push({
            field: 'entity_management:alternate_to',
            value: this.requestCod?.alternativeTo,
          });
        }
        return sectionsCOD;
      case 'DPC':
        const sectionsDPC = [
          {
            section: 'entity_management:updated_particulars',
            data: [
              {
                field: 'entity_management:name_in_chinese',
                value: this.requestDPC?.directorNameZh,
              },
              {
                field: 'entity_management:name_in_english',
                value: this.requestDPC?.directorNameEn,
              },
              {
                field: 'entity_management:alias_in_chinese',
                value: this.requestDPC?.aliasZh,
              },
              {
                field: 'entity_management:alias_in_chinese',
                value: this.requestDPC?.aliasEn,
              },
              {
                field: 'entity_management:email_address',
                value: this.requestDPC?.email,
              },
              {
                field: 'entity_management:residential_address',
                value: `${this.requestDPC?.address1} ${this.requestDPC?.address2}`,
              },
              {
                field: 'entity_management:effective_date_of_change',
                value: formatDate(this.requestDPC?.effectiveDate),
              },
            ],
          },
        ];

        if (this.requestDPC?.idCardNo) {
          sectionsDPC[0].data.push({
            field: 'entity_management:hk_id_card_number',
            value: this.requestDPC?.idCardNo,
          });
        } else if (this.requestDPC?.passportNo) {
          sectionsDPC[0].data.push(
            {
              field: 'entity_management:passport_issuing_country',
              value: this.requestDPC?.passportCountry,
            },
            {
              field: 'entity_management:passport_number',
              value: this.requestDPC?.passportNo,
            },
          );
        }

        if (this.files.length > 0) {
          sectionsDPC.push({
            section: 'entity_management:supporting_documents',
            data: this.files.map((file) => ({
              field: fileTypeFields[file.type]
                ? `entity_management:${fileTypeFields[file.type]}`
                : '',
              value: file.blobName,
            })),
          });
        }

        return sectionsDPC;
      case 'RSR':
        const sections = [
          {
            section: 'entity_management:request_for_statutory_review',
            data: [
              {
                field: 'entity_management:name_of_audit_firm_name',
                value: this.requestRSR?.auditFirm,
              },
              {
                field: 'entity_management:name_of_contact_person',
                value: this.requestRSR?.contactPerson,
              },
              {
                field: 'entity_management:phone_number',
                value: this.requestRSR?.phone,
              },
              {
                field: 'entity_management:email_address',
                value: this.requestRSR?.email,
              },
              {
                field: 'entity_management:type_of_review',
                value: this.requestRSR?.reviewType,
              },
            ],
          },
        ];
        if (this.requestRSR?.reviewType === 'Onsite Visit')
          sections[0].data.push(
            {
              field: 'entity_management:preferred_date_time',
              value: formatDate(
                new Date(this.requestRSR?.preferredDateTime),
                'yyyy-MM-dd hh:mm a',
              ),
            },
            {
              field:
                'entity_management:years_of_compnay_records_to_be_reviewed',
              value: this.requestRSR?.yearsToReview.toString(),
            },
          );

        return sections;
      case 'TS':
        const sectionsTS = [
          {
            section: 'entity_management:transferor',
            data: [
              {
                field: 'entity_management:name_of_transferor',
                value: this.requestTS?.nameOfTransferor,
              },
              {
                field: 'entity_management:transferor_address',
                value: `${this.requestTS?.address1} ${this.requestTS?.address2}`,
              },
              {
                field: 'entity_management:occupationOfTransferor',
                value: this.requestTS?.occupationOfTransferor,
              },
            ],
          },
          {
            section: 'entity_management:transferee',
            data: [
              {
                field: 'entity_management:name_of_transferee',
                value: this.requestTS?.nameOfTransferee,
              },
              {
                field: 'entity_management:transferee_address',
                value: `${this.requestTS?.address1OfTransferee} ${this.requestTS?.address2OfTransferee}`,
              },
              {
                field: 'entity_management:occupation_of_transferee',
                value: this.requestTS?.occupationOfTransferee,
              },
            ],
          },
          {
            section: 'entity_management:transfer_of_shares',
            data: [
              {
                field: 'entity_management:number_of_shares_to_be_transferred',
                value: this.requestTS?.noOfShare,
              },
              {
                field: 'entity_management:value_of_transfer',
                value: this.requestTS?.value,
              },
              {
                field: 'entity_management:occupation_of_transferee',
                value: this.requestTS?.occupationOfTransferee,
              },
              {
                field: 'entity_management:effective_date_of_transfer',
                value: formatDate(this.requestTS?.effectiveDate),
              },
            ],
          },
        ];

        if (this.files.length > 0) {
          sectionsTS.push({
            section: 'entity_management:supporting_documents',
            data: this.files.map((file) => ({
              field: fileTypeFields[file.type]
                ? `entity_management:${fileTypeFields[file.type]}`
                : '',
              value: file.blobName,
            })),
          });
        }
        return sectionsTS;
      default:
        return [];
    }
  }
}

import React from 'react';
import Calendar, { DateCallback } from 'react-calendar';
import { addDays, format, getDay, isBefore } from 'date-fns';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import { useTranslation } from 'react-i18next';
import { isSameDay } from 'date-fns/esm';
import EventsDetail from './EventsDetail';
import { CalendarEvent } from '../../../../models/CalendarEvent';
import { ListedCompany } from '../../../../models/ListedCompany';
import CalendarService from '../../../../api/calendar';
import { events as eventsIcon } from '../../../../assets';
import './Calendar.css';
import CreateEvent from './CreateEvent';
import EventsDialog from './EventsDialog';

interface Props {
  company?: ListedCompany;

  defaultValue: Date;
}

const CustomCalendar: React.FC<Props> = ({ company, defaultValue }) => {
  const { t, i18n } = useTranslation('home');
  const [events, setEvents] = React.useState<CalendarEvent[]>([]);
  const [selectedEvents, setSelectedEvents] = React.useState<CalendarEvent[]>();
  const [selectedDate, setSelectedDate] = React.useState<Date>(new Date());
  const [eventObj, setEventObj] = React.useState<{
    [date: string]: CalendarEvent[];
  }>({});

  React.useEffect(() => {
    if (company) {
      CalendarService.getEvents(company.cmpOid).then((res) => {
        setEvents(res.data);
      });
    }
  }, [company]);

  React.useEffect(() => {
    setEventObj(
      events.reduce((acc: { [date: string]: CalendarEvent[] }, event) => {
        const temp = { ...acc };
        let date = new Date(event.startTime);
        const dateEnd = new Date(event.endTime);

        do {
          const key = format(date, 'dd/MM/yyyy');
          temp[key] = temp[key] ? [...temp[key], event] : [event];
          date = addDays(date, 1);
        } while (isBefore(date, dateEnd) || isSameDay(date, dateEnd));

        return temp;
      }, {}),
    );
  }, [events]);

  const getEvents = () => {
    if (company) {
      CalendarService.getEvents(company.cmpOid).then((res) => {
        setEvents(res.data);
      });
    }
  };

  const onClickDay = React.useCallback(
    (date: Date) => {
      setSelectedDate(date);
      setSelectedEvents(eventObj[format(date, 'dd/MM/yyyy')]);
    },
    [eventObj],
  );

  const renderTileContent = ({ activeStartDate, date }: any) => {
    const day = date.getDate();

    const isPublicHoliday =
      getDay(date) === 0 ||
      eventObj[format(date, 'dd/MM/yyyy')]?.findIndex(
        (item) => item.isPublicHoliday,
      ) > -1;

    return (
      <div className="react-calendar__tile__day_container">
        <div
          className={`react-calendar__tile__day ${
            isPublicHoliday && 'holiday'
          }`}
        >
          <span>{day}</span>
        </div>
        <div className="react-calendar__tile__indicator_container">
          {eventObj[format(date, 'dd/MM/yyyy')]
            ?.filter((item) => !item.isPublicHoliday)
            ?.slice(0, 2)
            ?.map((event) => (
              <div
                key={`tile::${event.id}`}
                className="react-calendar__tile__indicator"
                style={{ backgroundColor: event.colorCode || 'red' }}
              />
            ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="section-container">
        <img src={eventsIcon} className="section-icon" alt="events quotation" />
        <span className="section-title f1">{t('events')}</span>
        <CreateEvent
          romOid={company?.cmpOid}
          onEventCreated={getEvents}
          defaultDate={selectedDate}
        />
        <EventsDialog romOid={company?.cmpOid} refreshEvents={getEvents} />
      </div>
      <Calendar
        next2Label={null}
        prev2Label={null}
        prevLabel={<NavigateBeforeRoundedIcon />}
        nextLabel={<NavigateNextRoundedIcon />}
        tileContent={renderTileContent}
        defaultValue={defaultValue}
        locale={i18n.language === 'cn' ? 'zh-CN' : i18n.language}
        onClickDay={onClickDay}
        calendarType="US"
      />
      <EventsDetail
        events={selectedEvents}
        onClose={() => setSelectedEvents(undefined)}
        refreshEvents={getEvents}
      />
    </div>
  );
};

export default CustomCalendar;

import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { RootState } from './store';
import { selectUserInfo } from './pages/auth/duck/selector';
import { CompanyUser } from './models/CompanyUser';

interface Props {
  location: {
    pathname: string;
  };

  user: CompanyUser | null;
}
const GoogleAnalytics = ({ location: { pathname }, user }: Props) => {
  React.useEffect(() => {
    if (
      !['/register-of-members', '/entity-management', '/reports'].includes(
        pathname,
      )
    ) {
      ReactGA.pageview(pathname);
      if (user && pathname === '/industry-analyst-report') {
        ReactGA.event({
          category: 'Page View',
          action: 'Industry Analyst Report',
          label: user.id.toString(),
        });
      }
    }
  }, [pathname, user]);
  return null;
};

const mapStateToProps = (state: RootState) => ({
  user: selectUserInfo(state),
});

export default withRouter(connect(mapStateToProps)(GoogleAnalytics));

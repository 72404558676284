import { Button, Container, makeStyles } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { format, isValid, sub } from 'date-fns';
import { Field, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AdminService from '../../../api/admin';
import { AuditTrailReport, IconUser, ReportsOutline } from '../../../assets';
import { saveAsFile } from '../../../utils';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  dateInput: {
    backgroundColor: '#fff',
    paddingTop: 4,
    '& input': {
      borderWidth: 0,
    },
    '& .MuiOutlinedInput-input': {
      padding: '6px !important',
    },
    '& .MuiIconButton-root': {
      padding: 0,
    },
    '& legend': {
      display: 'none',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0px, -4px) scale(0.75)',
    },
    '& .Mui-error': {
      fontSize: 10,
    },
  },
  dateTo: {
    fontSize: '1.4rem',
    color: '#262626',
    margin: '6px 8px',
    display: 'inline-block',
  },
  checkbox: {
    '& svg': {
      fill: '#0909B7',
    },
  },
}));

const ManageReports = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation('manage_reports');

  const onClickDownloadUserReport = () => {
    AdminService.getUserReport()
      .then((res) => {
        saveAsFile(new Blob([res.data]), 'user-report.xlsx');
      })
      .catch((e) => {
        alert('Fail to download file');
      });
  };

  return (
    <Container disableGutters maxWidth={false}>
      <div className={classes.row}>
        <ReportsOutline className="section-icon" />
        <span className="section-title">{t('reports')}</span>
      </div>
      <div style={{ height: 10 }} />
      <div className={classes.row}>
        <AuditTrailReport className="section-icon" />
        <span className="section-title">{t('audit_trail_report')}</span>
      </div>
      <Formik
        initialValues={{
          enabled: false,
          startDate: new Date('2021-01-01'),
          endDate: sub(new Date(), { days: 1 }),
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          AdminService.getAuditReport(
            format(values.startDate, 'yyyy-MM-dd 00:00:00'),
            format(values.endDate, 'yyyy-MM-dd 23:59:59'),
          )
            .then((res) => {
              saveAsFile(new Blob([res.data]), 'audit-report.xlsx');
            })
            .catch((e) => {
              alert('Fail to download file');
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
        validateOnBlur
      >
        {({ submitForm, isSubmitting, setFieldValue, values }) => (
          <div className={classes.row} style={{ alignItems: 'flex-start' }}>
            <Field
              component={CheckboxWithLabel}
              name="enabled"
              Label={{ label: '' }}
              type="checkbox"
              style={{
                marginTop: -2,
                marginRight: -8,
              }}
              classes={{ root: classes.checkbox }}
            />
            <div className={classes.dateTo}>{t('common:from')}</div>
            <KeyboardDatePicker
              autoOk
              disableFuture
              variant="inline"
              format="dd/MM/yyyy"
              disabled={!values.enabled}
              value={values.startDate}
              minDate={new Date('2017-01-01')}
              minDateMessage={t('common:start_must_after_minimal_date')}
              InputAdornmentProps={{ position: 'end' }}
              inputVariant="outlined"
              invalidDateMessage={t('common:please_enter_valid_date')}
              onChange={(date, value) => {
                if (!date || !value || value.includes('_')) return;
                if (!isValid(date)) {
                  alert(t('common:please_enter_valid_date'));
                  return;
                }
                setFieldValue('startDate', date);
              }}
              // @ts-ignore
              classes={{ root: classes.dateInput }}
            />
            <div className={classes.dateTo}>{t('common:to')}</div>
            <KeyboardDatePicker
              autoOk
              disableFuture
              disabled={!values.enabled}
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              value={values.endDate}
              minDate={values.startDate}
              maxDate={sub(new Date(), { days: 1 })}
              invalidDateMessage={t('common:please_enter_valid_date')}
              maxDateMessage={t('common:enter_any_date_preceding_current_date')}
              InputAdornmentProps={{ position: 'end' }}
              onChange={(date, value) => {
                if (!date || !value || value.includes('_')) return;
                if (!isValid(date)) {
                  alert(t('common:please_enter_valid_date'));
                  return;
                }
                setFieldValue('endDate', date);
              }}
              // @ts-ignore
              classes={{ root: classes.dateInput }}
            />
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="button-create"
              onClick={submitForm}
              endIcon={<DownloadIcon />}
              style={{ marginLeft: 8, marginTop: 2 }}
            >
              {t('common:download')}
            </Button>
          </div>
        )}
      </Formik>
      <div style={{ height: 10 }} />
      <div className={classes.row}>
        <IconUser className="section-icon" />
        <span className="section-title">{t('User Report')}</span>
      </div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        className="button-create"
        onClick={onClickDownloadUserReport}
        endIcon={<DownloadIcon />}
      >
        {t('common:download')}
      </Button>
    </Container>
  );
};

export default ManageReports;

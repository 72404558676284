/* eslint-disable @typescript-eslint/indent */
/* eslint-disable no-nested-ternary */
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  makeStyles,
  Theme,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DialogTitle, DialogContent } from '../../../../components/Dialog';
import { selectLang } from '../../../settings/duck/selector';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    '& .MuiTableCell-root': {
      borderBottom: 'unset',
      padding: '8px 0',
    },
    marginBottom: 8,
  },
  sectionTitle: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: '#262626',
  },
  label: {
    fontSize: '1.2rem',
    color: '#262626',
    flex: 2,
  },
  value: {
    flex: 3,
    fontSize: '1.2rem',
    color: '#262626',
  },
}));

interface Props {
  holder?: any;

  onClose: () => void;
}

const HolderInformation: React.FC<Props> = ({ holder, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation('register_of_members');
  const [open, setOpen] = React.useState(false);
  const lang = useSelector(selectLang);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  React.useEffect(() => {
    if (holder) handleClickOpen();
  }, [holder]);

  const tableData: React.ReactNode[] = [];
  if (holder) {
    const sections = [
      {
        section: 'holder_details',
        data: [
          {
            field: 'account_no',
            value: holder.folioNo,
          },
          {
            field: 'holder_name',
            value: holder.hdrName,
          },
          {
            field: 'address',
            value: holder.address,
          },
          {
            field: 'balance',
            value: holder.balance.toLocaleString(),
          },
        ],
      },
      {
        section: 'preferences',
        data: [
          {
            field: 'means_of_communication',
            value: holder.annualRpt[lang],
          },
          {
            field: 'dividend_election_instruction',
            value: holder.divPlan[lang],
          },
          {
            field: 'autopay_instruction',
            value:
              holder.autopayInd === 'Y'
                ? t('automatic_payment')
                : holder.autopayInd === 'N'
                ? t('cheque')
                : '-',
          },
          {
            field: 'currency_election',
            value: holder.mcuCode[lang],
          },
        ],
      },
    ];

    sections.forEach(({ section, data }) => {
      tableData.push(
        <TableHead key={`section-${section}`}>
          <TableRow>
            <TableCell className={classes.sectionTitle}>{t(section)}</TableCell>
          </TableRow>
        </TableHead>,
      );

      tableData.push(
        <TableBody key={`table-body-${section}`}>
          {data.map(({ field, value }) => (
            <TableRow key={`row-${field}`}>
              <TableCell
                compone-nt="th"
                scope="row"
                className={classes.label}
                colSpan={2}
              >
                {`${t(field)}:`}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={classes.value}
                colSpan={3}
              >
                {value}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>,
      );
    });
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="user-information-dialog-title"
      open={open}
    >
      <DialogTitle id="user-information-dialog-title" onClose={handleClose}>
        {t('holder_information')}
      </DialogTitle>
      <DialogContent>
        <Table className={classes.table} aria-label="simple table">
          {tableData}
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default HolderInformation;

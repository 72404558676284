import { CalendarEvent } from '../models/CalendarEvent';
import { PaginationResponse } from '../models/PaginationResponse';
import axios from './index';

const getEvents = (
  romOid: string,
  pageSize?: number,
  page?: number,
  params?: {},
) =>
  axios
    .get<PaginationResponse<CalendarEvent>>(`calendar/rom/${romOid}`, {
      params: {
        pageSize,
        page,
        sortBy: 'startTime',
        sortOrder: 'asc',
        ...params,
      },
  })
    .then((res) => ({
      ...res.data,
      data: res.data.data,
    }));

const createEvent = (
  romOid: string,
  event: Omit<
  CalendarEvent,
  'id' | 'colorCode' | 'isPublicHoliday' | 'title'
  > & { title: string },
) => {
  return axios.post('calendar', {
    romOid,
    ...event,
  });
};

const updateEvent = (
  eventId: string | number,
  event: Omit<
    CalendarEvent,
    'id' | 'colorCode' | 'isPublicHoliday' | 'title'
  > & { title: string },
) => axios.put(`calendar/${eventId}`, { ...event });

const deleteEvent = (eventId: string | number) =>
  axios.delete(`calendar/${eventId}`);

export default {
  createEvent,
  updateEvent,
  getEvents,
  deleteEvent,
};

/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Button,
  Dialog,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AdminService from '../../../api/admin';
import { AccessTime, ApplicationOutline } from '../../../assets';
import { DialogContent, DialogTitle } from '../../../components/Dialog';
import TabPanel, { a11yProps } from '../../../components/TabPanel';
import { selectIsMaker, selectUserInfo } from '../../auth/duck/selector';
import { selectLang } from '../../settings/duck/selector';
import RejectCompanyDialog from './RejectCompanyDialog';
import RejectDialog from './RejectDialog';
import SendReminderDialog from './SendReminderDialog';
import TabApproved from './TabApproved';
import TabCompanyApproved from './TabCompanyApproved';
import TabCompanyRejected from './TabCompanyRejected';
import TabPendingApproval from './TabPendingApproval';
import TabPendingCompanyApproval from './TabPendingCompanyApproval';
import TabRejected from './TabRejected';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 20,
    },
    backButton: {
      fontSize: '1.2rem',
      color: '#707070',
      textTransform: 'none',
      '& svg': {
        fontSize: '1.25rem !important',
      },
      '&:hover': {
        backgroundColor: 'transparent',
        opacity: 1,
      },
    },
    name: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      color: '#262626',
      paddingBottom: '0px !important',
    },
    tab: {
      textTransform: 'capitalize',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      backgroundColor: '#f3f3f3',
      border: '1px solid #e7e7e7',
      color: '#ababab',
      marginRight: 4,
      borderTop: '4px solid #e7e7e7',
    },
    tabSelected: {
      borderBottomWidth: 0,
      backgroundColor: '#fff',
      color: '#000',
      borderTop: '4px solid #0909B7',
    },
    tabBorder: {
      width: '100%',
      height: 1,
      position: 'absolute',
      background: '#e7e7e7',
      bottom: 0,
    },
    wrapper: {
      flexDirection: 'row',
      '& g > path': {
        fill: '#0909b7 !important',
      },
      '& svg': {
        marginBottom: '0px !important',
        marginRight: 6,
        height: '1em',
        width: '1em',
        '& path': {
          fill: '#0909b7',
          opacity: 1,
        },
      },
    },
    indicator: {
      display: 'none',
    },
    footer: {
      display: 'flex',
      flexDirection: 'row',
      padding: 12,
      backgroundColor: '#fff',
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      alignItems: 'center',
    },
    itemSelected: {
      flex: 1,
      fontSize: 14,
      fontWeight: 'bold',
      color: '#262626',
    },
    buttonAccept: {
      backgroundColor: '#0909B7',
      color: '#fff',
      fontWeight: 600,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#001A5B',
      },
    },
    errorMessage: {
      fontSize: '1.4rem',
      lineHeight: 1.43,
      textAlign: 'center',
      color: '#707070',
      whiteSpace: 'pre-line',
      margin: '24px 0',
    },
  }),
);

const TABS = [
  { label: 'pending_for_approval', icon: AccessTime },
  { label: 'approved', icon: CheckCircleIcon },
  { label: 'rejected', icon: CancelIcon },
  { label: 'pending_for_approval_company', icon: AccessTime },
  { label: 'approved_company', icon: CheckCircleIcon },
  { label: 'rejected_company', icon: CancelIcon },
];

const Applictaions: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('manage_applications');
  const [value, setValue] = React.useState(0);
  const [selectedApprovals, setSelectedApprovals] = React.useState<any[]>([]);
  const [
    selectedCompanyApprovals,
    setSelectedCompanyApprovals,
  ] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const userInfo = useSelector(selectUserInfo);
  const isMaker = useSelector(selectIsMaker);

  const lang = useSelector(selectLang);
  const [error, setError] = React.useState('');

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const onPressApprove = React.useCallback(() => {
    setLoading(true);
    AdminService.approveApplications(
      selectedApprovals.map((item) => item.original.id),
    )
      .then(() => {
        setCount((state) => state + 1);
      })
      .catch((e) => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedApprovals]);

  const onPressApproveCompany = React.useCallback(() => {
    setLoading(true);
    AdminService.approveCompanyApplications(
      selectedCompanyApprovals.map((item) => item.original.id),
    )
      .then(() => {
        setCount((state) => state + 1);
      })
      .catch((e) => {
        setLoading(false);
        let errorMsgKey = 'message';
        if (lang === 'zh') errorMsgKey = 'messageZH';
        else if (lang === 'cn') errorMsgKey = 'messageCN';
        setError(e.response.data[errorMsgKey]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedCompanyApprovals]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.row}>
            <ApplicationOutline className="section-icon" />
            <span className="section-title">{t('applications')}</span>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="standard"
            scrollButtons="on"
            aria-label="scrollable auto tabs example"
            classes={{ indicator: classes.indicator }}
          >
            <div className={classes.tabBorder} />
            {TABS.filter((item) => {
              if (
                (userInfo?.role === 'ClientMaker' ||
                  userInfo?.role === 'ClientChecker') &&
                item.label.endsWith('_company')
              ) {
                return false;
              }
              return true;
            }).map((item, index) => (
              <Tab
                {...a11yProps(index)}
                key={item.label}
                icon={
                  <item.icon htmlColor="#0909b7" className="MuiSvgIcon-root" />
                }
                value={index}
                label={t(item.label)}
                disableFocusRipple
                disableTouchRipple
                disableRipple
                classes={{
                  root: clsx(classes.tab, {
                    [classes.tabSelected]: index === value,
                  }),
                  wrapper: classes.wrapper,
                }}
              />
            ))}
          </Tabs>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0} key={`pending-approvals-tab-${count}`}>
        <TabPendingApproval
          setSelectedApprovals={setSelectedApprovals}
          selecting={selectedApprovals.length > 0}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TabApproved />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TabRejected />
      </TabPanel>
      <TabPanel
        value={value}
        index={3}
        key={`pending-company-approvals-tab-${count}`}
      >
        <TabPendingCompanyApproval
          setSelectedApprovals={setSelectedCompanyApprovals}
          selecting={selectedCompanyApprovals.length > 0}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TabCompanyApproved />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <TabCompanyRejected />
      </TabPanel>
      {selectedApprovals.length > 0 && (
        <div className={classes.footer}>
          <Typography className={classes.itemSelected}>
            {`${selectedApprovals.length} items selected`}
          </Typography>

          {isMaker ? (
            <SendReminderDialog
              applicationIds={selectedApprovals.map((item) => item.original.id)}
              onSent={() => {
                setCount((state) => state + 1);
              }}
              onSendError={(e) => {
                let errorMsgKey = 'message';
                if (lang === 'zh') errorMsgKey = 'messageZH';
                else if (lang === 'cn') errorMsgKey = 'messageCN';
                setError(e.response.data[errorMsgKey]);
              }}
            />
          ) : (
            <>
              <Button
                disabled={loading}
                variant="contained"
                classes={{
                  contained: classes.buttonAccept,
                }}
                onClick={onPressApprove}
              >
                {t('manage_applications:button_approve')}
              </Button>
              <RejectDialog
                showNote
                applicationIds={selectedApprovals.map(
                  (item) => item.original.id,
                )}
                onRejected={() => {
                  setCount((state) => state + 1);
                }}
                onRejectError={(e) => {
                  let errorMsgKey = 'message';
                  if (lang === 'zh') errorMsgKey = 'messageZH';
                  else if (lang === 'cn') errorMsgKey = 'messageCN';
                  setError(e.response.data[errorMsgKey]);
                }}
              />
            </>
          )}
        </div>
      )}
      {selectedCompanyApprovals.length > 0 && (
        <div className={classes.footer}>
          <Typography className={classes.itemSelected}>
            {`${selectedCompanyApprovals.length} items selected`}
          </Typography>
          {isMaker ? (
            <SendReminderDialog
              isCompanyApplication
              applicationIds={selectedCompanyApprovals.map(
                (item) => item.original.id,
              )}
              onSent={() => {
                setCount((state) => state + 1);
              }}
              onSendError={(e) => {
                let errorMsgKey = 'message';
                if (lang === 'zh') errorMsgKey = 'messageZH';
                else if (lang === 'cn') errorMsgKey = 'messageCN';
                setError(e.response.data[errorMsgKey]);
              }}
            />
          ) : (
            <>
              <Button
                disabled={loading}
                variant="contained"
                classes={{
                  contained: classes.buttonAccept,
                }}
                onClick={onPressApproveCompany}
              >
                {t('manage_applications:button_approve')}
              </Button>
              <RejectCompanyDialog
                showNote
                applicationIds={selectedCompanyApprovals.map(
                  (item) => item.original.id,
                )}
                onRejected={() => {
                  setCount((state) => state + 1);
                }}
                onRejectError={(e) => {
                  let errorMsgKey = 'message';
                  if (lang === 'zh') errorMsgKey = 'messageZH';
                  else if (lang === 'cn') errorMsgKey = 'messageCN';
                  setError(e.response.data[errorMsgKey]);
                }}
              />
            </>
          )}
        </div>
      )}
      <Dialog
        onClose={() => setError('')}
        aria-labelledby="error-dialog-title"
        open={Boolean(error)}
      >
        <DialogTitle id="error-dialog-title" onClose={() => setError('')}>
          Error
        </DialogTitle>
        <DialogContent>
          <div className={classes.errorMessage}>{error}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Applictaions;

import React from 'react';
import { useSelector } from 'react-redux';
import { selectStaffInfo } from '../../../auth/duck/selector';
import AccessRightForm from '../AccessRightForm';

type Props = {};

const TabUserInfo = (props: Props) => {
  const staffInfo = useSelector(selectStaffInfo);

  return (
    <AccessRightForm
      isUser
      hideAccessRight={staffInfo?.role !== 'TricorSuperAdmin'}
      // setFieldValue={setFieldValue}
      showRequired
    />
  );
};

export default TabUserInfo;

/* eslint-disable @typescript-eslint/indent */
import axios from 'axios';
import { BlockBlobClient } from '@azure/storage-blob';
import appService from './index';

const fileService = axios.create();

const readFile = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Int8Array(e.target?.result as any);
      resolve(data);
    };
    reader.onerror = (e) => {
      reject(Error('Read file fail.'));
    };
    reader.readAsArrayBuffer(file);
  });

const uploadFile = (uri: string, file: File, resId: string) =>
  readFile(file)
    .then((data) =>
      fileService.put(uri, data, {
        headers: {
          'x-ms-write': 'update',
          'x-ms-range': `bytes=0-${file.size - 1}`,
          'x-ms-version': '2019-12-12',
        },
      }),
    )
    .then(() => resId);

const uploadDocument = (
  companyId: string,
  type:
    | 'SalesAndPurchaseAgreement'
    | 'LatestStatement'
    | 'LatestManagementAccount'
    | 'IDCardOrPassport'
    | 'AddressProof'
    | 'ConsiderationToBeReceivedPaid',
  file: File,
) => {
  return appService
    .post(`request/company/${companyId}/document/token`, {
      type,
      blobName: file.name,
      size: file.size,
    })
    .then((res) => ({ uri: res.data.token.uriWithSAS, resId: res.data.id }))
    .then(({ uri, resId }) => uploadFile(uri, file, resId));
};

const uploadArticleImage = (file: File) => {
  let blobUrl = '';
  return appService
    .post('leadership/image/token', {
      fileName: file.name,
      size: file.size,
    })
    .then(({ data }) => {
      const blockBlobClient = new BlockBlobClient(data.token.uriWithSAS);
      blobUrl = data.token.blobUrl;
      return blockBlobClient.uploadData(file);
    })
    .then((uploadResult) => {
      if (uploadResult.errorCode) {
        throw Error(uploadResult.errorCode);
      }
      return blobUrl;
    });
};
export default { uploadDocument, uploadFile, uploadArticleImage };

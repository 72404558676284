/* eslint-disable react/jsx-wrap-multilines */
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LoginContainer from './LoginContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      color: '#000',
    },
    description: {
      fontSize: '1.2rem',
      lineHeight: 1.17,
      color: '#575757',
      margin: '10px 0 18px 0',
    },
    button: {
      width: '100%',
      marginTop: 25,
      fontSize: '1.8rem',
      fontWeight: 600,
      color: theme.palette.common.white,
      textTransform: 'capitalize',
      borderRadius: 7,
      backgroundColor: '#0909B7',
      '&:hover': {
        backgroundColor: '#001A5B',
      },
    },
  }),
);

const RecoverSuccess: React.FC = () => {
  const { t } = useTranslation('login');
  const classes = useStyles();
  const history = useHistory();

  return (
    <LoginContainer>
      <>
        <div className={classes.label}>{t('login:set_password_success')}</div>
        <div className={classes.description}>
          {t('login:set_password_success_message')}
        </div>
        <Button
          variant="contained"
          disableElevation
          disableFocusRipple
          disableRipple
          className={classes.button}
          onClick={() => history.replace('/')}
        >
          {t('sign_in')}
        </Button>
      </>
    </LoginContainer>
  );
};

export default RecoverSuccess;

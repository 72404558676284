/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/indent */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from '../../../locales';

export type SliceState = {
  lang: 'en' | 'zh' | 'cn';
};

const initialState: SliceState = {
  lang: 'en',
};

const SettingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    changeLang: (state, action: PayloadAction<SliceState['lang']>) => {
      state.lang = action.payload;
      i18n.changeLanguage(action.payload);
    },
  },
});

const { reducer, actions } = SettingsSlice;

export const { changeLang } = actions;

export default reducer;

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AuthService from '../api/auth';
import { CompanyUser } from '../models/CompanyUser';
import { hideFeedback as ActionHideFeedback } from '../pages/auth/duck/authDuck';
import { selectUserInfo } from '../pages/auth/duck/selector';
import { AppDispatch, RootState } from '../store';
import FeedbackModal from './FeedbackModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    feedbackDialogContainer: {
      position: 'absolute',
      top: 0,
      margin: 0,
    },
    customCheckbox: {
      '&.MuiCheckbox-colorSecondary.Mui-checked .MuiIconButton-label': {
        color: '#0909B7',
      },
    },
    buttonRed: {
      backgroundColor: '#0909B7',
      fontSize: '1.2rem',
      color: '#fff',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#001A5B',
      },
    },
    buttonGrey: {
      backgroundColor: '#fff',
      fontSize: '1.2rem',
      color: '#0909B7',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#D0D4DE',
      },
    },
  }),
);

const AskFeedbackModal = ({
  user,
  hideFeedback,
}: {
  user: CompanyUser | null;
  hideFeedback: () => void;
}) => {
  const classes = useStyles();
  const { t } = useTranslation('feedback');
  const [askFeedbackModalOpen, setAskFeedbackModalOpen] = React.useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = React.useState(false);

  const [stopFeedback, setStopFeedback] = React.useState(false);
  React.useEffect(() => {
    if (!user?.hideFeedback) {
      setTimeout(() => {
        setAskFeedbackModalOpen(true);
      }, 900000);
    }
  }, [user?.hideFeedback]);

  React.useEffect(() => {
    if (!askFeedbackModalOpen && stopFeedback) {
      AuthService.turnoffFeedback().then(() => {
        hideFeedback();
      });
    }
  }, [askFeedbackModalOpen]);

  const onClose = () => {
    setAskFeedbackModalOpen(false);
  };

  return (
    <>
      <Dialog
        classes={{ paper: classes.feedbackDialogContainer }}
        onClose={onClose}
        aria-labelledby="feeback-dialog-title"
        open={askFeedbackModalOpen}
      >
        <DialogTitle id="feeback-dialog-title">
          {t('feedback:ask_feedback_title')}
        </DialogTitle>
        <DialogContent>
          <div>{t('ask_feedback_description')}</div>
          <div>
            <Checkbox
              checked={stopFeedback}
              disableFocusRipple
              disableTouchRipple
              disableRipple
              onChange={(event) => {
                setStopFeedback(event.target.checked);
              }}
              className={classes.customCheckbox}
            />
            {t('do_not_show_again')}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.buttonRed}
            onClick={() => {
              onClose();
              setFeedbackModalOpen(true);
            }}
            variant="contained"
          >
            {t('leave_feedback')}
          </Button>
          <Button
            className={classes.buttonGrey}
            onClick={onClose}
            variant="contained"
          >
            {t('maybe_later')}
          </Button>
        </DialogActions>
      </Dialog>
      {user && (
        <FeedbackModal
          user={user}
          open={feedbackModalOpen}
          onClose={() => {
            setFeedbackModalOpen(false);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUserInfo(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  hideFeedback: () => dispatch(ActionHideFeedback()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AskFeedbackModal);

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { FieldProps, getIn } from 'formik';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import Input, { InputProps } from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

export interface SimpleFileUploadProps extends FieldProps {
  label: string;
  disabled?: boolean;
  accept?: string;
  InputProps?: Omit<InputProps, 'name' | 'type' | 'label'>;
  InputLabelProps?: InputLabelProps;
  FormControlProps?: FormControlProps;
}

export const SimpleFileUpload = ({
  field,
  form: { isSubmitting, values, errors, setFieldValue },
  label,
  disabled = false,
  accept,
  InputProps: inputProps,
  InputLabelProps: inputLabelProps,
  FormControlProps: formControlProps,
}: SimpleFileUploadProps) => {
  const error = getIn(errors, field.name);
  const value = getIn(values, field.name);
  return (
    <FormControl {...formControlProps}>
      {label && (
        <InputLabel shrink error={!!error} {...inputLabelProps}>
          {label}
        </InputLabel>
      )}
      {(inputProps as any)?.currentImage && !value && (
        <img
          src={(inputProps as any)?.currentImage}
          style={{ width: 240, margin: '8px 0' }}
        />
      )}
      <Input
        error={!!error}
        inputProps={{
          type: 'file',
          disabled: disabled || isSubmitting,
          accept,
          name: field.name,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange: (event: React.ChangeEvent<any>) => {
            if (inputProps?.onChange) {
              inputProps.onChange(event);
            } else {
              const file = event.currentTarget.files[0];
              setFieldValue(field.name, file);
            }
          },
        }}
        {...inputProps}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SimpleFileUpload;

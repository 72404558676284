/* eslint-disable import/no-cycle */
import { Dialog, makeStyles } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/GroupRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import AdminService from '../../../api/admin';
import { DialogContent, DialogTitle } from '../../../components/Dialog';
import PaginationTable from '../../../components/PaginationTable';
import { CompanyUser } from '../../../models/CompanyUser';
import { selectIsChecker, selectStaffInfo } from '../../auth/duck/selector';
import { selectLang } from '../../settings/duck/selector';
import { UserReducerContext } from './duck';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 16,
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
  buttonOff: {
    width: 64,
    marginBottom: 4,
    textTransform: 'capitalize',
    '&.Mui-disabled': {
      color: '#000',
    },
  },
  buttonOn: {
    width: 64,
    color: '#fff',
    backgroundColor: '#0909B7',
    '&:hover': {
      backgroundColor: '#001A5B',
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
    marginBottom: 4,
    textTransform: 'capitalize',
    '&.Mui-disabled': {
      color: '#000',
    },
  },
  errorMessage: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
}));

const TabClientChecker: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    state: { user },
    dispatch,
  } = React.useContext(UserReducerContext);
  const [data, setData] = React.useState<CompanyUser[]>([]);
  const [assignedCheckers, setAssignedChecker] = React.useState<CompanyUser[]>(
    [],
  );
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const isChecker = useSelector(selectIsChecker);
  const staffInfo = useSelector(selectStaffInfo);

  const lang = useSelector(selectLang);
  const [error, setError] = React.useState('');

  const COLUMNS: Array<Column<CompanyUser>> = React.useMemo(
    () => [
      {
        Header: 'manage_user:name',
        accessor: 'name',
        disableFilters: true,
      },
      {
        Header: 'manage_user:email',
        accessor: 'username',
        disableFilters: true,
      },
      {
        Header: 'manage_user:status',
        accessor: 'status',
        disableFilters: true,
        Cell: ({ value }) =>
          value ? t(`manage_user:${value.toLowerCase()}`) : '-',
      },
    ],
    [t],
  );

  // React.useEffect(() => {
  //   if (user?.id) {
  //     AdminService.getUserAssignedChecker(user.id).then((res) => {
  //       setAssignedChecker(res.data);
  //     });
  //   }
  // }, [user?.id]);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      if (!user?.id) return;
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      AdminService.getUserAssignedChecker(
        user?.id,
        pageSize,
        pageIndex,
        query,
        sortBy,
      ).then((res) => {
        if (fetchId === fetchIdRef.current) {
          setData(res.data);
          setPageCount(res.totalPage);
          setLoading(false);
        }
      });
    },
    [user?.romOids, user?.id],
  );

  // const getUserAssignedChecker = React.useCallback(() => {
  //   if (user) {
  //     AdminService.getUserAssignedChecker(user.id).then((res) => {
  //       setAssignedChecker(res.data);
  //     });
  //   }
  // // }, [user?.id]);

  // const renderActions = (item: CompanyUser) => {
  //   const assigned =
  //     assignedCheckers.findIndex((checker) => checker.id === item.id) > -1;

  //   return (
  //     <div className="table-row-actions">
  //       <Button
  //         disabled={isChecker && Boolean(staffInfo)}
  //         aria-label="assign"
  //         variant={isChecker && Boolean(staffInfo) ? 'text' : 'contained'}
  //         className={
  //           assigned && !(isChecker && Boolean(staffInfo))
  //             ? classes.buttonOn
  //             : classes.buttonOff
  //         }
  //         onClick={() => {
  //           if (!user) return;
  //           setLoading(true);
  //           if (assigned) {
  //             AdminService.unassignClientChecker(user.id, item.id)
  //               .then(() => getUserAssignedChecker())
  //               .catch((e) => {
  //                 let errorMsgKey = 'message';
  //                 if (lang === 'zh') errorMsgKey = 'messageZH';
  //                 else if (lang === 'cn') errorMsgKey = 'messageCN';
  //                 setError(e.response.data[errorMsgKey]);
  //               })
  //               .finally(() => {
  //                 setLoading(false);
  //               });
  //           } else {
  //             AdminService.assignClientChecker(user.id, item.id)
  //               .then(() => getUserAssignedChecker())
  //               .catch((e) => {
  //                 let errorMsgKey = 'message';
  //                 if (lang === 'zh') errorMsgKey = 'messageZH';
  //                 else if (lang === 'cn') errorMsgKey = 'messageCN';
  //                 setError(e.response.data[errorMsgKey]);
  //               })
  //               .finally(() => {
  //                 setLoading(false);
  //               });
  //           }
  //         }}
  //       >
  //         {assigned ? 'Unassign' : 'Assign'}
  //       </Button>
  //     </div>
  //   );
  // };

  return (
    <div className={classes.container}>
      <PaginationTable
        key={assignedCheckers.length}
        defaultPageSize={15}
        columns={COLUMNS}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by name"
        placeholder={t('manage_user:rom_placeholder')}
        // renderActions={renderActions}
        renderHeaderLeft={
          <>
            <GroupIcon className="section-icon" />
            <span className="section-title">
              {t('manage_user:assigned_client_checker')}
            </span>
          </>
        }
      />
      <Dialog
        onClose={() => setError('')}
        aria-labelledby="error-dialog-title"
        open={Boolean(error)}
      >
        <DialogTitle id="error-dialog-title" onClose={() => setError('')}>
          Error
        </DialogTitle>
        <DialogContent>
          <div className={classes.errorMessage}>{error}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TabClientChecker;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';
import { connect } from 'react-redux';
import PaginationTable from '../../../components/PaginationTable';
import SelectColumnFilter, {
  ColumnProps,
} from '../../../components/SelectCloumnFilter';
import Entity from '../../../models/Entity';
import EntityService from '../../../api/entity';
import { CompanyUser } from '../../../models/CompanyUser';
import { AppDispatch } from '../../../store';
import { setEntities as ActionSetEntities } from './duck/entityDuck';
import { EntityResponse } from '../../../models/EntityResponse';
import MultiLang from '../../../models/MultiLang';

interface Props {
  user: CompanyUser | null;
  currentEntityId?: string | null;
  onClickEntity: (entity: Entity) => void;
  setEntities: (entities: EntityResponse[]) => void;
}

const EntityList: React.FC<Props> = ({
  user,
  currentEntityId,
  onClickEntity,
  setEntities,
}) => {
  const { t } = useTranslation('entity_management');
  const fetchIdRef = React.useRef(0);
  const [data, setData] = React.useState<Entity[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [options, setOptions] = React.useState<{
    [field: string]: MultiLang[];
  }>({});

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy, filters }) => {
      if (!user) return;
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      const filterObj = filters?.reduce(
        (acc: Map<string, any>, item: any) => ({
          ...acc,
          [item.id]: item.value,
        }),
        {},
      );

      EntityService.getUserEntities(
        user.id,
        pageSize,
        pageIndex,
        query,
        sortBy?.id === 'incorpDateString'
          ? { ...sortBy, id: 'incorporateDate' }
          : sortBy,
        filterObj,
      )
        .then((res) => {
          if (fetchId === fetchIdRef.current) {
            setEntities(res.data.data);
            setData(res.data.data.map((item) => new Entity(item)));
            setPageCount(res.data.totalPage);
            setLoading(false);
          }
        })
        .catch(() => {
          setData([]);
          setPageCount(0);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [user],
  );

  React.useEffect(() => {
    EntityService.getEntitiesFilterOptions().then((res) => {
      setOptions(res);
    });
  }, []);

  const columns: Array<Column<Entity>> = React.useMemo(() => {
    return [
      // {
      //   Header: 'entity_management:file_code',
      //   accessor: 'fileCode',
      //   disableFilters: true,
      //   width: 60,
      // },
      {
        Header: 'entity_management:company_name',
        accessor: 'name',
        disableFilters: true,
      },
      {
        Header: 'entity_management:jurisdiction',
        accessor: 'jurisdiction',
        Cell: ({ value }: any) =>
          t(`country:${value.toLowerCase().replaceAll(' ', '_')}`),
        disableFilters:
          !options.jurisdiction || options.jurisdiction.length === 0,
        Filter: (props: ColumnProps) => (
          <SelectColumnFilter
            {...props}
            customOptions={options.jurisdiction || []}
          />
        ),
        width: 60,
      },
      {
        Header: 'entity_management:date_of_incorporation',
        accessor: 'incorpDateString',
        disableFilters: true,
        width: 80,
      },
      {
        Header: 'entity_management:status',
        accessor: 'status',
        filter: 'includes',
        disableFilters: !options.status,
        Cell: ({ value }: any) => t(value.toLowerCase()),
        Filter: (props: ColumnProps) => (
          <SelectColumnFilter {...props} customOptions={options.status || []} />
        ),
        width: 60,
      },
    ];
  }, [options]);

  return (
    <div className="entity-list">
      <PaginationTable
        defaultPageSize={15}
        columns={columns}
        data={data}
        loading={loading}
        pageCount={pageCount}
        currentId={currentEntityId}
        onClickRow={onClickEntity}
        placeholder={t('entity_list_placeholder')}
        fetchData={fetchData}
        searchPlaceholder={t('search_company_placeholder')}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setEntities: (entities: EntityResponse[]) =>
    dispatch(ActionSetEntities({ entities })),
});
export default connect(null, mapDispatchToProps)(EntityList);

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import EntityApi from '../../../../api/entity';
import FileServiceApi from '../../../../api/fileservice';
import { MyRequestResponse } from '../../../../models/MyRequestResponse';
import { Document } from '../../../../models/Document';
import { logout, login } from '../../../auth/duck/authDuck';
import { EntityResponse } from '../../../../models/EntityResponse';
import Entity from '../../../../models/Entity';
import {
  CreateCODAction,
  CreateDPCAction,
  CreateRSRAction,
  CreateTSAction,
} from '../../../../models/RequestForm';

export const getEntities = createAsyncThunk<any, { entity: Entity }>(
  'enitity/GET_ENTITIES',
  async (payload) => {
    const response = await EntityApi.getDownlineEntities(
      payload.entity.fileCode,
    );
    return response.data;
  },
);

export const getEntityRequests = createAsyncThunk<any, { entity: Entity }>(
  'enitity/GET_ENTITY_REQUESTS',
  async (payload) => {
    const response = await EntityApi.getEntityRequests(payload.entity.id);
    return response.data.map((item: any) => ({
      ...item,
      status: item?.latestLog?.postWorkFlow || '',
    }));
  },
);

export const getEntityDetail = createAsyncThunk<any, { entity: Entity }>(
  'enitity/GET_ENTITY_DETAIL',
  async ({ entity }, thunkAPI) => {
    thunkAPI.dispatch(getEntityRequests({ entity }));
    const [summary, directors, shareholders, secretary] = await Promise.all([
      EntityApi.getEntitySummary(entity.id),
      EntityApi.getEntityDirectors(entity.id),
      EntityApi.getEntityShareholders(entity.id),
      EntityApi.getEntitySecretary(entity.id),
    ]);

    return {
      summary: summary.data?.[0],
      directors: directors.data,
      shareholders: shareholders.data,
      secretaries: secretary.data,
    };
  },
);

export const createRequestCOD = createAsyncThunk<any, CreateCODAction>(
  'enitity/CREATE_ENTITY_REQUEST_COD',
  async ({ entity, values, onSuccess }, thunkAPI) => {
    const response = await EntityApi.createRequestCod(entity.id, values);
    thunkAPI.dispatch(getEntityRequests({ entity }));
    onSuccess();
    return response.data;
  },
);

export const createRequestDPC = createAsyncThunk<any, CreateDPCAction>(
  'enitity/CREATE_ENTITY_REQUEST_DPC',
  async ({ entity, values, onSuccess }, thunkAPI) => {
    const promises = [];
    if (values.idCopy) {
      promises.push(
        FileServiceApi.uploadDocument(
          entity.id,
          'IDCardOrPassport',
          values.idCopy,
        ),
      );
    }

    if (values.addressProof) {
      promises.push(
        FileServiceApi.uploadDocument(
          entity.id,
          'AddressProof',
          values.addressProof,
        ),
      );
    }
    const documentIds = await Promise.all(promises);
    if (documentIds.length > 0) values.documentIds = documentIds;
    const response = await EntityApi.createRequestDPC(entity.id, values);
    thunkAPI.dispatch(getEntityRequests({ entity }));
    onSuccess();
    return response.data;
  },
);

export const createRequestRSR = createAsyncThunk<any, CreateRSRAction>(
  'enitity/CREATE_ENTITY_REQUEST_RSR',
  async ({ entity, values, onSuccess }, thunkAPI) => {
    const response = await EntityApi.createRequestRSR(entity.id, values);
    thunkAPI.dispatch(getEntityRequests({ entity }));
    onSuccess();
    return response.data;
  },
);

export const createRequestTS = createAsyncThunk<any, CreateTSAction>(
  'enitity/CREATE_ENTITY_REQUEST_TS',
  async ({ entity, values, onSuccess }, thunkAPI) => {
    const promises = [];
    if (values.salesAndPurchaseAgreement) {
      promises.push(
        FileServiceApi.uploadDocument(
          entity.id,
          'SalesAndPurchaseAgreement',
          values.salesAndPurchaseAgreement,
        ),
      );
    }
    if (values.latestStatement) {
      promises.push(
        FileServiceApi.uploadDocument(
          entity.id,
          'LatestStatement',
          values.latestStatement,
        ),
      );
    }
    if (values.latestManagementAccount) {
      promises.push(
        FileServiceApi.uploadDocument(
          entity.id,
          'LatestManagementAccount',
          values.latestManagementAccount,
        ),
      );
    }
    if (values.considerationToBeReceivedPaid) {
      promises.push(
        FileServiceApi.uploadDocument(
          entity.id,
          'ConsiderationToBeReceivedPaid',
          values.considerationToBeReceivedPaid,
        ),
      );
    }
    const documentIds = await Promise.all(promises);
    if (documentIds.length > 0) values.documentIds = documentIds;

    const response = await EntityApi.createRequestTS(entity.id, values);
    thunkAPI.dispatch(getEntityRequests({ entity }));
    onSuccess();
    return response.data;
  },
);

interface SliceState {
  currentEntityId: string | null;
  entities: {
    [entityId: string]: EntityResponse;
  };
  requests: {
    [entityId: string]: MyRequestResponse[];
  };
  documents: {
    [entityId: string]: Document[];
  };
}

const initialState: SliceState = {
  currentEntityId: null,
  entities: {},
  requests: {},
  documents: {},
};

const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    setEntity(state, action: PayloadAction<{ entityId: string | null }>) {
      state.currentEntityId = action.payload.entityId;
    },
    setEntities(state, action: PayloadAction<{ entities: EntityResponse[] }>) {
      action.payload.entities.forEach((entity) => {
        state.entities[entity.fileCode] = entity;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.pending, (state) => {
      state.currentEntityId = null;
      state.entities = {};
      state.requests = {};
      state.documents = {};
    });
    builder.addCase(getEntities.fulfilled, (state, { payload }) => {
      payload.forEach((entity: EntityResponse) => {
        state.entities[entity.fileCode] = entity;
      });
    });
    builder.addCase(getEntityDetail.fulfilled, (state, { payload }) => {
      const {
        summary,
        directors,
        shareholders,
        documents,
        secretaries,
      } = payload;

      if (summary) {
        const { entCode } = summary;
        state.entities[entCode] = {
          ...state.entities[entCode],
          ...summary,
          directors,
          shareholders,
          secretaries,
        };

        state.documents[entCode] = documents;
      }
    });

    builder.addCase(getEntityRequests.fulfilled, (state, { payload, meta }) => {
      state.requests[meta.arg.entity.id] = payload;
    });

    builder.addCase(createRequestCOD.fulfilled, (state, { meta }) => {
      meta.arg.formActions.setSubmitting(false);
      meta.arg.formActions.resetForm();
    });

    builder.addCase(createRequestDPC.fulfilled, (state, { meta }) => {
      meta.arg.formActions.setSubmitting(false);
      meta.arg.formActions.resetForm();
    });

    builder.addCase(createRequestRSR.fulfilled, (state, { meta }) => {
      meta.arg.formActions.setSubmitting(false);
      meta.arg.formActions.resetForm();
    });

    builder.addCase(createRequestTS.fulfilled, (state, { meta }) => {
      meta.arg.formActions.setSubmitting(false);
      meta.arg.formActions.resetForm();
    });

    builder.addCase(createRequestCOD.rejected, (state, { meta }) => {
      meta.arg.formActions.setSubmitting(false);
      meta.arg.formActions.setErrors({
        directorNameZh: 'Create Request Fail.',
      });
    });
    builder.addCase(createRequestDPC.rejected, (state, { meta }) => {
      meta.arg.formActions.setSubmitting(false);
      meta.arg.formActions.setErrors({
        directorNameZh: 'Create Request Fail.',
      });
    });
    builder.addCase(createRequestRSR.rejected, (state, { meta }) => {
      meta.arg.formActions.setSubmitting(false);
      meta.arg.formActions.setErrors({
        auditFirm: 'Create Request Fail.',
      });
    });
    builder.addCase(createRequestTS.rejected, (state, { meta }) => {
      meta.arg.formActions.setSubmitting(false);
      meta.arg.formActions.setErrors({
        nameOfTransferor: 'Create Request Fail.',
      });
    });
  },
});

const { actions, reducer } = entitySlice;

export const { setEntity, setEntities } = actions;

export default reducer;

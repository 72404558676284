import React from 'react';
import { HorizontalBar as HorizontalBarChart } from 'react-chartjs-2';

require('./RoundedBarCorners');

const HorizontalBar = ({ labels, data, datalabels, height }: any) => {
  const OPTIONS = {
    title: { display: false },
    legend: { display: false },
    scales: {
      yAxes: [
        {
          maxBarThickness: 20,
          gridLines: { display: false },
          ticks: {
            fontColor: '#262626',
            fontStyle: 'bold',
            crossAlign: 'far',
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            callback(value: any) {
              return value.toLocaleString();
            },
          },
        },
      ],
    },
    tooltips: {
      enabled: false,
    },
    cornerRadius: 1,
    plugins: {
      datalabels: {
        display: true,
        color: '#262626',
        fontWeight: 'bold',
        anchor: 'end',
        align: 'end',
        formatter(value: any, context: any) {
          return datalabels?.[context.dataIndex] || '';
        },
      },
    },
  };

  return (
    <HorizontalBarChart
      height={height}
      data={{
        labels,
        datasets: [
          {
            backgroundColor: '#e84637',
            borderColor: '#e84637',
            borderWidth: 1,
            hoverBackgroundColor: '#e84637',
            hoverBorderColor: '#e84637',
            data,
          },
        ],
      }}
      options={OPTIONS}
    />
  );
};

export default HorizontalBar;

/* eslint-disable import/no-cycle */
import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

export const setToken = (token: string) => {
  instance.defaults.headers.common.Authorization = token;
};

export const clearToken = () => {
  delete instance.defaults.headers.common.Authorization;
};

export default instance;

import React from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';

export default ({
  data,
  labels,
  datalabels,
  backgroundColor,
  displayLengend = true,
  type,
}: any) => {
  const OPTIONS = {
    responsive: true,
    maintainAspectRatio: false,
    title: { display: false },
    legend: {
      display: displayLengend,
      position: 'bottom',
      onClick: () => {},
      labels: {
        usePointStyle: true,
      },
    },
    tooltips: {
      callbacks: {
        label(tooltipItem: any, data: any) {
          const { index } = tooltipItem;
          const label = data.labels[index];
          return `${label}: ${datalabels ? datalabels[index] : ''}`;
        },
        footer(tooltipItems: any, data: any) {
          const [{ datasetIndex, index }] = tooltipItems;
          return data.datasets[datasetIndex].data[index].toLocaleString() || '';
        },
      },
    },
    layout: {
      padding: {
        top: 40,
        bottom: 20,
      },
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const ChartComponent = type === 'doughnut' ? Doughnut : Pie;
  return (
    <ChartComponent
      data={{
        datasets: [
          {
            data,
            backgroundColor: backgroundColor || [
              '#1166a7',
              '#e84637',
              '#f5a922',
              '#ca398c',
              '#a5cabd',
            ],
          },
        ],
        labels,
      }}
      options={OPTIONS}
    />
  );
};

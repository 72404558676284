import {
  createStyles,
  Dialog,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded';
import ListIcon from '@material-ui/icons/List';
import Pagination from '@material-ui/lab/Pagination';
import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import cnLocale from 'date-fns/locale/zh-CN';
import zhLocale from 'date-fns/locale/zh-TW';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CalendarService from '../../../../api/calendar';
import { edit } from '../../../../assets';
import { DialogContent, DialogTitle } from '../../../../components/Dialog';
import { CalendarEvent } from '../../../../models/CalendarEvent';
import { selectLang } from '../../../settings/duck/selector';
import EditEvent from './EditEvent';

interface Props {
  romOid?: string;
  refreshEvents: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonShowEvent: {
      marginLeft: 8,
      padding: 4,
      '&:hover': {
        opacity: 0.8,
      },
    },
    dialog: {
      minWidth: '40%',
      [theme.breakpoints.down('sm')]: {
        minWidth: '80%',
      },
    },
    content: {},
    backButton: {
      padding: 4,
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    pagination: {
      margin: '12px 0',
      alignSelf: 'center',
      '& .MuiPaginationItem-root': {
        fontSize: '1rem',
        fontWeight: 'bold',
        borderRadius: 12,
        '&.Mui-selected': {
          backgroundColor: '#0909B7',
          color: '#fff',
        },
      },
    },
    item: {
      borderRadius: 4,
      boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
      backgroundColor: '#ffffff',
      marginBottom: 8,
    },
    itemTextPrimary: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: '#262626',
    },
    itemTextSecondary: {
      fontSize: '1.2rem',
      color: '#b4b4b4',
    },
    itemButton: {
      '&:hover': {
        backgroundColor: 'unset',
      },
    },
    edit: {
      height: 16,
      width: 16,
    },
    title: {
      fontSize: '1.4rem',
      color: '#262626',
      fontWeight: 'bold',
      marginRight: 8,
    },
    label: {
      marginTop: 8,
      fontSize: '1.2rem',
      lineHeight: 1.17,
      fontWeight: 'bold',
      color: '#262626',
      marginRight: 4,
    },
    value: {
      fontSize: '1.2rem',
      lineHeight: 1.17,
      color: '#262626',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '1.4rem',
      '& .MuiSelect-select': {
        paddingTop: 8,
      },
    },
    menuItem: {
      backgroundColor: '#838383 !important',
      fontSize: 14,
      fontWeight: 'bold',
      color: '#fff',
      '&:hover': {
        backgroundColor: '#838383 !important',
      },
    },
    selected: {
      backgroundColor: '#838383',
      fontSize: 14,
      fontWeight: 'bold',
      color: '#0909B7',
    },
  }),
);

const EventsDialog = ({ romOid, refreshEvents }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('home');
  const [open, setOpen] = React.useState(false);
  const [years, setYears] = React.useState<string[]>([]);
  const [year, setYear] = React.useState<string>();
  const [data, setData] = React.useState<CalendarEvent[]>([]);
  const [upComingOnly, setUpComingOnly] = React.useState(true);
  const [event, setEvent] = React.useState<CalendarEvent>();
  const [editingEvent, setEditingEvent] = React.useState<CalendarEvent>();
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [pageIndex, setPageIndex] = React.useState(1);
  const fetchIdRef = React.useRef(0);
  const lang = useSelector(selectLang);

  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    if (open && romOid) {
      setLoading(true);
      CalendarService.getEvents(romOid, undefined, 1, {
        startTime: '2000-01-01',
      }).then((res) => {
        const sortedYears = Object.keys(
          res.data.reduce(
            (acc, item) => ({ ...acc, [item.startTime.split('-')[0]]: true }),
            {},
          ),
        )
          .sort((a, b) => Number(b) - Number(a))
          .filter((item) => Number(item) <= new Date().getFullYear());

        setYears(sortedYears);
        setYear(sortedYears[0]);
        setLoading(false);
      });
    }
  }, [open, romOid]);

  React.useEffect(() => {
    if (open && romOid && year) {
      setLoading(true);
      const fetchId = ++fetchIdRef.current;

      CalendarService.getEvents(romOid, 5, pageIndex, {
        startTime: format(
          upComingOnly ? new Date() : new Date(Number(year), 0, 1),
          'yyyy-MM-dd',
        ),
        endTime: format(
          !upComingOnly && year === years[0]
            ? new Date()
            : new Date(Number(year), 11, 31),
          'yyyy-MM-dd',
        ),
      }).then((res) => {
        if (fetchId === fetchIdRef.current) {
          setData(res.data);
          setPageCount(res.totalPage);
          setLoading(false);
        }
      });
    }
  }, [open, romOid, year, upComingOnly, pageIndex]);

  const onExited = () => {
    setPageCount(0);
    setPageIndex(1);
    setData([]);
    setEvent(undefined);
    setLoading(false);
  };

  const locale = {
    en: enLocale,
    zh: zhLocale,
    cn: cnLocale,
  }[lang];

  return (
    <div>
      <IconButton
        onClick={() => setOpen(true)}
        aria-label="show upcoming event"
        className={classes.buttonShowEvent}
        disabled={!romOid}
      >
        <ListIcon fontSize="large" />
      </IconButton>
      <Dialog
        onClose={handleClose}
        onExited={onExited}
        aria-labelledby="events-dialog-title"
        open={open}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle id="events-dialog-title" onClose={handleClose}>
          {event ? (
            <>
              <IconButton
                aria-label="back"
                disableFocusRipple
                disableRipple
                disableTouchRipple
                className={classes.backButton}
                onClick={() => setEvent(undefined)}
              >
                <ChevronLeftIcon fontSize="large" />
              </IconButton>
              <span>{t('event_detail')}</span>
            </>
          ) : (
            t('events')
          )}
        </DialogTitle>
        <DialogContent className={classes.content}>
          {years.length > 0 && year && !event && (
            <div className={classes.row}>
              <span style={{ marginRight: 8 }}>{`${t('time')}:`}</span>
              <Select
                disableUnderline
                value={upComingOnly ? 'upcoming' : 'past'}
                defaultValue
                onChange={(e: any) => {
                  setPageIndex(1);
                  setUpComingOnly(e.target.value === 'upcoming');
                  if (e.target.value === 'upcoming') {
                    setYear(years[0]);
                  }
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem
                  value="upcoming"
                  classes={{
                    root: classes.menuItem,
                    selected: classes.selected,
                  }}
                >
                  {t('upcoming')}
                </MenuItem>
                <MenuItem
                  value="past"
                  classes={{
                    root: classes.menuItem,
                    selected: classes.selected,
                  }}
                >
                  {t('past')}
                </MenuItem>
              </Select>
              <div style={{ flex: 1 }} />
              {!upComingOnly && (
                <>
                  <span style={{ marginRight: 8 }}>{`${t('year')}:`}</span>
                  <Select
                    disableUnderline
                    value={year}
                    defaultValue={year}
                    onChange={(e: any) => {
                      setPageIndex(1);
                      setYear(e.target.value);
                      if (e.target.value !== years[0]) {
                        setUpComingOnly(false);
                      }
                    }}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {years.map((item) => (
                      <MenuItem
                        value={item}
                        key={item}
                        classes={{
                          root: classes.menuItem,
                          selected: classes.selected,
                        }}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </>
              )}
            </div>
          )}
          {event ? (
            <div style={{ marginBottom: 24 }}>
              <div className="row" style={{ alignItems: 'center' }}>
                <span className={classes.title}>{event.title[lang]}</span>
                {!event.isPublicHoliday && (
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                      setEditingEvent(event);
                    }}
                  >
                    <img src={edit} alt="edit" className={classes.edit} />
                  </IconButton>
                )}
              </div>
              <div style={{ marginTop: 8 }}>
                <span className={classes.label}>{`${t('start_time')}:`}</span>
                <span className={classes.value}>
                  {format(
                    new Date(event.startTime),
                    lang === 'en'
                      ? `dd MMMM yyyy${event.isWholeDay ? '' : ' hh:mm a'}`
                      : `yyyy年MM月dd日${event.isWholeDay ? '' : ' hh:mm a'}`,
                    { locale },
                  )}
                </span>
              </div>
              <div style={{ marginTop: 8 }}>
                <span className={classes.label}>{`${t('end_time')}:`}</span>
                <span className={classes.value}>
                  {format(
                    new Date(event.endTime),
                    lang === 'en'
                      ? `dd MMMM yyyy${event.isWholeDay ? '' : ' hh:mm a'}`
                      : `yyyy年MM月dd日${event.isWholeDay ? '' : ' hh:mm a'}`,
                    { locale },
                  )}
                </span>
              </div>
              <div className={classes.label} style={{ marginTop: 8 }}>
                {`${t('description')}:`}
              </div>
              <div className={classes.value}>
                {event.isPublicHoliday
                  ? t('hk_public_holiday')
                  : event.description}
              </div>
            </div>
          ) : (
            <List>
              {data.map((item) => (
                <ListItem
                  key={item.id}
                  role={undefined}
                  dense
                  button
                  className={classes.item}
                  onClick={() => setEvent(item)}
                >
                  <ListItemText
                    primary={item.title[lang]}
                    secondary={format(
                      new Date(item.startTime),
                      lang === 'en' ? 'dd MMMM yyyy' : 'yyyy年MM月dd日',
                      { locale },
                    )}
                    classes={{
                      primary: classes.itemTextPrimary,
                      secondary: classes.itemTextSecondary,
                    }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="event details"
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      className={classes.itemButton}
                      onClick={() => setEvent(item)}
                    >
                      <ChevronRightIcon fontSize="large" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        {!event && (
          <Pagination
            count={pageCount}
            defaultPage={1}
            page={pageIndex}
            showFirstButton
            showLastButton
            onChange={(e, value) => setPageIndex(value)}
            size="small"
            className={classes.pagination}
          />
        )}
      </Dialog>
      <EditEvent
        event={editingEvent}
        onClose={() => setEditingEvent(undefined)}
        refreshEvents={refreshEvents}
      />
    </div>
  );
};

export default EventsDialog;

import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/GetApp';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Director } from '../models/Director';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.16)',
      backgroundColor: '#ffffff',
      padding: '20px 30px',
      marginBottom: 2,
      borderRadius: 4,
      overflowY: 'auto',
      height: 340,
      maxHeight: 340,
    },
    companyImage: {
      height: 100,
      width: 100,
      marginRight: 16,
      backgroundColor: 'rgba(0,0,0,0.3)',
    },
    name: {
      flex: 1,
      fontSize: '1.2rem',
      fontWeight: 'bold',
      lineHeight: 1.2,
      color: '#262626',
      alignSelf: 'center',
      display: '-webkit-box',
      '-webkit-line-clamp': 4,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre-wrap',
    },
    addressLabel: {
      fontSize: '1rem',
      lineHeight: 1.13,
      color: '#575757',
      fontWeight: 'bold',
    },
    address: {
      fontSize: '1rem',
      lineHeight: 1.13,
      color: '#838383',
      marginTop: 4,
      marginBottom: 8,
    },
    label: {
      fontSize: '1rem',
      fontWeight: 'bold',
      lineHeight: 1.2,
      color: '#575757',
    },
    value: {
      fontSize: '1rem',
      lineHeight: 1.13,
      color: '#838383',
      marginTop: 4,
      whiteSpace: 'break-spaces',
    },
    button: {
      padding: 4,
      minWidth: 0,
      //   borderRadius: 24,
      '&:hover': {
        background: 'red',
      },
    },
    buttonIcon: {
      marginRight: 0,
      marginLeft: 0,
    },
  }),
);

interface Props {
  name?: string;
  address?: string;
  directors?: Director[];
  securityClass?: string;
  issuedCapital?: string;
  incorpDate?: string;
  incorpPlace?: string;
  incorpNumber?: string;
  companySecretary?: string;
  onClickDownload?: () => void;
}

const CardCompanyProfile: React.FC<Props> = ({
  name,
  address,
  directors,
  securityClass,
  issuedCapital,
  incorpPlace,
  incorpDate,
  incorpNumber,
  companySecretary,
  onClickDownload,
}) => {
  const { t } = useTranslation('entity_management');
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <div className="row" style={{ marginBottom: 8 }}>
        <div className={classes.name}>{name}</div>
        {onClickDownload && (
          <Button
            variant="contained"
            startIcon={<DownloadIcon htmlColor="#fff" fontSize="small" />}
            classes={{
              contained: classes.button,
              startIcon: classes.buttonIcon,
            }}
            onClick={onClickDownload}
          />
        )}
      </div>
      {address && (
        <>
          <div className={classes.addressLabel}>{t('registered_office')}</div>
          <div className={classes.address}>{` ${address}`}</div>
        </>
      )}
      {securityClass && (
        <div>
          <span className={classes.addressLabel}>
            {`${t('register_of_members:security_class')}: `}
          </span>
          <span className={classes.address}>{securityClass}</span>
        </div>
      )}
      {securityClass && (
        <div>
          <span className={classes.addressLabel}>
            {`${t('register_of_members:issued_capital')}: `}
          </span>
          <span className={classes.address}>{issuedCapital}</span>
        </div>
      )}
      {directors && (
        <div className="row">
          <div className="f1">
            <div className={classes.label}>{t('directors')}</div>
            {directors?.map((director) => (
              <div key={director.directorName} className={classes.value}>
                {director.directorName}
              </div>
            ))}
          </div>
          <div className="f1">
            <div className={classes.label}>{t('shareholders')}</div>
            <div className={classes.value}>-</div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="f1">
          <div className={classes.label}>{t('date_of_incorporation')}</div>
          <div className={classes.value}>{incorpDate}</div>
        </div>
        <div className="f1">
          <div className={classes.label}>{t('place_of_incorporation')}</div>
          <div className={classes.value}>{incorpPlace || '-'}</div>
        </div>
      </div>
      <div className="row">
        <div className="f1">
          <div className={classes.label}>{t('company_secretary')}</div>
          <div className={classes.value}>{companySecretary || '-'}</div>
        </div>
        <div className="f1">
          <div className={classes.label}>{t('registration_number')}</div>
          <div className={classes.value}>{incorpNumber || '-'}</div>
        </div>
      </div>
    </div>
  );
};

export default CardCompanyProfile;

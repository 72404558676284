import React from 'react';
import { Column } from 'react-table';
import PaginationTable from '../../../../components/PaginationTable';
import { Certificate } from '../../../../models/Certificate';
import RomService from '../../../../api/rom';

const COLUMNS: Array<Column<Certificate>> = [
  {
    Header: 'register_of_members:cert_no',
    accessor: 'fmtCertNo',
    disableFilters: true,
  },
  {
    Header: 'register_of_members:no_shares_units',
    accessor: 'denom',
    disableFilters: true,
    Cell: ({ value }) => value.toLocaleString(),
  },
];

interface Props {
  instrumentId: string;

  folioNo: string;
}

const CertificateList = ({ instrumentId, folioNo }: Props) => {
  const [data, setData] = React.useState<Certificate[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);

  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, sortBy }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      if (instrumentId && folioNo) {
        RomService.getCertificates(
          instrumentId,
          folioNo,
          pageSize,
          pageIndex,
          sortBy,
        )
          .then((res) => {
            if (fetchId === fetchIdRef.current) {
              setData(res.data);
              setPageCount(res.totalPage);
              setLoading(false);
            }
          })
          .catch(() => {
            setData([]);
            setPageCount(0);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [instrumentId, folioNo],
  );

  return (
    <div style={{ maxWidth: 600 }}>
      <PaginationTable
        columns={COLUMNS}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        defaultPageSize={5}
        disableSearch
        showEmptyRows={false}
      />
    </div>
  );
};

export default CertificateList;

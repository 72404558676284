export default {
  common: {
    required: '*必須填寫',
    create: 'Create',
    go_back: 'Go Back',
    select: '選擇',
    cancel: '取消',
    close: '關閉',
    submit: '提交',
    delete: '刪除',
    edit: '編輯',
    ok: '好',
    timeout_title: 'Timeout',
    timeout_message:
      "Your Vistra service will automatically time-out in around 1 minute.\nIf you wish to continue, please click 'OK'.",
    download: '下載',
    export: '轉存',
    done: 'Done',
    assign: 'Assign',
    unassign: 'Unassign',
    save: '儲存',
    remove: 'Remove',
    confirm: '確認',
    select_fields: '選擇欄目',
    fields: '欄目',
    all: '全部',
    from: '由',
    to: '至',
    learn_more: '了解更多',
    learn_more_message:
      '感謝您對{{feature}}服務的關注。您的客戶服務總監將與您聯繫。',
    learn_more_eagm: '「融會」',
    learn_more_eproxy: '電子委任代表系統「融會」',
    learn_more_boardfolio: '董事會議平台(Boardfolio)',
    learn_more_hkscc_registered_holders: '登記持有人之電子公司通訊',
    learn_more_hkscc_non_registered_holders:
      '香港中央結算非登記持有人之電子公司通訊',
    learn_more_ccass: '中央結算系統持股紀錄',
    learn_more_ownership_analytics: '持有人及股東分析',
    learn_more_request_new_report:
      '已收悉您希望索取定制的報告。您的客戶服務總監將盡快與您聯繫。',
    back: '返回',
    date_should_after_2021: '日期需為2021年1月1日或之後',
    start_date_should_before_end_date: '開始日期應早於結束日期或與結束日期相同',
    end_date_should_after_start_date: '結束日期應晚於開始日期或與開始日期相同',
    start_must_after_2016: '開始日期必須在2016年1月1日之後',
    date_must_within_a_year: '日期必須在今天止一年內。',
    date_cannot_be_future: '日期不能為未來日期。',
    start_must_after_minimal_date: '輸入日期不應早於最小日期',
    enter_any_date_preceding_current_date: '輸入當前日期之前的任何日期',

    date_must_before_2100: '日期需為2100年1月1日或之前',
    end_time_should_be_after_start_time: '結束時間不應早於開始時間',
    please_enter_valid_date: '請輸入有效的日期格式(DD/MM/YYYY)',
  },
  feedback: {
    let_us_know_your_feedback: '讓我們知道您的反饋，以便我們改善您的體驗。',
    ask_feedback_title: '我們希望您的反饋。',
    ask_feedback_description:
      '很榮幸能為您提供幫助。反饋有助於我們建立更好的在線形象並提供更好的服務。您願意花幾分鐘時間給我們評論嗎？非常感謝您抽出寶貴時間分享您的經驗。感謝您的意見。',
    leave_feedback: '留下反饋',
    maybe_later: '稍後留',
    do_not_show_again: '不再顯示',
    name: 'Name',
    date: 'Date',
    comments: '意見',
    rating: '評分',

    give_us_feedback_title: '給我們反饋',
    give_us_feedback_message:
      '請與我們分享您的想法，以獲得更好的體驗。 有了您的反饋，我們將能夠不斷改進並為您提供更好的服務。',
  },
  error: {
    required: '必須填寫',
    invalid_email: 'Invalid email address',
    required_id_or_passport_number:
      'Please enter "Hong Kong Identity Card Number" or "Passport Number"',
  },
  tabbar: {
    logout: 'Log Out',
    home: '主頁',
    register_of_members: '成員登記冊',
    ccass_holding: '中央結算系統持股記錄',
    reports: '報告',
    entity_management: '管理我的公司',
    market_intelligence: '市場資訊',
    industry_analyst_report: '行業分析報告',
    shareholder_analytics: '持有人及股東分析',
    eagm: '融會',
    eproxy: '電子委任代表系統',
    invoice_management: '賬單管理',
    board_portal: '董事會議平台',
    thought_leadership: 'Vistra卓佳洞察',
    manage_company: 'Manage Company',
    manage_rom: 'Manage ROM',
    manage_user: 'Manage User',
    manage_request: 'Manage Request',
    manage_feedback: 'Manage Feedback',
    manage_thought_leadership: 'Manage Vistra Inside Newsletter',
    manage_staff: 'Manage Staff',
  },
  login: {
    welcome: 'Welcome 欢迎',
    title: 'Online Company Secretary Platform',
    contact_us: 'Contact Us 联络我们',
    email: 'Email 电邮',
    password: 'Password 密码',
    forget_password: 'Forget Password? 忘记密码?',
    sign_in: 'Sign In 登记',
    reset_password: 'Reset Password',
    reset_password_description:
      'Enter your registered email below to receive password reset instruction',
    reset_password_success_description: '已發出重置密碼的電郵。',
    reset_password_expired: 'Reset Password',
    reset_password_expired_message:
      'We are sorry, the password reset link seems expired or invalid.',
    set_password_success: 'Set Password Success',
    set_password_success_message: 'Please use the new password to sign in.',

    set_password_title: 'Choose a new password',
    set_password_description:
      'A strong password consists of at least 12 characters that are a combination of uppercase letters, lowercase letters, numbers and special characters(@, #, $, %, etc.)',
    new_password: 'New password',
    confirm_password: 'Confirm password',
    account_verification: 'Account Verification\n账号验证',
    account_verification_message:
      'Please enter the one-time verification code sent to your registered email to access your account.\n请输入发送到阁下登记电邮的一次性验证码以进入阁下的账号。',
    account_verification_message_sms:
      'Please enter the one-time verification code sent to your registered mobile number {{phoneNumber}}.\n请输入发送到阁下登记手机号码{{phoneNumber}} 的一次性验证码。',
    send_verification_code: '發送驗證碼',
    resend_verification_code: '重新發送驗證碼',
    invalid_verification_code: '你輸入的驗證碼不符或已失效，請重新輸入。',
    confirm: '確認',
    request_new_code_in:
      'You may request for verification code or change account verification method after {{time}}\n阁下可于{{time}}后重新获取验证码或更改账号验证方式。',
  },
  relationship_manager: {
    client_management_team: '客戶管理團隊',
    client_director: '客戶總監',
    contact_us: '聯絡我們',
    contact: '聯絡',
    office_address: '辦公地址',
    email: '電郵',
    phone: '電話',
    contact_tricor: 'Contact Vistra',
  },
  home: {
    stock_quotation: '證券報價',
    last_updated: '最後更新',
    delayed_quote: '延遲報價',
    events: '活動',
    event_detail: '活動詳情',
    events_placeholder: 'No upcoming events after {{date}}.',
    ccass_holding: '中央結算系統持股記錄',
    ownership_analytics: '持有人及股東分析',

    create_an_event: '建立活動',
    edit_an_event: '編輯活動',
    title: '標題',
    description: '詳情',
    upcoming: '即將來臨',
    past: '過往',
    year: '年份',
    date: '日期',
    time: '時間',
    start_time: '開始時間',
    end_time: '結束時間',
    start: '開始時間',
    end: '結束時間',
    all_day: '全日',
    upcoming_event_detail: '活動詳情',
    hk_public_holiday: '香港公眾假期',

    share_registration_and_issuer_services: '股份登記及發行人服務',
    registry_management: '保存及管理股東登記冊',
    corporate_actions: '企業行動',
    shareholder_communications: '股東通訊',
    dividend_payment: '股息支付',
  },
  entity_management: {
    current_shareholders: '現任股東',
    list_of_entities: '公司名單',
    entity_list_placeholder: 'No entity',
    search_company_placeholder: '按公司名稱搜索',
    select_company: '選擇公司',

    my_requests: 'My Requests',
    my_requests_placeholder: 'There are no upcoming tasks',
    my_requests_submitted: 'Submitted',
    my_requests_completed: 'Completed',
    company_summary: '公司概要',

    upcoming_tasks: 'Upcoming Tasks',
    upcoming_tasks_placeholder: 'There are no upcoming tasks',

    recent_documents: '最近的文件',
    recent_documents_placeholder: '沒有最近的文件。',
    search_recent_documents_placeholder: '按描述搜索',
    date: '日期',
    code: 'Code',
    task_title: 'Task Title',
    status: '公司現況',
    active: '存續',
    closed: '已關閉',
    closing: '即將關閉',
    category: '類別',
    bank__financial_statements_financial_statements: '財務報表',
    statutory_legal_statutory_forms__submission:
      '法令的 /法定的: 法定表格 / 呈遞',
    statutory_legal_minutes_and_resolutions_director__officer:
      '法令的 /法定的: 會議記錄及決議: 董事及高級人員',

    description: '描述',
    file_code: 'File Code',
    name: '名稱',
    company_name: '公司名稱',
    date_of_incorporation: '註冊成立日期',
    place_of_incorporation: '成立地點',
    company_secretary: '公司秘書',
    registration_number: '登記號碼',
    jurisdiction: '管轄權',
    british_virgin_islands: '托爾托拉島(英屬處女群島)',
    cayman_islands: '開曼群島',
    china: '中國',
    hong_kong: '香港',
    labuan: '納閩',
    registered_office: '註冊辦事處地址',
    incorpated_in: '于{{country}}成立',
    current_directors: '現任董事',
    select_dates: 'Select Dates',
    appointment_date: '委任日期',
    resignation_date: 'Resignation Date',
    download_list_report: 'Download List Report',
    // Create Request
    create_request: 'Create Request',
    type_of_request: 'Type of Request:',
    please_input_details_below: 'Please input details below:',

    submit: 'Submit',
    cancel: 'Cancel',
    change_of_directors: 'Change of Directors',
    directors_particulars_change: 'Director’s Particulars Change',
    request_for_statutory_review: 'Request for Statutory Review',
    transfer_of_shares: 'Transfer of Shares',

    resignation_of_director: 'Resignation of Director',
    alternate_to: 'Alternate to:',
    alternate_director: 'Alternate Director',
    name_in_chinese: 'Name in Chinese:',
    name_in_english: 'Name in English:',
    alias_in_chinese: 'Alias in Chinese:',
    alias_in_english: 'Alias in English:',
    name_of_resigning_director: 'Name of resigning director',
    reason_of_cessation: 'Reason of Cessation:',
    resignation_or_others: 'Resignation / Others',
    deceased: 'Deceased',
    name_of_director: 'Name of director:',
    name_of_director_en: 'Name of director in English:',
    name_of_director_zh: 'Name of director in Chinese:',
    phone_number: 'Phone Number:',
    email_address: 'Email Address:',
    residential_address: 'Residential Address:',
    effective_date_of_change: 'Effective date of the change:',
    address_line: 'Address line {{number}}',
    hk_id_card_number: 'Hong Kong Identity Card Number:',
    passport_issuing_country: 'Passport Issuing Country / Region:',
    passport_number: 'Passport Number:',
    updated_particulars:
      'Updated Particulars (Please complete item(s) with change(s) only)',
    supporting_documents_needed: 'Supporting Documents Needed',
    supporting_documents: 'Supporting Documents',
    certified_id_copy_if_changed:
      'Certified true copy of HKID card/ passport (if HKID/ passport has changed):',
    certified_id_copy: 'Certified true copy of HKID card/ passport:',
    proof_of_address_if_changed: 'Proof of Address (if address has changed):',
    proof_of_address: 'Proof of Address:',
    name_of_audit_firm_name: 'Name of Audit Firm:',
    name_of_contact_person: 'Name of Contact Person:',
    preferred_date_time: 'Preferred date time:',
    preferred_time: 'Preferred time:',
    years_of_compnay_records_to_be_reviewed:
      'Years of company records to be reviewed:',
    consideration_to_be_received_or_paid: 'Consideration to be received/ paid:',
    type_of_review: 'Type of Review:',

    obtain_ecopies: 'Obtain e-copies',
    onsite_visit: 'Onsite visit',

    transferor: 'Transferor',
    transferee: 'Transferee',
    name_of_transferor: 'Name of Transferor:',
    name_of_transferee: 'Name of Transferee:',
    transferor_address: 'Address of Transferor:',
    transferee_address: 'Address of Transferee:',
    occupation_of_transferor: 'Occupation of Transferor:',
    occupation_of_transferee: 'Occupation of Transferee:',
    number_of_shares_to_be_transferred: 'Number of Shares to be Transferred:',
    value_of_transfer: 'Value/ Considerations of Transfer:',
    effective_date_of_transfer: 'Effective Date of the Transfer:',
    sale_and_purchase_agreement: 'Sale and Purchase Agreement:',
    sale_and_purchase_agreement_if_any: 'Sale and Purchase Agreement (If Any):',
    latest_consolidated_statesment:
      'Latest Consolidated Audited Financial Statements of the Company:',
    latest_management_accounts: 'Latest Management Accounts of the Company:',

    registered_address: 'Registered Address:',
    directors: 'Directors',
    shareholders: '股東',

    re_notice_cod: 'Re: Notice of Change in Particulars of Company Secretary',
    re_notice_dpc: 'Re: Notice of Director’s Particulars Change',
    re_notice_rsr: 'Re: Notice of Request for Statutory Review',
    re_notice_ts: 'Re: Notice of Transfer of Shares',

    request_of_type: 'Request of {{type}}',

    submitted: 'Submitted',
    completed: 'Completed',
    rejected: 'Rejected',
    pending: 'Pending',
  },
  manage_company: {
    back: 'Back to list of companies',
    select_company: '選擇公司',
    select_company_placeholder: 'No company',
    date: '日期',
    file_code: 'File Code',
    email: '電郵',
    name: '名稱',
    area_code: '國家/地區代碼',
    phone_number: '手機號碼',
    status: '公司現況',
    registered_office: '註冊辦事處地址',

    access_rights_list: '權限設置清單',
    existing_users: 'Existing Users',
    existing_users_placeholder: 'No Existing Users',
    company_profile: '公司概要',

    create_access_right: 'Create Access Right',
    edit_access_right: '更改權限設置',

    select_users: 'Select Users',
    add_user: 'Add User',
    remove_user: 'Delete Account',
    remove_user_message: 'Are you sure you want to remove user {{mail}}?',
  },
  manage_rom: {
    select_company: '選擇公司',
    select_company_placeholder: 'No company',
    back: 'Back to list of ROM companies',
    select_user: 'Select User',
    add_user: 'Add User',
  },
  manage_user: {
    users_data_download: 'Users Data Download',
    back: 'Back to list of users',
    select_user: 'Select User',
    select_user_placeholder: 'No users',
    name: '名稱',
    email: '電郵',
    status: '狀態',
    profile: 'User Profile',
    register_of_members: '成員登記冊',
    entity_management: '管理我的公司',
    contact_point: '聯絡人',
    create_user: '創建使用者帳戶',
    user_info: '賬戶資料',
    access_rights: '權限設置',
    last_login: '上次登錄日期',
    created_at: '創建日期',

    delete_user: 'Delete Account',
    delete_user_message:
      'Are you sure you want to delete account {{mail}}?\nThis cannot be undone.',

    staffs: 'Staffs',
    staffs_placeholder: 'No staff',
    rom_placeholder: 'No company',
    entity_management_placeholder: 'No company',
    relationship_manager: 'Relationship Manager',
    management_team: 'Management Team',
    select_new_relationship_manager: 'Select New Relationship Manager',
    please_select_rm: 'Please Select Relationship Manager',
    primary: 'Primary',
    set_as_primary: 'Set as Primary',
    remove: 'Remove',

    unlock: 'Unlock',
    resend: 'Resend',
    invitationsent: '已邀請',
    active: '已激活',
    locked: 'Locked',
    reset_password: 'Reset Password',

    error_mobile_format: '請輸入正確格式的手機號碼',
    error_email_already_registered: '此郵箱已註冊',
  },
  manage_staff: {
    back: 'Back to list of staffs',
    select_staff: 'Select Staff',
    select_staff_placeholder: 'No staff',
    create_staff: 'Create Staff',
    name: 'Name',
    email: 'Email',
    status: 'Status',
    role: 'Role',
    last_login: 'Last Login',
    created_at: 'Created At',

    add_client: 'Add Client',
    delete_user: 'Delete Account',
    delete_user_message:
      'Are you sure you want to delete account {{mail}}?\nThis cannot be undone.',

    unlock: 'Unlock',
    resend: 'Resend',
    invitationsent: 'Invited',
    active: 'Active',
    locked: 'Locked',
    reset_password: 'Reset Password',

    staff_profile: 'Account Information',
    existing_clients: 'Existing Clients',
    existing_clients_placeholder: 'No Existing Clients',
    edit_role: 'Edit Role',
  },
  manage_request: {
    manage_request: 'Manage Request',
    request_type: 'Request Type',
    date: 'Date',
    company: 'Company',
    task_title: 'Task Title',
    status: 'Status',
  },
  manage_thought_leadership: {
    manage_thought_leadership: 'Manage Vistra Inside Newsletter',
    created_at: 'Created At',
    title: 'Title',
    description: 'Description',
    image: 'Image',
    weblink: 'Link',
    create_article: 'Create Article',
    edit_article: 'Edit Article',
    delete_article: 'Delete Article',
    delete_article_message:
      'Are you sure you want to delete this article?\nThis cannot be undone.',
  },
  name_card: {
    office_address: '辦公地址',
    name: 'Name',
    email: '電郵',
    phone: '電話',
  },
  register_of_members: {
    select_company: '選擇公司',
    search_company_placeholder: '按公司名稱搜索',
    issue_summary: '發行總覽',
    holders_list_report: '持有人清單報告 ',
    holder_list: '持有人清單',
    holder_list_placeholder: '沒有搜索結果',
    holder_list_search_placeholder: '搜尋包括持有人姓名，帳戶號碼，證書編號',
    details: '詳情',
    issued_share_movement_history: '已發行證券變動記錄',
    share_movement_history_placeholder: '沒有搜索結果',
    share_movement_history_search_placeholder: '按類別搜尋',
    start_date: '開始日期',
    end_date: '結束日期',
    transaction_amount: '交易數量',
    transaction_unit_or_shares: '交易股份/單位',
    member_analysis: '成員分析',
    analysis: '的分析報告',
    no_of_holders: '持有人數量',
    issued_capital: '已發行股份/單位',
    par_value: '面值',
    trading_counter: '交易櫃台',
    ecorporate_communication: '電子公司通訊',
    registered_holders: '電子公司通訊：\n登記持有人',
    hkscc_non_registered_holders: '電子公司通訊：\n香港中央結算非登記持有人',
    select_no_of_shareholders: '持有人數量',
    request_for_service: '聯絡我們以啟用服務',
    sort: 'Sort',

    type_of_report: 'Type of Report',
    full_list: 'Full List',
    top_holder_list: 'Top Holder List',
    number_of_holders: '持有人數量',
    number_of_registered_holders: '登記持有人數量',
    all: '全部',
    top: '首',
    last: 'Last',
    sort_by: '排序方式',
    sort_order: 'Sort Order',
    asc: 'Ascending',
    desc: 'Descending',
    holders: '名持有人',
    fields: 'Fields',
    account_number: '帳戶號碼',
    download_holders_report: '下載持有人清單',
    download_report: '下載報告',
    holding_balance: 'Holding Balance',
    folio_no: '帳戶號碼',
    hdr_name: '持有人姓名',
    hdr_name_1: '持有人姓名',

    class_of_securities: '證券類別:',
    ordinary_shares: '普通股',
    preference_shares: 'Preference Shares',
    warrants: 'Warrants',
    dual_currency_securities: 'Dual Currency Securities',

    symbol: 'Symbol',
    account_no: '帳戶號碼',
    cert_no: '證書編號',
    no_of_certs: 'Number of Certificates',
    certificates_detail: '證書詳情',
    no_shares_units: '股份/單位數量',
    cert_value: 'Value',
    holder_name: '姓名',
    name: '姓名',
    address: '地址',
    balance: '結存',
    country_area: '國家/地區',
    security_class: 'Security Class',
    means_of_communication: '接收公司通訊的方式',
    access_through_others: '其他',
    access_through_website: '經網上閲覽',
    access_through_physical_copy: '收取印刷本',
    special: '特殊處理',
    new_holders: '新登記持有人',
    physical_copy_in_english_and_chinese: '中文及英文印刷本',
    physical_copy_in_english: '英文印刷本',
    physical_copy_in_chinese: '中文印刷本',
    notification_by_mail: '郵寄通知函',
    notification_by_email: '電郵通知函',
    dividend_election_instruction: '永久選擇收取現金股息/以新股代替現金股息',
    no_instruction: '沒有指示',
    permanent_election_for_scrip: '選擇永久收取代息股份',
    permanent_election_for_cash: '選擇永久收取現金股息',
    autopay_instruction: '收取現金股息的方式',
    currency_for_cash_dividend: '永久選擇收取現金股息貨幣',
    currency_election: '永久選擇收取現金股息貨幣',
    automatic_payment: '自動轉帳',
    cheque: '支票',
    registered_holders_label: 'Registered Holders (“RHs”)',
    holder_information: '持有人資料',
    holder_details: '持有人詳情',
    preferences: '持有人選擇',

    date_of_event: '變動日期',
    nature: '類別',
    issued_securities_balance: '已發行證券結存',
    select_category: '選擇類別',
    overseas_holders_analysis: '地域分析報告',
    total_holders: '持有人總數',
    percentage_of_holders: '持有人百分比',
    total_holdings: '總計持有',
    percentage_of_issued_securities: '已發行證券百分比',

    payment_instruction: '股息支付指示',
    currency: 'Currency',

    means_of_receiving_corporate_communications: '接收公司通訊方式的分析報告',
    number_of_registered_holders: '登記持有人數量',
    others: 'Others',
    email_notification_on_publication_of_corporate_communications: '電郵通知函',
    physical_otification_on_publication_of_corporate_communications:
      '印刷本通知函',
    physical_copy_in_english_and_chinese_html: '中文及英文',
    physical_copy_in_english_html: '英文',
    physical_copy_in_chinese_html: '中文',
    automatic_payment_html: '<span class=red>自動轉帳</span>',
    cheque_html: '<span class=red>支票</span>',
    to_receive_dividend_in_scrip_html: '新股份',
    to_receive_dividend_in_cash_html: '現金',
    no_instruction_given_html: '沒有指示',
    no_instruction_html: '<span class=red>沒有指示</span>',
    total: '總數',

    not_applicable: '不適用',
    has_corp_comm: '已採用',

    range_of_holdings_analysis: '持股量範圍分析報告',
    range_of_holdings: '持股量範圍',
    above: '超過',
    to: '至',
    from: '由',
    generate_range_of_holdings_report: '生成持股量範圍報告',

    error_input_value_should_be_larger_than: '輸入之數值需大於 {{value}}',
  },
  thought_leadership: {
    featured_articles: '精選文章',
  },
  ccass_holding: {
    holder_list_placeholder: '沒有搜索結果。請選擇其他日期。',
    shareholding_by_holder_types_percent: '各類持有人之持股量百分比',
    top_10_daily_holding_changes_in_ccass:
      '中央結算系統{{count}}大即日持股量變動(最後工作日)',
    top_10_holders_in_ccass: '中央結算系統{{count}}大持有人',
    participant_name: '參與者名稱',
    last_holding_date: '最後持股日期',
    shareholding: '持股量',
    change: '變動',
    percent_holding: '持股量百分比',
    percent_change: '變動百分比',
    percent_holdings_change: '持股量變動百分比',

    ccass_participant_information: '中央結算系統參與者資料',
    participant_details: '參與者詳情',
    participant_id: '參與者編號',
    ccass_participant_name: '參與者名稱',
    address: '地址',
    percent_of_share: '佔已發行股份/權證/單位百分比',
    southbound_holding: '港股通持股紀錄',
    select_last_holding_date: '選擇最後持股日期',
    data_not_available: '您選擇的日期沒有資料，請選擇其他日期',
    date_is_holiday: '日期必須為工作日。',
  },
  reports: {
    request_new_report: '索取新報告',
    scheduled: '恒常報告',
    on_demand: '按需報告',
    transfer_journal: '轉讓日誌',
    top_holders_list: '主要持股人清單',
    holders_list: '持有人清單',
    coming_soon: '快將推行',
    oversea_holders_list: '海外持有人名單',
    allotment_report: '配發報告',
    buyback_report: '證券註銷報告',
    other_report: '其他報告',
    select_year: '選擇年份',
    historical_on_demand_report: '過往的按需报告',
    file_name: '檔案名稱',
    date: '日期',
    date_or_period: '日期/時期',
    all: '全部',
    not_available: '不適用',

    please_select_required_report: '請選擇所需報告',
  },
  boardfolio: {
    boardfolio_intro: '數字化如何令董事會會議更高效率？',
    what_is_boardfolio: '什麼是 BOARDFOLIO?',
    what_is_boardfolio_description:
      'Boardfolio是Vistra卓佳先進的董事會門戶軟件解決方案，可令舉行董事會會議變得更輕鬆，並實現董事會與管理層之間的即時無縫協作。它提供安全的中央資料庫，存儲與董事會會議相關的所有記錄，為董事會成員和董事秘書團隊提供管理上的便利。',
    advanced_corporate_governance: '先進的公司管理',
    advanced_corporate_governance_description:
      'Boardfolio幫助企業達到公司管理、合規性和安全協作的標準，並可以將依賴人手及紙本文件的董事會會議流程無縫轉移至安全的數字平台。先進的文檔安全性和數據加密功能協助管理層人員作出快速而全面的決定。',
    seamless_meeting_management: '無縫的點對點會議管理',
    seamless_meeting_management_description:
      'Broadfolio不受地理限制，能支持多種設備、細分登入權限和實時數據共享，旨在解決董事會會議管理中的各種問題。會議解決方案包括董事會會議信息集的創建、議程建立、出席記錄、會議記錄發布和工作分配管理。董事秘書團隊可以把科技應用於會議的各個階段（會議前，會議中和會議後），從而節省大量時間和成本。',
    what_are_the_benfits: 'BOARDFOLIO 有什麼優勢？',
    security_and_access_control: '安全和登入權限控制',
    security_and_access_control_description:
      '<ul><li>先進的數據加密功能</li><li>加強保密和隱私性</li><li>自訂登入權限</li><li>仔細的文件層面控制（查看/下載/註解）</li></ul>',
    seamless_collaboration: '無縫合作',
    seamless_collaboration_description:
      ' <ul><li>利用雲端技術即時存取董事會記錄</li><li>促進委員會合作的虛擬工作組</li><li>評論和討論工具</li><li>輕鬆建立特殊項目/團隊</li></ul>',
    enhanced_productivity_and_efficiency: '提高生產力和效率',
    enhanced_productivity_and_efficiency_description:
      '<ul><li>儀表板提供項目摘要和快速訪問鏈接</li><li>全面的項目追踪</li><li>自動更新文件檔案</li><li>輕鬆建立董事會信息集</li><li>同時間管理多個項目</li></ul>',
    adminstrative_ease: '行政便利',
    adminstrative_ease_description:
      '<ul><li>組織會議、建立議程及董事會信息集</li><li>快速記錄會議和記錄出席情況</li><li>即時發布會議記錄，分配項目並存檔會議</li></ul>',
    how_can_tricor_help: 'BOARDFOLIO 如何無縫協作？',
    how_can_tricor_help_description:
      '通過Boardfolio，Vistra卓佳為您提供最新技術的先進解決方案，令您在管理敏感的董事會信息時更加輕鬆。您可以依靠我們高度安全的託管解決方案，這些解決方案有助於確保您的數據受到保護，並可隨時存取。\n\n我們聘請獨立的專家進行頻繁的安全審核，以確保流程的完整性和最佳實踐。 Boardfolio完全符合GDPR要求，並獲得Verasafe隱私認證，讓您全面接連第三方數據請求，在各個層面及傳輸過程中的數據加密功能令您安心。\n\n作為您的夥伴，Vistra卓佳助您增強競爭力並創造業務價值。我們提供客戶指導、專屬的客戶經理和迅速的客戶服務支持。\n\nBoardfolio可令舉行董事會會議變得更輕鬆，與我們合作將會令您的董事會流程管理更上一層樓。\n\nVistra卓佳的高度可擴展科技在安全的專用網絡上運行，存儲和傳輸所有機密數據均使用行業標準的加密系統進行加密。此外，Vistra卓佳亦提供ISO 27001認證的雲端技術和基礎設備，以確保可靠的服務和自動故障轉移機制，支持系統的高可用性。',
  },
  eagm: {
    hybrid_meeting: '混合會議',
    what_is_hybrid_meeting: '如何利用科技來提升對股東大會的管理？',
    what_is_hybrid_meeting_description:
      '混合會議結合了傳統實體會議和虛擬會議，是一個綜合的會議平台。混合會議通過先進的技術，讓成員可以選擇親自或線上參與會議，提供更高效的會議方式，同時有效管理溝通和投票事宜。虛擬會議（線上）和混合會議（線上和實體）解決了實體會議的挑戰，對提高成員出席率十分重要。這種會議方式具有高度的可擴展性，可節省大量成本並減少行政工作，也可提高成員的參與度。 融會（SPOT）容許成員進行線上投票，透過實時播放功能觀看會議以及作出線上提問，就如親身參與實體會議，擁有安全的在線投票功能和觀看即時結果是融會的兩大優勢。由於世界正面臨越來越多的環境和公共衛生挑戰，加上經常實施出行限制，因此融會能在這種意料之外的情況下發揮重要作用，使會議能夠按照計劃透過我們的安全系統順利進行。',
    hybrid_meeting_in_a_snapshot: '混合會議簡介',
    hybrid_meeting_what_are_the_benfits: '混合會議有何優勢？',
    member_engagement: '成員參與度',
    member_engagement_description:
      '<ul><li>未能親自參加會議的成員能選擇線上出席</li><li>透過不同設備和平台隨時登入</li><li>與親身參與會議享有相同的權利和好處</li></ul>',
    real_time_collaboration: '提供即時協作',
    real_time_collaboration_description:
      '<ul><li>數字化工具提升協作體驗</li><li>方便主席與成員分享提問</li><li>多源實時播放和內容共享</li><li>進行即時在線投票和提交投票</li><li>享用主席介面</li></ul>',
    transparency_and_security: '增加透明度與安全性',
    transparency_and_security_description:
      '<ul><li>即時發布投票結果</li><li>直接互動，董事會能即時回答提問</li><li>增強投票和報告準確性</li><li>安全登入</li><li>使用雲端設備及加密技術</li></ul>',
    saving_and_efficiency: '成本節省和高效',
    saving_and_efficiency_decscription:
      '<ul><li>電子投票顯著能節省成員交通時間</li><li>虛擬會議達到節省成本</li><li>擁有高度擴展性，參與人數不限</li><li>透過虛擬參與提高效率</li></ul>',
    spot_is_ultimate_solution: '融會是混合會議的最佳解決方案',
    spot_is_ultimate_solution_description:
      'Vistra卓佳是會議管理和投票監督服務的市場領導者。我們確保上市發行人完全符合所有相關監管要求，是您值得信賴的合作夥伴。融會是我們在過去20多年從服務數以千計客戶股東大會上獲得的經驗而結集的心血結晶。我們為您管理整個會議流程，從審視所有會議文檔、會議通知、在線會議用戶指南、會議過程、主席講話文稿，以至在會議前測試系統及與主席排演流程。\n\nVistra卓佳的高度可擴展科技在安全的專用網絡上運行，存儲和傳輸所有機密數據均使用行業標準的加密系統進行加密。此外，Vistra卓佳亦提供ISO 27001認證的雲端技術和基礎設備，以確保可靠的服務和自動故障轉移機制，支持系統的高可用性。',

    eproxy_intro: '電子委任代表系統',
    what_is_eproxy: '如何通過安全的電子委任代表系統增強投資者關係？',
    what_is_eproxy_description:
      '為了確保良好的公司治理，必須讓盡可能多的股東參與重大決策。但是，由於各種原因，很難保證所有董事會成員和股東出席。在全球範圍內，當電子會議（混合或虛擬）和電子委任代表功能到位時，公司的參與度將有所增加。電子委任代表系統通過為股東提供結構化和安全的線上平台處理電子委任代表和投票指示。',
    what_are_the_benfits: '對企業的價值',
    streamlining_management_of_proxies: '簡化代理管理',
    streamlining_management_of_proxies_description:
      '<ul><li>可立即修改代理人任命和投票指示</li><li>全面的委託摘要，避免大股東或其他個人或機構投資者偶爾遺漏投票</li></ul>',
    facilitating_investor_relations: '促進投資者關係',
    facilitating_investor_relations_description:
      '<ul><li>最新的投票指導信息，有助增進投資者關係</li><li>與股東進行電子溝通以推動參與</li></ul>',
    enhancing_corporate_governance: '加強公司管治',
    enhancing_corporate_governance_description:
      '<ul><li>增加了股東在重大決策的參與度</li><li>通過為股東提供一個電子委任代表系統來提升公司形象</li></ul>',
    enjoying_security_and_efficiency: '享受安全和效率',
    enjoying_security_and_efficiency_description:
      '<ul><li>加密的服務器網絡，可將股東提交的所有信息進行保密</li><li>以安全有效的方式提交的電子委任代表任命/指示</li></ul>',
    how_can_tricor_help: 'Vistra卓佳如何提供幫助?',
    how_can_tricor_help_description:
      'Vistra卓佳作為一站式服務商，提供具有凝聚力的電子會議和電子委任代表解決方案，以確保大會的無縫舉行和主持， 以及成功的現場、在線投票以及結果驗證。我們的專業團隊還可以幫助您審查和修改公司章程，以使電子股東大會和委任代表成為可能。',
    key_features_of_eproxy: '電子委任代表系統的主要功能',
    key_features_1: '股東可查閱每日持股情況',
    key_features_2:
      '提交截止日期前的任何時間（24/7）進行輸入，一鍵提交，簡易快捷',
    key_features_3:
      '多功能輸入指示，包括委派單一委任代表或委任多名代表及在指定截止日期前取消或修改委任指示',
    key_features_4:
      '下載已成功提交的電子委任代表及/或其投票指示之回執，以確保股東指令成功提交作存檔',
    key_features_5:
      '中央結算以電子形式提交其參與者之委任代表資料及/或其投票指示',

    stock_code: '股份代號',
    company: '公司/主辦機構',
    meeting_type: '會議類別',
    meeting_time: '會議時間',
    search_placeholder: '搜尋',
    welcome_to_spot: '歡迎登入融會（Vistra卓佳電子會議系統）',
  },
  eproxy: {
    eproxy_intro: '企業數字化',
    what_is_eproxy: '如何通過安全的電子委任代表系統增強投資者關係？',
    what_is_eproxy_description:
      '為了確保良好的公司治理，必須讓盡可能多的股東參與重大決策。但是，由於各種原因，很難保證所有董事會成員和股東出席。在全球範圍內，當電子會議（混合或虛擬）和電子委任代表功能到位時，公司的參與度將有所增加。電子委任代表系統通過為股東提供結構化和安全的線上平台處理電子委任代表和投票指示。',
    what_are_the_benfits: '對企業的價值',
    streamlining_management_of_proxies: '簡化代理管理',
    streamlining_management_of_proxies_description:
      '<ul><li>可立即修改代理人任命和投票指示</li><li>全面的委託摘要，避免大股東或其他個人或機構投資者偶爾遺漏投票</li></ul>',
    facilitating_investor_relations: '促進投資者關係',
    facilitating_investor_relations_description:
      '<ul><li>最新的投票指導信息，有助增進投資者關係</li><li>與股東進行電子溝通以推動參與</li></ul>',
    enhancing_corporate_governance: '加強公司管治',
    enhancing_corporate_governance_description:
      '<ul><li>增加了股東在重大決策的參與度</li><li>通過為股東提供一個電子委任代表系統來提升公司形象</li></ul>',
    enjoying_security_and_efficiency: '享受安全和效率',
    enjoying_security_and_efficiency_description:
      '<ul><li>加密的服務器網絡，可將股東提交的所有信息進行保密</li><li>以安全有效的方式提交的電子委任代表任命/指示</li></ul>',
    how_can_tricor_help: 'Vistra卓佳如何提供幫助?',
    how_can_tricor_help_description:
      'Vistra卓佳作為一站式服務商，提供具有凝聚力的電子會議和電子委任代表解決方案，以確保大會的無縫舉行和主持， 以及成功的現場、在線投票以及結果驗證。我們的專業團隊還可以幫助您審查和修改公司章程，以使電子股東大會和委任代表成為可能。',
    key_features_of_eproxy: '電子委任代表系統的主要功能',
    key_features_1: '股東可查閱每日持股情況',
    key_features_2:
      '提交截止日期前的任何時間（24/7）進行輸入，一鍵提交，簡易快捷',
    key_features_3:
      '多功能輸入指示，包括委派單一委任代表或委任多名代表及在指定截止日期前取消或修改委任指示',
    key_features_4:
      '下載已成功提交的電子委任代表及/或其投票指示之回執，以確保股東指令成功提交作存檔',
    key_features_5:
      '中央結算以電子形式提交其參與者之委任代表資料及/或其投票指示',
  },
  currency: {
    singapore_dollars: '新加坡元',
    japanese_yen: '日圓',
    korean: '南韓圜',
    hong_kong_dollars: '港元',
    united_states_dollars: '美元',
    australia: '澳元',
    chinese_rmb: '人民幣',
    norway: '挪威克朗',
    european: '歐元',
    malaysian: '馬來西亞零吉',
    canada: '加拿大元',
    british: '英鎊',
    chinese_yuan: '人民幣',
  },
  country: {
    bermuda_or_cayman_islands: '',
    'bermuda_or_hong_kong_re-domiciled': '',
    bermuda_liquidation: '',
    'bvi_or_cayman_islands_re-domiciled': '',
    cayman_islands_or_bermuda: '',
    'cayman_islands_or_bermuda_re-domiciled': '',
    'cayman_islands_or_bermuda_re-domiciled_liquidation': '',
    'cayman_islands_or_hong_kong_re-domiciled': '',
    cayman_islands_liquidation: '',
    'singapore_or_hong_kong_re-domiciled': '',
    albania: '阿爾巴尼亞',
    algeria: '阿爾及利亞',
    angola: '安哥拉',
    'anguilla,_b.w.i.': '安圭拉',
    argentina: '阿根庭',
    armenia: '亞美尼亞共和國',
    '(z)_australia': '澳洲',
    australia: '澳洲',
    austria: '奧地利',
    bahamas: '巴哈馬',
    bahrain: '巴林',
    bangladesh: '孟加拉',
    barbados: '巴巴多斯',
    belgium: '比利時',
    belize: '伯利茲',
    bermuda: '百慕達',
    bhutan: '不丹',
    bolivia: '玻利維亞',
    botswana: '博茨瓦納',
    brazil: '巴西',
    british_isles: '不列顛群島',
    '(z)_british_virgin_islands': '托爾托拉島(英屬處女群島)',
    british_virgin_islands: '托爾托拉島(英屬處女群島)',
    british_west_indies: '西印度群島聯邦',
    brunei: '汶萊',
    bulgaria: '保加利亞',
    burkina_faso: '布基納法索',
    burma: '缅甸',
    burundi: '布隆迪',
    cambodia: '柬埔寨',
    canada: '加拿大',
    cayman_islands: '開曼群島',
    central_african_republic: '中非共和國',
    '(z)_channel_islands': '海峽群島',
    channel_islands: '海峽群島',
    chile: '智利',
    '(z)_china': '中國',
    '*china_guangdong_province': '中國廣東省',
    china: '中國',
    colombia: '哥倫比亞',
    congo: '剛果',
    cook_islands: '庫克群島',
    costa_rica: '哥斯達黎加',
    croatia: '克羅地亞',
    cuba: '古巴',
    curacao: '庫拉索島',
    cyprus: '塞浦路斯',
    czech_republic: '捷克',
    democratic_yemen: '也門',
    denmark: '丹麥',
    djibouti: '吉布提',
    dominican_republic: '多明尼加共和國',
    ecuador: '厄瓜多爾',
    egypt: '埃及',
    eire: '愛爾蘭',
    el_salvador: '薩爾瓦多',
    equatorial_guinea: '赤道畿內亞',
    ethiopia: '埃塞俄比亞',
    fiji: '斐濟',
    finland: '芬蘭',
    '(z)_france': '法國',
    france: '法國',
    gabon: '加薘',
    gambia: '岡比亞',
    german_democratic_republic: '德意志民主共和國',
    '(z)_germany': '德國',
    germany: '德國',
    ghana: '加納',
    gibraltar: '直布羅陀',
    gilbert_islands: '加拉帕戈斯群島',
    greece: '希臘',
    grenada: '格林納達',
    guam: '關島',
    guatemala: '危地馬拉',
    guernsey: '格恩西島',
    guinea: '幾內亞',
    'guinea-bissau': '幾內亞比紹',
    guyana: '圭亞那',
    haiti: '海地',
    honduras: '洪都拉斯',
    '(z)_hong_kong': '香港',
    'hk-special_delivery_(z1)': '香港',
    'hk-special_delivery_(z10)': '香港',
    'hk-special_delivery_(z11)': '香港',
    'hk-special_delivery_(z12)': '香港',
    'hk-special_delivery_(z13)': '香港',
    'hk-special_delivery_(z14)': '香港',
    'hk-special_delivery_(z15)': '香港',
    'hk-special_delivery_(z1b)': '香港',
    'hk-special_delivery_(z2)': '香港',
    'hk-special_delivery_(z3)': '香港',
    'hk-special_delivery_(z4)': '香港',
    'hk-special_delivery_(z5)': '香港',
    'hk-special_delivery_(z6)': '香港',
    'hk-special_delivery_(z7)': '香港',
    'hk-special_delivery_(z8)': '香港',
    'hk-special_delivery_(z9)': '香港',
    hong_kong: '香港',
    'hong_kong_(c)': '香港',
    hungary: '匈牙利',
    iceland: '冰島',
    '(z)_india': '印度',
    india: '印度',
    indonesia: '印尼',
    iran: '伊朗伊斯蘭共和國',
    iraq: '伊拉克',
    '(z)_ireland': '愛爾蘭',
    ireland: '愛爾蘭',
    'ireland_(irl)': '愛爾蘭',
    '(z)_isle_of_man': '人島',
    isle_of_man: '人島',
    israel: '以色列',
    italy: '意大利',
    ivory_coast: '科特迪瓦',
    jamaica: '牙買加',
    japan: '日本',
    '(z)_jersey': '澤西',
    jersey: '澤西',
    '(z)_jordan': '約旦',
    jordan: '約旦',
    kenya: '肯尼亞',
    kingdom_of_cambodia: '柬埔寨',
    korea: '韓國',
    kowloon: '九龍',
    kuwait: '科威特',
    laos: '老撾',
    lebanon: '黎巴嫩',
    lesotho: '萊索托 ',
    liberia: '利比利亞',
    libya: '利比亞',
    liechtenstein: '列支頓士登',
    luxembourg: '盧森堡',
    macau: '澳門',
    madagascar: '馬達加斯加',
    malawi: '馬拉維',
    '(z)_malaysia': '馬來西亞',
    malaysia: '馬來西亞',
    maldives: '馬爾代夫',
    mali: '馬里',
    '(z)_malta': '馬爾他',
    malta: '馬爾他',
    marshall_islands: '馬紹爾群島',
    mauritania: '毛里塔尼亞',
    mauritius: '毛里求斯',
    mexico: '墨西哥',
    monaco: '摩洛哥',
    mongolia: '蒙古',
    montserrat: '蒙特塞拉特島',
    morocco: '摩洛哥',
    mozambigue: '莫桑比克',
    namibia: '納米比亞',
    naura: '瑙魯',
    nepal: '尼泊爾',
    netherlands: '荷蘭',
    netherlands_antilles: '荷屬安的列斯',
    '(z)_new_zealand': '新西蘭',
    new_zealand: '新西蘭',
    nicaragua: '尼加拉瓜',
    niger: '尼日爾',
    '(z)_nigeria': '尼日利亞',
    nigeria: '尼日利亞',
    niue: '尼烏埃島',
    north_korea: '朝鮮',
    '(z)_northern_ireland': '北愛爾蘭',
    northern_ireland: '北愛爾蘭',
    norway: '挪威',
    oman: '阿曼',
    pakistan: '巴基斯坦',
    panama: '巴拿馬',
    papua_new_guinea: '巴布亞新幾內亞',
    paraguay: '巴拉圭',
    peru: '秘魯',
    '(z)_philippines': '菲律賓',
    philippines: '菲律賓',
    poland: '波蘭',
    '(z)_portugal': '葡萄牙',
    portugal: '葡萄牙',
    qatar: '卡塔爾',
    republic_of_south_africa: '南非',
    romania: '羅馬尼亞',
    russia: '俄羅斯',
    rwanda: '盧旺達',
    samoa: '薩摩亞',
    sao_tome_and_principe: '聖多美和普林西比',
    saudi_arabia: '沙地阿拉伯',
    scotland: '蘇格蘭',
    senegal: '塞內加爾 ',
    '(z)_seychelles': '塞舌爾',
    seychelles: '塞舌爾',
    sierra_leone: '塞拉里昂 ',
    '(z)_singapore': '新加坡',
    singapore: '新加坡',
    slovakia: '斯洛伐克',
    solomon_islands: '所羅門群島',
    somalia: '索馬里',
    south_africa: '南非',
    '(z)_spain': '西班牙',
    spain: '西班牙',
    sri_lanka: '斯里蘭卡',
    sudan: '蘇丹',
    suriname: '蘇里南',
    sweden: '瑞典',
    switzerland: '瑞士',
    'switzerland_(che)': '瑞士',
    syria: '敘利亞',
    taiwan: '台灣',
    thailand: '泰國',
    togo: '多哥 ',
    tonga: '湯加',
    trinidad_and_tobago: '千里達及多巴哥',
    tunisia: '突尼斯',
    '(z)_turkey': '土耳其',
    turkey: '土耳其',
    uganda: '烏干達',
    ukraine: '烏克蘭',
    united_arab_emirates: '阿拉伯聯合酋長國',
    '(z)_united_kingdom': '英國',
    united_kingdom: '英國',
    united_republic_of_cameroon: '喀麥隆',
    united_repulic_of_tanzania: '坦桑尼亞',
    '(z)_united_states_of_america': '美國',
    united_states_of_america: '美國',
    unknown_overseas_address: '未知的海外地址',
    uruguay: '烏拉圭',
    'usa-special_delivery': '美國特別送貨',
    vanuatu: '瓦努阿圖',
    vatican: '梵蒂岡',
    venezuela: '委內瑞拉 ',
    vietnam: '越南',
    west_africa: '西非',
    yemen: '也門',
    yugoslavia: '南斯拉夫',
    zaire: '扎伊爾',
    zambia: '贊比亞',
    zimbabwe: '津巴布韋',
  },
};

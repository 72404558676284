/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from '@material-ui/core';
import QuestionAnswerRoundedIcon from '@material-ui/icons/QuestionAnswerRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { CompanyUser } from '../models/CompanyUser';
import { selectUserInfo } from '../pages/auth/duck/selector';
import { RootState } from '../store';
import FeedbackDialog from './FeedbackModal';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '8px 0',
  },
  description: {
    flex: 1,
    fontSize: '1rem',
    fontStyle: 'italic',
    color: '#262626',
  },
  feedbackButton: {
    backgroundColor: '#b4b4b4',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#b4b4b4',
      opacity: 0.8,
    },
  },
  labelFeedback: {
    textTransform: 'capitalize',
    fontSize: '1.2rem',
    color: 'white',
  },
  message: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
  form: {
    '& label': {
      color: 'red',
    },
  },
  rating: {
    fontSize: '1.3rem',
    fontWeight: 'bold',

    marginTop: 20,
  },
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonSubmit: {
    backgroundColor: '#0909B7',
    marginRight: 48,
    '&:hover': {
      backgroundColor: '#001A5B',
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelSubmit: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  labelCancel: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#707070',
  },
  star: {
    color: '#454545 !important',
  },
  listItemIcon: {
    minWidth: 36,
    '& svg': { height: 18, width: 18 },
  },
  listItem: {
    padding: '12px 22px',
    '& span': { color: '#838383' },
    '& path': { fill: '#838383', opacity: 1 },
  },
  listItemTextRoot: {
    flex: 'unset',
    marginRight: 8,
  },
  listItemText: {
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
    fontWeight: 'bold',
    lineHeight: 1.28,
  },
}));

interface Props {
  user?: CompanyUser | null;
  onClick?: () => void;
}

const Feedback: React.FC<Props> = ({ user, onClick }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <ListItem
        disableRipple
        button
        onClick={() => {
          setOpen(true);
        }}
        className={classes.listItem}
        style={{ alignSelf: 'flex-end' }}
      >
        <ListItemIcon classes={{ root: classes.listItemIcon }}>
          <QuestionAnswerRoundedIcon htmlColor="#fff" />
        </ListItemIcon>
        <ListItemText
          classes={{
            root: classes.listItemTextRoot,
            primary: classes.listItemText,
          }}
          primary={t('feedback:leave_feedback')}
        />
      </ListItem>
      {user && (
        <FeedbackDialog
          open={open}
          onClose={() => setOpen(false)}
          user={user}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  user: selectUserInfo(state),
});

export default connect(mapStateToProps)(Feedback);

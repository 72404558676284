import { Button, makeStyles, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { DialogContent, DialogTitle } from './Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonRemove: {
    marginRight: 48,
    backgroundColor: '#0909B7',
    '&:hover': {
      backgroundColor: '#001A5B',
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelRemove: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  labelCancel: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#707070',
  },
}));

interface Props {
  onIdle: () => void;
  msTimeout: number;
}

const TimeoutDialog: React.FC<Props> = ({ onIdle, msTimeout }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [open, setOpen] = React.useState(false);
  const timerRef = React.useRef<NodeJS.Timeout>();

  useIdleTimer({
    timeout: msTimeout,
    onIdle: (event) => {
      setOpen(true);
      timerRef.current = setTimeout(() => {
        onIdle();
      }, 60000);
    },
    debounce: 500,
  });

  const handleClose = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="timeout-dialog-title"
      open={open}
      disableBackdropClick
    >
      <DialogTitle id="timeout-dialog-title" onClose={handleClose}>
        {t('timeout_title')}
      </DialogTitle>
      <DialogContent>
        <div className={classes.message}>{t('timeout_message')}</div>
      </DialogContent>
      <div className={classes.bottomContainer}>
        <Button
          variant="contained"
          color="primary"
          classes={{
            root: classes.buttonRemove,
            label: classes.labelRemove,
          }}
          onClick={handleClose}
        >
          {t('ok')}
        </Button>
      </div>
    </Dialog>
  );
};

export default TimeoutDialog;

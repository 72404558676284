/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { Button, IconButton, makeStyles, Theme } from '@material-ui/core';
import PermContactCalendarRoundedIcon from '@material-ui/icons/PermContactCalendarRounded';
import TuneRounded from '@material-ui/icons/TuneRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Column } from 'react-table';
import RomService from '../../../../api/rom';
import PaginationTable from '../../../../components/PaginationTable';
import { ColumnProps } from '../../../../components/SelectCloumnFilter';
import SelectCloumnMultipleFilter from '../../../../components/SelectCloumnMultipleFilter';
import Holder from '../../../../models/Holder';
import MultiLang from '../../../../models/MultiLang';
import { saveAsFile } from '../../../../utils';
import { selectLang } from '../../../settings/duck/selector';
import CertificateDetailModal from './CertificateDetailModal';
import HolderInformation from './HolderInformation';

interface Props {
  instrumentId?: string | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  downloadButton: {
    // borderRadius: 24,
    marginLeft: 16,
    backgroundColor: '#0909B7',
    fontSize: '1.2rem',
    color: '#fff',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#001A5B',
    },
  },
  selectFieldButton: {
    margin: '0 8px',
  },
  label: {
    color: '#838383',
    fontSize: '1.3rem',
    fontWeight: 'bold',
    marginBottom: 8,
  },
  checkboxGroup: {
    '& .MuiFormControlLabel-label': { fontSize: '1.2rem' },
    '& .MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: 'rgba(255, 255, 255, 0.5)',
    },
  },
}));

const HolderList: React.FC<Props> = ({ instrumentId }) => {
  const { t } = useTranslation('register_of_members');
  const classes = useStyles();
  const lang = useSelector(selectLang);

  const [data, setData] = React.useState<Holder[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [holder, setHolder] = React.useState<Holder | undefined>(undefined);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<{
    [field: string]: MultiLang[];
  }>({});
  const [tableParams, setTableParams] = React.useState<any>({});
  const [fields, setFields] = React.useState<string[]>([]);
  const columns = React.useMemo<Array<Column<Holder>>>(() => {
    return [
      {
        Header: 'register_of_members:account_no',
        accessor: 'folioNo',
        disableFilters: true,
        width: 80,
      },
      {
        Header: 'register_of_members:name',
        accessor: 'hdrName',
        disableFilters: true,
      },
      {
        Header: 'register_of_members:address',
        accessor: 'address',
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'register_of_members:country_area',
        accessor: 'mcoName',
        disableFilters: !options.mcoName,
        Filter: (props: ColumnProps) => (
          <SelectCloumnMultipleFilter
            {...props}
            customOptions={options?.mcoName}
          />
        ),
        Cell: ({ row: { original } }) => original.mcoName[lang],
      },
      {
        Header: (
          <div style={{ flex: 1, textAlign: 'end' }}>
            {t('register_of_members:balance')}
          </div>
        ),
        width: 100,
        accessor: 'balance',
        disableFilters: true,
        Cell: ({ value }) => (
          <div style={{ textAlign: 'right' }}>{value.toLocaleString()}</div>
        ),
      },
      {
        Header: 'register_of_members:means_of_communication',
        accessor: 'annualRpt',
        disableFilters: !options.annualRpt,
        Filter: (props: ColumnProps) => (
          <SelectCloumnMultipleFilter
            {...props}
            customOptions={options.annualRpt}
          />
        ),
        Cell: ({ value }) => value[lang],
      },
      {
        Header: 'register_of_members:dividend_election_instruction',
        accessor: 'divPlan',
        disableFilters: !options.divPlan,
        Filter: (props: ColumnProps) => (
          <SelectCloumnMultipleFilter
            {...props}
            customOptions={options.divPlan}
          />
        ),
        Cell: ({ value }) => value[lang],
      },
      {
        Header: 'register_of_members:autopay_instruction',
        accessor: 'payInst',
        disableFilters: !options.payInst,
        Cell: ({ value }) => value[lang],
        Filter: (props: ColumnProps) => (
          <SelectCloumnMultipleFilter
            {...props}
            customOptions={options.payInst}
          />
        ),
      },
      {
        Header: 'register_of_members:currency_for_cash_dividend',
        accessor: 'mcuCode',
        disableFilters: !options.mcuCode,
        Filter: (props: ColumnProps) => (
          <SelectCloumnMultipleFilter
            {...props}
            customOptions={options.mcuCode}
          />
        ),
        Cell: ({ value }) => value[lang],
      },
      {
        Header: 'register_of_members:certificates_detail',
        accessor: 'noOfCerts',
        disableFilters: true,
        width: 100,
        Cell: ({ row: { original } }: any) =>
          instrumentId && (
            <CertificateDetailModal
              instrumentId={instrumentId}
              folioNo={original.folioNo}
            />
          ),
      },
    ];
  }, [options, instrumentId, lang]);

  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy, filters }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      if (instrumentId) {
        const filterObj = filters?.reduce(
          (acc: Map<string, any>, item: any) => ({
            ...acc,
            [item.id]: item.value,
          }),
          {},
        );

        setTableParams({
          fullTextSearch: query,
          sortBy: sortBy?.id === 'hdrName' ? 'hdrName1' : sortBy?.id,
          sortOrder: sortBy ? (sortBy.desc ? 'desc' : 'asc') : undefined,
          filterV3: filterObj,
        });

        RomService.getCompanyHolders(
          instrumentId,
          pageSize,
          pageIndex,
          query,
          sortBy?.id === 'hdrName' ? { ...sortBy, id: 'hdrName1' } : sortBy,
          filterObj,
        )
          .then((res) => {
            if (fetchId === fetchIdRef.current) {
              setData(res.data);
              setPageCount(res.totalPage);
              setLoading(false);
            }
          })
          .catch(() => {
            setData([]);
            setPageCount(0);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [instrumentId],
  );

  React.useEffect(() => {
    setTableParams({});

    if (instrumentId) {
      RomService.getHolderListFilterOptions(instrumentId).then((res) => {
        setOptions(res);
      });
    }
  }, [instrumentId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onClickDownloadReport = () => {
    if (instrumentId) {
      RomService.getActiveHoldersReport(instrumentId, {
        ...tableParams,
        columns: fields.join(','),
        lang,
      })
        .then((res) => {
          saveAsFile(new Blob([res.data]), 'shareholders-report.xlsx');
        })
        .catch((e) => {
          alert('Fail to download file');
        });
    }
  };

  return (
    <div>
      <PaginationTable
        key={instrumentId}
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder={t('holder_list_search_placeholder')}
        placeholder={t('holder_list_placeholder')}
        onClickRow={(item) => setHolder(item)}
        onFieldsModalClose={handleClose}
        selectFieldOpen={open}
        selectFieldBlacklist={[
          'folioNo',
          'hdrName',
          'balance',
          'address',
          'noOfCerts',
        ]}
        onFieldsChange={setFields}
        renderHeaderLeft={
          <>
            <PermContactCalendarRoundedIcon className="section-icon" />
            <span className="section-title f1">{t('holder_list')}</span>
          </>
        }
        renderHeaderRight={
          <>
            <Button
              variant="contained"
              className={classes.downloadButton}
              onClick={onClickDownloadReport}
              disableElevation
            >
              {t('common:export')}
            </Button>
            <IconButton
              color="primary"
              aria-label="select fields"
              component="span"
              onClick={handleClickOpen}
              className={classes.selectFieldButton}
            >
              <TuneRounded fontSize="large" />
            </IconButton>
          </>
        }
      />
      <HolderInformation holder={holder} onClose={() => setHolder(undefined)} />
    </div>
  );
};

export default HolderList;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import GpsNotFixedRoundedIcon from '@material-ui/icons/GpsNotFixedRounded';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/core';
import { Column } from 'react-table';
import PaginationTable from '../../../components/PaginationTable';
import ThoughtLeadershipService from '../../../api/thoughtLeadership';
import { Article } from '../../../models/Article';
import CreateArticle from './CreateArticle';
import EditArticle from './EditArticle';
import DeleteArticleDialog from './DeleteArticleDialog';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    '& tr': {
      cursor: 'pointer',
    },
  },
}));

const COLUMNS: Array<Column<Article>> = [
  {
    Header: 'manage_thought_leadership:created_at',
    accessor: 'createdAt',
    disableFilters: true,
    maxWidth: 140,
    Cell: ({ value }: any) => format(new Date(value), 'yyyy-MM-dd'),
  },
  {
    Header: 'manage_thought_leadership:title',
    accessor: 'title',
    disableFilters: true,
  },
  {
    Header: 'manage_thought_leadership:weblink',
    accessor: 'webLink',
    disableFilters: true,
  },
];

const ManageThoughtLeadership: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [articles, setArticles] = React.useState<Article[]>([]);
  const [article, setArticle] = React.useState<Article | undefined>();
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [createArticleCount, setCreateArticleCount] = React.useState(0);

  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      ThoughtLeadershipService.getThoughtLeadership(
        pageSize,
        pageIndex,
        query,
        sortBy,
      ).then(({ data, totalPage }) => {
        if (fetchId === fetchIdRef.current) {
          setArticles(data);
          setPageCount(totalPage);
          setLoading(false);
        }
      });
    },
    [],
  );

  const onClickRow = (item: Article) => setArticle(item);

  const refreshArticles = () => setCreateArticleCount((state) => state + 1);
  const renderActions = (item: Article) => (
    <div className="table-row-actions" style={{ display: 'flex' }}>
      <DeleteArticleDialog
        small
        article={item}
        onDeleteSuccess={refreshArticles}
      />
      <div style={{ width: 8 }} />
      <EditArticle article={item} onEditSuccess={refreshArticles} />
    </div>
  );

  return (
    <div className={classes.container}>
      <PaginationTable
        disableSearch
        key={createArticleCount}
        defaultPageSize={20}
        columns={COLUMNS}
        data={articles}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        // searchPlaceholder="Search by ..."
        // onClickRow={onClickRow}
        placeholder="No thought leadership"
        renderActions={renderActions}
        renderHeaderLeft={
          <>
            <GpsNotFixedRoundedIcon className="section-icon" />
            <span className="section-title f1">
              {t('manage_thought_leadership:manage_thought_leadership')}
            </span>
          </>
        }
        renderHeaderRight={
          <>
            <CreateArticle onCreateSuccess={refreshArticles} />
          </>
        }
      />
    </div>
  );
};

export default ManageThoughtLeadership;

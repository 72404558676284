import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      height: 'calc(100% - 64px)',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: 30,
    },
    title: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      lineHeight: 1.2,
      textAlign: 'center',
      whiteSpace: 'break-spaces',
      marginBottom: 20,
    },
    checkboxContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'flex-start',
      fontSize: '1.6rem',
      lineHeight: 1.13,
      color: '#838383',
      marginTop: 30,
    },
    checkboxText: {
      whiteSpace: 'pre',
    },
    buttonRed: {
      height: 50,
      fontSize: '2rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      color: '#fff',
      textTransform: 'capitalize',
      minWidth: '50%',
      margin: '15px 0',
      backgroundColor: '#0909B7',
      '&:hover': {
        backgroundColor: '#001A5B',
      },
    },
    contactUsMessage: {
      fontSize: '1.4rem',
      color: '#575757',
    },
    buttonContactUs: {
      fontSize: '1.4rem',
      color: '#2266cb',
    },
    buttonClose: {
      position: 'absolute',
      top: 16,
      right: 16,
    },
  }),
);

const TncModal = ({
  open,
  onClose,
  onClickContinue,
  viewOnly,
}: {
  open: boolean;
  onClose: () => void;
  onClickContinue: () => void;
  viewOnly?: boolean;
}) => {
  const classes = useStyles();
  const [agreeTnc, setAgreeTnc] = React.useState(false);

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby="feeback-dialog-title"
        fullWidth
        maxWidth="lg"
        classes={{ paper: classes.dialog }}
      >
        <DialogContent className={classes.container}>
          <div className={classes.title}>
            {'Terms and Conditions\n条款及条件'}
          </div>
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.buttonClose}
          >
            <ClearRoundedIcon htmlColor="#a5a5a5" fontSize="large" />
          </IconButton>
          <iframe
            src="/files/Issuer_Portal_TC.pdf#toolbar=0&navpanes=0&scrollbar=0&view=FitH&embedded=true"
            style={{ height: '100%', width: '100%' }}
          />
          {!viewOnly && (
            <>
              <div className={classes.checkboxContainer}>
                <Checkbox
                  checked={agreeTnc}
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                  onChange={(event) => {
                    setAgreeTnc(event.target.checked);
                  }}
                />
                <div className={classes.checkboxText}>
                  {
                    'I have read and agreed to the above Terms and Conditions.\n我已阅读并同意上述的条款及条件。'
                  }
                </div>
              </div>
              <Button
                className={classes.buttonRed}
                onClick={onClickContinue}
                variant="contained"
                disabled={!agreeTnc}
              >
                Continue 继续
              </Button>
            </>
          )}
          <div className={classes.contactUsMessage}>
            {'For any enquiry, please '}
            <a
              href="mailto:iphk.support@vistra.com"
              className={classes.buttonContactUs}
            >
              contact us
            </a>
          </div>
          <div className={classes.contactUsMessage}>
            如有查询，请
            <a
              href="mailto:iphk.support@vistra.com"
              className={classes.buttonContactUs}
            >
              联络我们
            </a>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TncModal;

import { format } from 'date-fns';
import { Director } from './Director';
import { Staff } from './Staff';
import { EntityResponse } from './EntityResponse';
import { Shareholder } from './Shareholder';
import { CompanySecretary } from './CompanySecretary';

export default class Entity {
  _id: number;

  fileCode: string;

  name: string;

  status: string;

  incorpNumber: string;

  incorpDate: string;

  incorpPlace: string;

  address: string;

  directors: Director[];

  shareholders: Shareholder[];

  secretaries: CompanySecretary[];

  relationManager?: Staff;

  jurisdiction: string;

  applicationStatus?: string;

  constructor(parameters: EntityResponse) {
    this._id = parameters.id;
    this.fileCode = parameters.fileCode;
    this.name = parameters.name;
    this.status = parameters.status || '';
    this.incorpNumber = parameters.incorpNr || '';
    this.incorpDate = parameters.incorporateDate;
    this.address = parameters.adline || '';
    this.directors = parameters.directors || [];
    this.shareholders = parameters.shareholders || [];
    this.secretaries = parameters.secretaries || [];
    this.relationManager = parameters.relationManager;
    this.incorpPlace = parameters.incorporatePlace || '-';
    this.jurisdiction = parameters.jurisdiction || '-';
    this.applicationStatus = parameters.applicationStatus || 'assigned';
  }

  public get incorpDateString(): string {
    return this.incorpDate
      ? format(new Date(this.incorpDate), 'yyyy-MM-dd')
      : '-';
  }

  public get id(): string {
    return this.fileCode;
  }
}

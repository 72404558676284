/* eslint-disable import/no-cycle */
import { makeStyles } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/GroupRounded';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Column } from 'react-table';
import AdminService from '../../../../api/admin';
import PaginationTable from '../../../../components/PaginationTable';
import { CompanyUser } from '../../../../models/CompanyUser';
import { UserReducerContext } from './duck';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 16,
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
  buttonOff: {
    width: 64,
    marginBottom: 4,
    textTransform: 'capitalize',
  },
  buttonOn: {
    width: 64,
    color: '#fff',
    backgroundColor: '#0909B7',
    '&:hover': {
      backgroundColor: '#001A5B',
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
    marginBottom: 4,
    textTransform: 'capitalize',
  },
}));

const TabClientChecker: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    state: { user, application },
    dispatch,
  } = React.useContext(UserReducerContext);
  const [checkers, setCheckers] = React.useState<
    (CompanyUser & { applicationStatus: string })[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const COLUMNS: Array<Column<
    CompanyUser & { applicationStatus: string }
  >> = React.useMemo(
    () => [
      {
        Header: 'manage_user:name',
        accessor: 'name',
        disableFilters: true,
        Cell: ({ value, row: { original } }) => {
          if (original.applicationStatus === 'pendingUnassign')
            return (
              <div style={{ textDecorationLine: 'line-through' }}>{value}</div>
            );
          return value || '-';
        },
      },
      {
        Header: 'manage_user:email',
        accessor: 'username',
        disableFilters: true,
        Cell: ({ value, row: { original } }) => {
          if (original.applicationStatus === 'pendingUnassign')
            return (
              <div style={{ textDecorationLine: 'line-through' }}>{value}</div>
            );
          return value || '-';
        },
      },
      {
        Header: 'manage_user:status',
        accessor: 'status',
        disableFilters: true,
        Cell: ({ value, row: { original } }) => {
          if (original.applicationStatus === 'pendingUnassign')
            return (
              <div style={{ textDecorationLine: 'line-through' }}>
                {t(`manage_user:${value.toLowerCase()}`)}
              </div>
            );
          return t(`manage_user:${value.toLowerCase()}`) || '-';
        },
      },
      {
        Header: 'manage_applications:approval_status',
        accessor: 'applicationStatus',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }) => {
          if (value === 'pendingAssign' || value === 'pendingUnassign')
            return 'Pending';
          return 'Assigned';
        },
      },
    ],
    [t],
  );

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      AdminService.getApplicationChecker(id, pageSize, pageIndex).then(
        ({ data: { data, totalPage } }) => {
          if (fetchId === fetchIdRef.current) {
            setCheckers(
              data.map((item) => {
                let status = 'assigned';
                if (user?.pendingAssignedCheckerIds?.includes(item.id)) {
                  status = 'pendingAssign';
                } else if (
                  user?.pendingUnassignedCheckerIds?.includes(item.id)
                ) {
                  status = 'pendingUnassign';
                }

                return { ...item, applicationStatus: status };
              }),
            );
            setPageCount(totalPage);
            setLoading(false);
          }
        },
      );
    },
    [user],
  );

  return (
    <div className={classes.container}>
      <PaginationTable
        defaultPageSize={15}
        columns={COLUMNS}
        data={checkers}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by name"
        placeholder={t('manage_user:rom_placeholder')}
        renderHeaderLeft={
          <>
            <GroupIcon className="section-icon" />
            <span className="section-title">
              {t(
                application?.role?.includes('Tricor')
                  ? 'manage_user:assigned_tricor_checker'
                  : 'manage_user:assigned_client_checker',
              )}
            </span>
          </>
        }
      />
    </div>
  );
};

export default TabClientChecker;

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import './index.css';
import './locales';
import * as serviceWorker from './serviceWorker';
import store from './store';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0909B7',
      dark: '#001A5B',
      light: '#8a8a8a',
    },
    text: {
      hint: '#0909B7',
      secondary: 'rgba(255, 255, 255, 0.5)',
    },
  },
});

const persistor = persistStore(store) as any;

if (process.env.REACT_APP_GA_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
    // debug: process.env.NODE_ENV === 'development',
  });
}

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { Button, IconButton, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import GpsNotFixedRoundedIcon from '@material-ui/icons/GpsNotFixedRounded';
import PublishIcon from '@material-ui/icons/Publish';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import AdminService from '../../../api/admin';
import { DialogContent, DialogTitle } from '../../../components/Dialog';
import PaginationTable from '../../../components/PaginationTable';
import { CompanyUser } from '../../../models/CompanyUser';
import { useAppDispatch } from '../../../store';
import { saveAsFile } from '../../../utils';
import { makerRequestSuccess } from '../../auth/duck/authDuck';
import {
  selectIsChecker,
  selectIsMaker,
  selectStaffInfo,
} from '../../auth/duck/selector';
import { selectLang } from '../../settings/duck/selector';
import CreateUserDialog from './createUser/CreateUserDialog';
import DeleteUserDialog from './DeleteUserDialog';

const useStyles = makeStyles(() => ({
  container: {
    '& .react-table .td': {
      minHeight: 34,
      lineHeight: 'unset',
      height: 'unset',
      whiteSpace: 'break-spaces',
      display: 'flex',
      alignItems: 'center',
    },
    '& div.td svg': {
      fill: '#0909B7',
    },
  },

  errorMessage: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
}));

const SelectCompany: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const inputFileRef = React.useRef<HTMLInputElement | null>();
  const [fileInputId, setFileInputId] = React.useState(0);
  const [error, setError] = React.useState('');

  const [data, setData] = React.useState<CompanyUser[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [createUserCount, setCreateUserCount] = React.useState(0);
  const [createSuccess, setCreateSuccess] = React.useState(false);
  const fetchIdRef = React.useRef(0);
  const isChecker = useSelector(selectIsChecker);
  const isMaker = useSelector(selectIsMaker);
  const staffInfo = useSelector(selectStaffInfo);
  const lang = useSelector(selectLang);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      const fetchId = ++fetchIdRef.current;
      setLoading(true);
      AdminService.getCompanyUsers(pageSize, pageIndex, query, sortBy).then(
        (res) => {
          if (fetchId === fetchIdRef.current) {
            setData(res.data);
            setPageCount(res.totalPage);
            setLoading(false);
          }
        },
      );
    },
    [],
  );

  const COLUMNS = React.useMemo<Array<Column<CompanyUser>>>(
    () => [
      {
        Header: 'manage_user:email',
        accessor: 'username',
        disableFilters: true,
      },
      {
        Header: 'manage_user:name',
        accessor: 'name',
        disableFilters: true,
      },
      {
        Header: 'manage_user:account_type',
        accessor: 'role',
        disableFilters: true,
        maxWidth: 120,
        Cell: ({ value }: any) =>
          value === 'ClientUser'
            ? 'User'
            : value === 'ClientMaker'
            ? 'Maker'
            : value === 'ClientChecker'
            ? 'Checker'
            : '-',
      },
      {
        Header: 'manage_user:hk_ticker',
        accessor: 'csTickerNos',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) =>
          value.slice(0, 3).join('\n') +
          (value.length > 4 ? '\n...' : '').toString(),
      },
      {
        Header: 'manage_user:rom_stock_codes',
        accessor: 'romList',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) =>
          value
            .slice(0, 3)
            .map((item: any) => item.insCode)
            .join('\n') + (value.length > 4 ? '\n...' : '').toString(),
      },
      {
        Header: 'manage_user:file_codes',
        accessor: 'emCodes',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) =>
          value.slice(0, 3).join('\n') +
          (value.length > 4 ? '\n...' : '').toString(),
      },
      {
        Header: 'manage_user:contact_person',
        accessor: 'contactPoints',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) =>
          value
            .slice(0, 3)
            .map((item: any) => item.staff?.name)
            .join('\n') + (value.length > 4 ? '\n...' : '').toString(),
      },
      {
        Header: 'manage_user:client_type',
        accessor: 'clientType',
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) =>
          value === 'CSandISClients'
            ? 'CS and IS clients'
            : value === 'ISOnlyClients'
            ? 'IS only clients'
            : value === 'CSOnlyClients'
            ? 'CS only clients'
            : '-',
      },
      {
        Header: 'manage_user:status',
        accessor: 'status',
        maxWidth: 100,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ value }: any) =>
          value ? t(`manage_user:${value.toLowerCase()}`) : '-',
      },
    ],
    [t],
  );

  const onClickDownloadReport = () => {
    AdminService.getUserReport()
      .then((res) => {
        saveAsFile(new Blob([res.data]), 'user-report.xlsx');
      })
      .catch((e) => {
        alert('Fail to download file');
      });
  };

  const onClickImport = () => {
    if (inputFileRef.current) {
      inputFileRef.current?.click();
    }
    // AdminService.importClient()
    //   .then((res) => {
    //     saveAsFile(new Blob([res.data]), 'user-report.xlsx');
    //   })
    //   .catch((e) => {
    //     alert('Fail to download file');
    //   });
  };

  const onClickRow = (item: CompanyUser) => {
    if (item.id) {
      history.push(`/admin/manage_user/${item.id}`);
    }
  };
  const refreshUserList = () => {
    setCreateUserCount((state) => state + 1);
  };

  const renderActions = (item: CompanyUser) => (
    <div className="table-row-actions">
      <IconButton
        aria-label="edit"
        onClick={() => {
          history.push(`/admin/manage_user/${item.id}`);
        }}
      >
        <EditRoundedIcon htmlColor="#a5a5a5" />
      </IconButton>
      {!isChecker && (
        <DeleteUserDialog user={item} onDeleteSuccess={refreshUserList} small />
      )}
    </div>
  );

  const onFileChange = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    AdminService.importClient(file)
      .catch((e) => {
        let errorMsgKey = 'message';
        if (lang === 'zh') errorMsgKey = 'messageZH';
        else if (lang === 'cn') errorMsgKey = 'messageCN';
        setError(e.response.data[errorMsgKey]);
      })
      .finally(() => {
        setFileInputId((state) => state + 1);
      });
  };

  return (
    <div className={classes.container}>
      <input
        type="file"
        id="file"
        key={`file-input-${fileInputId}`}
        ref={(ref) => {
          inputFileRef.current = ref;
        }}
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
      <PaginationTable
        key={createUserCount}
        defaultPageSize={10}
        columns={COLUMNS}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder="Search by user name or email"
        placeholder={t('manage_user:select_user_placeholder')}
        onClickRow={onClickRow}
        renderActions={renderActions}
        renderHeaderLeft={
          <>
            <GpsNotFixedRoundedIcon className="section-icon" />
            <span className="section-title">
              {t('manage_user:select_user')}
            </span>
          </>
        }
        renderHeaderRight={
          isChecker && staffInfo ? undefined : (
            <>
              {/* <Button
              variant="contained"
              color="primary"
              size="small"
              className="button-create"
              style={{ marginRight: 8 }}
              onClick={onClickDownloadReport}
            >
              {t('manage_user:users_data_download')}
            </Button> */}
              {staffInfo && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="button-create"
                  style={{ marginRight: 8 }}
                  onClick={onClickImport}
                  endIcon={<PublishIcon />}
                >
                  {t('manage_user:import')}
                </Button>
              )}
              <CreateUserDialog
                isUser
                onCreateSuccess={() => {
                  refreshUserList();
                  if (isMaker) {
                    dispatch(makerRequestSuccess());
                  } else {
                    setCreateSuccess(true);
                  }
                }}
              />
              <Dialog
                onClose={() => setCreateSuccess(false)}
                aria-labelledby="create-user-success-dialog-title"
                open={createSuccess}
              >
                <DialogTitle
                  id="create-user-success-dialog-title"
                  onClose={() => setCreateSuccess(false)}
                >
                  <div style={{ minWidth: 250 }}>Create user successfully</div>
                </DialogTitle>
              </Dialog>
            </>
          )
        }
      />
      <Dialog
        onClose={() => setError('')}
        aria-labelledby="error-dialog-title"
        open={Boolean(error)}
      >
        <DialogTitle id="error-dialog-title" onClose={() => setError('')}>
          Error
        </DialogTitle>
        <DialogContent>
          <div className={classes.errorMessage}>{error}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SelectCompany;

/* eslint-disable import/no-cycle */
import {
  Button,
  Dialog,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Theme,
  makeStyles,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUserRounded';
import { format } from 'date-fns';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import AdminService from '../../../../api/admin';
import AuthService from '../../../../api/auth';
import { DialogContent, DialogTitle } from '../../../../components/Dialog';
import PaginationTable from '../../../../components/PaginationTable';
import EditAccessRight from '../../users/EditAccessRight';
import { UserReducerContext } from '../duck';
// import DeleteUserDialog from '../DeleteUserDialog';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store';
import { makerRequestSuccess } from '../../../auth/duck/authDuck';
import { selectIsChecker, selectStaffInfo } from '../../../auth/duck/selector';
import { selectLang } from '../../../settings/duck/selector';
import DeleteStaffDialog from '../DeleteStaffDialog';

const formatDate = (date: string) => format(new Date(date), 'yyyy-MM-dd HH:mm');

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
  paper: {
    flex: 1,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#ffffff',
  },
  buttonAdd: {
    backgroundColor: '#0909B7',
    marginRight: 8,
    padding: '4px 8px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#001A5B',
    },
  },
  left: {
    height: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
  },
  table: {
    '& .MuiTableCell-root': {
      borderBottom: 'unset',
    },
  },
  label: {
    fontSize: '1.4rem',
    color: '#707070',
    flex: 1,
  },
  value: {
    flex: 2,
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: '#707070',
    whiteSpace: 'pre-line',
  },
  tickerInput: {
    borderRadius: 4,
    border: 'solid 1px #d9d9d9',
    fontSize: '1.4rem',
    minWidth: 300,
  },
  buttonRemove: {
    width: 64,
    marginBottom: 4,
    textTransform: 'capitalize',
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: 'rgba(255,255,255,0.6)',
    borderRadius: 4,
    top: 0,
    left: 0,
    fontSize: '1.6rem',
    fontWeight: 'bold',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    color: 'black',
  },
  dialogMessage: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    minWidth: 400,
  },
  buttonOK: {
    backgroundColor: '#0909B7',
    '&:hover': {
      backgroundColor: '#001A5B',
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelOK: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
}));

const COLUMNS: Array<Column<any>> = [
  {
    Header: 'manage_user:hk_ticker',
    accessor: 'tickerNo',
    disableFilters: true,
    Cell: ({ row: { original } }) => original.instruments?.[0]?.insCode,
    maxWidth: 250,
  },
  {
    Header: 'manage_user:name',
    accessor: 'entityName',
    disableFilters: true,
    Cell: ({ row: { original } }) => original.cmpEngName,
  },
];

const TabUserProfile: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('manage_user');
  const appDispatch = useAppDispatch();
  const {
    state: { user },
    dispatch,
  } = useContext(UserReducerContext);
  const myProfile = useSelector(selectStaffInfo);
  const isChecker = useSelector(selectIsChecker);
  const lang = useSelector(selectLang);
  const [ticker, setTicker] = React.useState('');
  const [tickers, setTickers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const fetchIdRef = React.useRef(0);

  const onEditAccessRightSuccess = () => {
    appDispatch(makerRequestSuccess());
    if (user) {
      AdminService.getStaff(user.id).then((res) => {
        dispatch({ type: 'SET_USER', payload: { user: res.data } });
      });
    }
  };

  const addTicker = () => {
    if (user) {
      setLoading(true);
      AdminService.addTicker(user.id, ticker)
        .then((res) => {
          if (!res.data.tickerExistsInCCASS) {
            setError(
              'The stock ticker added is not currently on our CCASS database. We will add the stock to our database and the data will appear in 2-3 days',
            );
          }
          return AdminService.getUserTickers(user.id);
        })
        .then((res) => {
          setTicker('');
          setTickers(res.data.data);
          appDispatch(makerRequestSuccess());
        })
        .catch(() => {
          setError('Invalid ticker');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy }) => {
      if (!user) return;
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      AdminService.getUserTickers(user.id, sortBy).then((res) => {
        if (fetchId === fetchIdRef.current) {
          setTickers(res.data.data);
          setLoading(false);
        }
      });
    },
    [user?.id],
  );

  const renderActions = (item: any) => {
    return (
      <div className="table-row-actions">
        <Button
          aria-label="remove"
          variant="contained"
          className={classes.buttonRemove}
          onClick={() => {
            if (!user) return;
            AdminService.removeTicker(user.id, item.instruments?.[0]?.insCode)
              .then(() => {
                setLoading(true);
                return AdminService.getUserTickers(user.id);
              })
              .then((res) => {
                setTickers(res.data.data);
              })
              .catch((e) => {
                let errorMsgKey = 'message';
                if (lang === 'zh') errorMsgKey = 'messageZH';
                else if (lang === 'cn') errorMsgKey = 'messageCN';
                setError(e.response.data[errorMsgKey]);
              })
              .finally(() => setLoading(false));
          }}
        >
          Remove
        </Button>
      </div>
    );
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="stretch"
      spacing={4}
      className={classes.root}
    >
      <Grid item xs={12} md={6} className={classes.left}>
        <div className={classes.column}>
          <div className="section-container">
            <InfoIcon className="section-icon" />
            <span className="section-title f1">
              {t('manage_user:user_info')}
            </span>
            {user && user.username !== myProfile?.username && !isChecker && (
              <DeleteStaffDialog
                user={user}
                onDeleteSuccess={() => {
                  history.goBack();
                }}
                onDeleteFailed={(message: string) => {
                  setError(message);
                }}
              />
            )}
          </div>
        </div>
        <Paper className={classes.paper}>
          <Table className={classes.table} aria-label="user infomation table">
            <TableBody>
              {[
                { label: t('name'), value: user?.name || '-' },
                { label: t('email'), value: user?.username || '-' },
                {
                  label: t('manage_company:phone_number'),
                  value:
                    user?.phoneNo && user?.areaCode
                      ? `+${user.areaCode}-${user.phoneNo}`
                      : '-',
                },
                {
                  label: t('status'),
                  value: user?.status
                    ? t(`manage_user:${user.status.toLowerCase()}`)
                    : '-',
                  renderButton: () => {
                    if (isChecker) return null;
                    let onPress;
                    let label = '';
                    switch (user?.status.toLowerCase()) {
                      case 'invitationsent':
                        onPress = () => {
                          AuthService.resendInvitation(user.username)
                            .then(() => {
                              alert('Invitation email reset.');
                            })
                            .catch((e) => {
                              let errorMsgKey = 'message';
                              if (lang === 'zh') errorMsgKey = 'messageZH';
                              else if (lang === 'cn') errorMsgKey = 'messageCN';
                              setError(e.response.data[errorMsgKey]);
                            });
                        };
                        label = t('resend');
                        break;
                      case 'active':
                        onPress = () => {
                          AuthService.resetPassword(user.username)
                            .then(() => {
                              alert('Reset password email sent.');
                            })
                            .catch((e) => {
                              let errorMsgKey = 'message';
                              if (lang === 'zh') errorMsgKey = 'messageZH';
                              else if (lang === 'cn') errorMsgKey = 'messageCN';
                              setError(e.response.data[errorMsgKey]);
                            });
                        };
                        label = t('reset_password');
                        break;
                      case 'locked':
                        onPress = () => {
                          if (user?.id)
                            AuthService.unlockUser(user.id.toString())
                              .then(onEditAccessRightSuccess)
                              .catch((e) => {
                                let errorMsgKey = 'message';
                                if (lang === 'zh') errorMsgKey = 'messageZH';
                                else if (lang === 'cn')
                                  errorMsgKey = 'messageCN';
                                setError(e.response.data[errorMsgKey]);
                              });
                        };
                        label = t('unlock');
                        break;
                      default:
                        return null;
                    }
                    if (onPress) {
                      return (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="button-create"
                          style={{ marginLeft: 12 }}
                          onClick={onPress}
                        >
                          {label}
                        </Button>
                      );
                    }

                    return null;
                  },
                },
                {
                  label: t('user_type'),
                  value:
                    user?.role === 'TricorSuperAdmin'
                      ? 'Tricor Super Admin'
                      : user?.role === 'TricorMaker'
                      ? 'Staff Maker'
                      : user?.role === 'TricorChecker'
                      ? 'Staff Checker'
                      : '-',
                },
                {
                  label: t('last_login'),
                  value: user?.lastLoginDate
                    ? formatDate(user.lastLoginDate)
                    : '-',
                },
                {
                  label: t('manage_staff:department'),
                  value: user?.dept || '-',
                },
                {
                  label: t('created_at'),
                  value: user?.createdAt ? formatDate(user.createdAt) : '-',
                },
              ].map(({ label, value, renderButton }) => (
                <TableRow key={`row-${label}`} style={{ verticalAlign: 'top' }}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.label}
                    colSpan={1}
                  >
                    {label}
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.value}
                    colSpan={2}
                  >
                    {value}
                    {renderButton && renderButton()}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <div style={{ height: 12 }} />
        <Paper className={classes.paper} style={{ position: 'relative' }}>
          <div className="section-title f1">{t('manage_user:hk_ticker')}</div>
          <div style={{ marginTop: 10 }}>
            <TextField
              className={classes.tickerInput}
              placeholder={t('manage_user:insert_hk_ticker')}
              value={ticker}
              onChange={(event) => setTicker(event.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.buttonAdd}
              style={{ marginLeft: 12 }}
              disabled={ticker.length === 0}
              onClick={addTicker}
            >
              {t('manage_user:add')}
            </Button>
          </div>
          <PaginationTable
            defaultPageSize={5}
            columns={COLUMNS}
            data={tickers}
            fetchData={fetchData}
            loading={loading}
            renderActions={renderActions}
            disableSearch
            hidePaginationControl
          />
          {user?.accessRights?.registerOfMember && (
            <div className={classes.overlay}>
              Access to ROM must be disabled to use this function
            </div>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="section-container">
          <VerifiedUserIcon className="section-icon" />
          <span className="section-title f1">
            {t('manage_user:access_rights')}
          </span>
          {!isChecker && user && (
            <EditAccessRight
              user={user}
              initialValues={{
                email: user.username,
                name: user.name,
                phoneNo: user.phoneNo || '',
                areaCode: user.areaCode || '',
                role: user.role,
                dept: user.dept,
                ...user.accessRights,
              }}
              onSuccess={onEditAccessRightSuccess}
            />
          )}
        </div>
        <Paper className={classes.paper}>
          <Table className={classes.table} aria-label="user infomation table">
            <TableBody>
              {[
                {
                  label: t('tabbar:entity_management'),
                  value: user?.accessRights?.entityManagement,
                },
                {
                  label: t('tabbar:register_of_members'),
                  value: user?.accessRights?.registerOfMember,
                },
                {
                  label: t('tabbar:ccass_holding'),
                  value: user?.accessRights?.ccassHolding,
                },
                {
                  label: t('tabbar:reports'),
                  value: user?.accessRights?.reports,
                },
                {
                  label: t('tabbar:shareholder_analytics'),
                  value: user?.accessRights?.ownershipAnalysis,
                },
                {
                  label: t('tabbar:market_intelligence'),
                  value: user?.accessRights?.marketIntelligence,
                },
                {
                  label: t('tabbar:industry_analyst_report'),
                  value: user?.accessRights?.industryAnalysis,
                },
                { label: t('tabbar:eagm'), value: user?.accessRights?.eAGM },
                // {
                //   label: t('tabbar:eproxy'),
                //   value: user?.accessRights?.eProxy,
                // },
                {
                  label: t('tabbar:board_portal'),
                  value: user?.accessRights?.boardPortal,
                },
                {
                  label: t('tabbar:invoice_management'),
                  value: user?.accessRights?.invoiceManagement,
                },
                {
                  label: t('tabbar:thought_leadership'),
                  value: user?.accessRights?.thoughtLeadership,
                },
              ].map(({ label, value }) => (
                <TableRow key={`row-${label}`}>
                  <TableCell component="td" className={classes.label}>
                    {label}
                  </TableCell>
                  <TableCell component="td" className={classes.value}>
                    <CheckIcon
                      htmlColor={value ? '#0909B7' : 'rgba(0,0,0,0.3)'}
                      fontSize="large"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
      <Dialog
        onClose={() => setError('')}
        aria-labelledby="ticker-dialog-title"
        open={!!error}
      >
        <DialogTitle id="ticker-dialog-title" onClose={() => setError('')}>
          Error
        </DialogTitle>
        <DialogContent>
          <div className={classes.dialogMessage}>{error}</div>
        </DialogContent>
        <div className={classes.bottomContainer}>
          <Button
            variant="contained"
            classes={{
              root: classes.buttonOK,
              label: classes.labelOK,
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setError('');
            }}
            disabled={loading}
          >
            {t('common:ok')}
          </Button>
        </div>
      </Dialog>
    </Grid>
  );
};

export default TabUserProfile;

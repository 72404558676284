import { Button, makeStyles, Theme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { clearMakerRequest } from '../pages/auth/duck/authDuck';
import { selectMakerSuccess } from '../pages/auth/duck/selector';
import { useAppDispatch } from '../store';
import { DialogContent, DialogTitle } from './Dialog';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '1.6rem',
    color: '#262626',
  },
  message: {
    fontSize: '1.4rem',
    lineHeight: 1.43,
    textAlign: 'center',
    color: '#707070',
    whiteSpace: 'pre-line',
    margin: '24px 0',
  },
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonRemove: {
    backgroundColor: '#0909B7',
    '&:hover': {
      backgroundColor: '#001A5B',
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelRemove: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  labelCancel: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#707070',
  },
}));

interface Props {}

const RequestSuccessDialog: React.FC<Props> = ({}) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const open = useSelector(selectMakerSuccess);

  const handleClose = React.useCallback(() => {
    dispatch(clearMakerRequest());
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="timeout-dialog-title"
      open={open}
      disableBackdropClick
    >
      <DialogTitle id="timeout-dialog-title" onClose={handleClose}>
        {/* <div className={classes.title}>{title}</div> */}
      </DialogTitle>
      <DialogContent>
        <div className={classes.message}>
          {'Request has been sent to your approver'}
        </div>
      </DialogContent>
      <div className={classes.bottomContainer}>
        <Button
          variant="contained"
          color="primary"
          classes={{
            root: classes.buttonRemove,
            label: classes.labelRemove,
          }}
          onClick={handleClose}
        >
          {t('ok')}
        </Button>
      </div>
    </Dialog>
  );
};

export default RequestSuccessDialog;

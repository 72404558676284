/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-cycle */
import {
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core';
import GroupIcon from '@material-ui/icons/GroupRounded';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import AdminService from '../../../../api/admin';
import AuthService from '../../../../api/auth';
import { TricorStaff } from '../../../../models/TricorStaff';
import { UserReducerContext } from './duck';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
  paper: {
    flex: 1,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#ffffff',
  },
  gridRight: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridLeft: {
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
  assignButtonOff: {
    color: '#fff',
    backgroundColor: '#0909B7',
    '&:hover': {
      backgroundColor: '#001A5B',
    },
  },
  assignButton: {
    minWidth: 64,
    marginBottom: 4,
    textTransform: 'capitalize',
    padding: '4px 8px',
    fontSize: '1rem',
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  buttonIcon: {
    fontSize: 14,
    marginLeft: 4,
  },
  name: {
    fontSize: '1.4rem',
    color: '#707070',
  },
  mail: {
    fontSize: '1.2rem',
    color: '#707070',
  },
  chip: {
    borderRadius: 4,
    marginLeft: 8,
    height: 'unset',
  },
  chipLabel: {
    fontSize: '1.2rem',
    paddingLeft: 4,
    paddingRight: 4,
  },
  tooltip: {
    fontSize: '1.2rem',
  },
  placeholderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // alignItems: 'center',
  },
  placeholder: {
    fontSize: '1.2rem',
    fontStyle: 'italic',
    color: '#b4b4b4',
  },
}));

const TabContactPersons: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('manage_user');
  const { id } = useParams();
  const {
    state: { user },
    dispatch,
  } = React.useContext(UserReducerContext);
  const [newContactPoints, setNewContactPoints] = React.useState<
    {
      companyUserId: number;
      createdAt: string;
      id: number;
      isPrimary: boolean;
      tricorId: string;
      staff: TricorStaff;
    }[]
  >([]);
  const [contactPoints, setContactPoints] = React.useState<
    {
      companyUserId: number;
      createdAt: string;
      id: number;
      isPrimary: boolean;
      tricorId: string;
      staff: TricorStaff;
    }[]
  >([]);

  React.useEffect(() => {
    if (user) {
      AuthService.getUserContactPoint(user.id).then((res) => {
        setContactPoints(res.data);
      });
    }
  }, [user]);

  React.useEffect(() => {
    AdminService.getApplicationContactPoint(id).then((res) => {
      setNewContactPoints([
        ...res.data.pendingAssignedContact,
        ...res.data.pendingUnassignedContact,
      ]);
    });
  }, [id, contactPoints.length]);

  return (
    <Grid
      container
      direction="row"
      alignItems="stretch"
      spacing={4}
      className={classes.root}
    >
      <Grid item xs={12} md={6} className={classes.gridLeft}>
        <div className="section-container">
          <GroupIcon className="section-icon" />
          <span className="section-title f1">
            {t('manage_user:management_team')}
          </span>
        </div>
        <Paper
          className={clsx(classes.paper, {
            [classes.placeholderContainer]:
              !user || user.contactPoints.length === 0,
          })}
        >
          {contactPoints && contactPoints?.length > 0 ? (
            <List>
              {contactPoints.map((item) => {
                const labelId = `checkbox-list-secondary-label-${item.tricorId}`;
                return (
                  <ListItem key={item.tricorId}>
                    {/* <ListItemAvatar>
                    <StaffAvatar />
                  </ListItemAvatar> */}
                    <ListItemText
                      id={labelId}
                      primary={
                        <>
                          {item.staff.name}
                          {item.isPrimary && (
                            <Chip
                              label={t('primary')}
                              color="primary"
                              classes={{
                                root: classes.chip,
                                label: classes.chipLabel,
                              }}
                            />
                          )}
                        </>
                      }
                      secondary={item.staff.mail}
                      classes={{
                        primary: classes.name,
                        secondary: classes.mail,
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <div className={classes.placeholder}>No changes</div>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12} md={6} className={classes.gridRight}>
        <div className="section-container">
          <GroupIcon className="section-icon" />
          <span className="section-title f1">New Management Team</span>
        </div>
        <Paper
          className={clsx(classes.paper, {
            [classes.placeholderContainer]: newContactPoints.length === 0,
          })}
        >
          {newContactPoints && newContactPoints?.length > 0 ? (
            <List>
              {newContactPoints.map((item) => {
                const labelId = `checkbox-list-secondary-label-${item.tricorId}`;
                return (
                  <ListItem key={item.tricorId}>
                    {/* <ListItemAvatar>
                    <StaffAvatar />
                  </ListItemAvatar> */}
                    <ListItemText
                      id={labelId}
                      primary={
                        <>
                          {item.staff.name}
                          {item.isPrimary && (
                            <Chip
                              label={t('primary')}
                              color="primary"
                              classes={{
                                root: classes.chip,
                                label: classes.chipLabel,
                              }}
                            />
                          )}
                        </>
                      }
                      secondary={item.staff.mail}
                      classes={{
                        primary: classes.name,
                        secondary: classes.mail,
                      }}
                    />
                  </ListItem>
                );
              })}
            </List>
          ) : (
            <div className={classes.placeholder}>No changes</div>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TabContactPersons;

/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { IconButton, makeStyles } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { UseFiltersColumnProps } from 'react-table';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectLang } from '../pages/settings/duck/selector';
import MultiLang from '../models/MultiLang';

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: '#fff',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.5)',
    },
    '&.on': {
      backgroundColor: 'red',
    },
  },
  formControl: {
    width: 0,
    overflow: 'hidden',
  },
  menuItem: {
    backgroundColor: '#838383 !important',
    fontSize: 13,
    fontWeight: 'bold',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#838383 !important',
    },
  },
  selected: {
    backgroundColor: '#838383',
    fontSize: 13,
    fontWeight: 'bold',
    color: 'red',
  },
}));

export interface ColumnProps<D extends object = {}> {
  column: UseFiltersColumnProps<D> & { id: string };
  customOptions?: MultiLang[];
}

const SelectColumnFilter: React.FC<ColumnProps> = ({
  column: { filterValue, setFilter, preFilteredRows, id },
  customOptions,
}) => {
  const classes = useStyles();
  const lang = useSelector(selectLang);
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    return false;
  };

  const handleClose = () => {
    setOpen(false);
    return false;
  };

  const options = React.useMemo(() => {
    if (customOptions) return customOptions;

    const options = new Set<string>();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });

    return Array.from(options.values()).map((item) => ({
      en: item,
      zh: item,
      cn: item,
    }));
  }, [id, preFilteredRows, customOptions]);

  return (
    <div>
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          handleOpen();
        }}
        classes={{ root: classes.button }}
        className={filterValue && 'on'}
      >
        <FilterListIcon
          fontSize="small"
          htmlColor={filterValue ? 'white' : '#454545'}
        />
      </IconButton>
      <FormControl className={classes.formControl}>
        <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          disableUnderline
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            getContentAnchorEl: null,
          }}
          value={filterValue || ''}
          onClick={(e: any) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            setFilter(e.target.value || undefined);
          }}
        >
          <MenuItem
            value=""
            classes={{ root: classes.menuItem, selected: classes.selected }}
          >
            <span style={{ marginTop: 3 }}>{t('common:all')}</span>
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem
              value={option.en}
              key={option.en}
              classes={{ root: classes.menuItem, selected: classes.selected }}
            >
              <span style={{ marginTop: 3 }}>{option[lang]}</span>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectColumnFilter;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { IconButton, makeStyles } from '@material-ui/core';
import { format } from 'date-fns';
import DownloadIcon from '@material-ui/icons/GetApp';
import { Column } from 'react-table';
import SelectColumnFilter, {
  ColumnProps,
} from '../../../components/SelectCloumnFilter';
import { RootState } from '../../../store';
import { selectCurrentEntityId } from './duck/selector';
import { Document } from '../../../models/Document';
import axios from '../../../api';
import PaginationTable from '../../../components/PaginationTable';
import EntityService from '../../../api/entity';
import MultiLang from '../../../models/MultiLang';
import { selectLang } from '../../settings/duck/selector';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 12,
    '& .react-table .tr .th:first-of-type': {
      maxWidth: 100,
    },
    '& .react-table .tr .td:first-of-type': {
      maxWidth: 100,
    },
    '& .react-table .table-row-actions': {
      visibility: 'visible',
    },
  },
}));

interface Props {
  entityId?: string | null;
}

export const RecentDocuments: React.FC<Props> = ({ entityId }) => {
  const { t } = useTranslation('entity_management');
  const classes = useStyles();

  const [documents, setDocuments] = React.useState<Document[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [options, setOptions] = React.useState<{
    [field: string]: MultiLang[];
  }>({});
  const lang = useSelector(selectLang);
  const fetchIdRef = React.useRef(0);

  const COLUMNS = React.useMemo<Array<Column<Document>>>(
    () => [
      {
        Header: 'entity_management:date',
        accessor: 'documentDate',
        disableFilters: true,
        Cell: ({ value }: any) => format(new Date(value), 'yyyy-MM-dd'),
      },
      {
        Header: 'entity_management:category',
        accessor: 'cabinet',
        filter: 'includes',
        disableFilters: !options.cabinet,
        Filter: (props: ColumnProps) => (
          <SelectColumnFilter {...props} customOptions={options?.cabinet} />
        ),
        Cell: ({ value }: any) => value[lang],
      },
      {
        Header: 'entity_management:description',
        accessor: 'pDescr',
        disableFilters: true,
        width: 200,
      },
    ],
    [t, options],
  );

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, query, sortBy, filters }) => {
      if (!entityId) return;
      const fetchId = ++fetchIdRef.current;

      setLoading(true);
      EntityService.getEntityDocuments(
        entityId,
        pageSize,
        pageIndex,
        query,
        sortBy?.id === 'incorpDateString'
          ? { ...sortBy, id: 'incorporateDate' }
          : sortBy,
        filters?.[0]?.id,
        filters?.[0]?.value,
      ).then(({ data: { data, totalPage } }) => {
        if (fetchId === fetchIdRef.current) {
          setDocuments(data);
          setPageCount(totalPage);
          setLoading(false);
        }
      });
    },
    [entityId],
  );

  React.useEffect(() => {
    if (entityId) {
      EntityService.getEntityDocumentsOptions(entityId).then((res) => {
        setOptions(res);
      });
    }
  }, [entityId]);

  const renderActions = (item: any) => (
    <div className="table-row-actions">
      <IconButton
        aria-label="download"
        onClick={() => {
          if (item.downloadLink) {
            axios
              .get(item.downloadLink, { responseType: 'blob' })
              .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', item.fileNames);
                document.body.appendChild(link);
                link.click();
              })
              .catch((e) => {
                alert('Fail to download file');
              });
          }
        }}
      >
        <DownloadIcon htmlColor="#a5a5a5" />
      </IconButton>
    </div>
  );

  return (
    <div className={classes.container}>
      <PaginationTable
        defaultPageSize={15}
        columns={COLUMNS}
        data={documents}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
        searchPlaceholder={t('search_recent_documents_placeholder')}
        placeholder={t('recent_documents_placeholder')}
        renderActions={renderActions}
      />
    </div>
  );
};
const mapStateToProps = (state: RootState) => ({
  entityId: selectCurrentEntityId(state),
});

export default connect(mapStateToProps)(RecentDocuments);

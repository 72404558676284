import React from 'react';
import { Avatar } from '@material-ui/core';
import AdminService from '../api/admin';

interface Props {
  staffId?: string | null;
  className?: string | undefined;
}

const StaffAvatar: React.FC<Props> = ({ staffId, className }) => {
  const [img, setImg] = React.useState<string | undefined>();

  React.useEffect(() => {
    if (staffId) {
      AdminService.getTricorStaffAvatar(staffId)
        .then((res) => {
          setImg(res.data);
        })
        .catch((e) => {
          // console.log(img res not found)
        });
    }
  }, [staffId]);

  return <Avatar className={className} src={img} />;
};

export default StaffAvatar;

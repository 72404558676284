import {
  Button,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Theme,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  format,
  getHours,
  isBefore,
  isSameDay,
  setHours,
  setMinutes,
} from 'date-fns';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { startOfDay } from 'date-fns/esm';
import CalendarService from '../../../../api/calendar';
import { DialogContent, DialogTitle } from '../../../../components/Dialog';
import { CalendarEvent } from '../../../../models/CalendarEvent';

interface FormValues
  extends Omit<
    CalendarEvent,
    'id' | 'colorCode' | 'isPublicHoliday' | 'title'
  > {
  title: string;
  startDate: Date;
  endDate: Date;
}
interface Props {
  defaultDate?: Date;
  romOid?: string;
  onEventCreated: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonAddEvent: {
      background: '#707070',
      padding: 4,
      '&:hover': {
        backgroundColor: '#707070',
        opacity: 0.8,
      },
    },
    dialog: {
      minWidth: '40%',
      [theme.breakpoints.down('sm')]: {
        minWidth: '80%',
      },
    },
    content: {},
    formContainer: {
      flex: 1,
      overflow: 'auto',
    },
    timeLabel: {
      fontSize: '1.2rem',
      color: '#262626',
      alignSelf: 'center',
      minWidth: 100,
    },
    dateInput: {
      backgroundColor: '#fff',
      '& input': {
        borderWidth: 0,
      },
      '& .MuiOutlinedInput-root': {
        marginTop: 4,
      },
      '& .MuiOutlinedInput-input': {
        padding: '6px !important',
      },
      '& .MuiIconButton-root': {
        padding: 0,
      },
      '& legend': {
        display: 'none',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'unset',
        transition: 'unset',
      },
    },
    allDay: {
      marginLeft: 0,
      marginRight: 0,
      minWidth: 120,
    },
    bottomContainer: {
      padding: '20px 0',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    buttonSubmit: {
      height: 33,
      //   borderRadius: 24,
      minWidth: 128,
      padding: '0 16px',
      textTransform: 'capitalize',
      backgroundColor: '#0909B7',
      '&:hover': {
        backgroundColor: '#001A5B',
      },
    },
    labelSubmit: {
      textTransform: 'capitalize',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: ' #fff',
    },
  }),
);

const CreateEvent = ({ romOid, onEventCreated, defaultDate }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('home');
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  const currentHour = getHours(new Date());

  const initialValues: FormValues = {
    title: '',
    description: '',
    startDate: startOfDay(defaultDate || new Date()),
    endDate: startOfDay(defaultDate || new Date()),
    startTime: `${currentHour.toLocaleString('en', {
      minimumIntegerDigits: 2,
    })}:00`,
    endTime: `${(currentHour + 1).toLocaleString('en', {
      minimumIntegerDigits: 2,
    })}:00`,
    isWholeDay: false,
  };

  const validate = (values: FormValues) => {
    const errors: { [name: string]: any } = {};
    if (!values.title.trim()) errors.title = t('error:required');
    if (!values.description.trim()) errors.description = t('error:required');

    const [startHours, startMins] = values.startTime.split(':');
    const [endHours, endMins] = values.endTime.split(':');

    if (isBefore(values.endDate, values.startDate)) {
      errors.startDate = t('common:start_date_should_before_end_date');
    } else if (
      isSameDay(values.endDate, values.startDate) &&
      !values.isWholeDay
    ) {
      if (
        Number(endHours) < Number(startHours) ||
        (Number(endHours) === Number(startHours) &&
          Number(endMins) <= Number(startMins))
      ) {
        errors.endTime = t('common:end_time_should_be_after_start_time');
      }
    }

    return errors;
  };

  const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    if (!romOid) return;
    const { startDate, endDate, startTime, endTime, ...others } = values;
    const [startHours, startMins] = startTime.split(':');
    const [endHours, endMins] = endTime.split(':');

    CalendarService.createEvent(romOid, {
      ...others,
      startTime: format(
        setHours(setMinutes(startDate, Number(startMins)), Number(startHours)),
        "yyyy-MM-dd'T'HH:mm",
      ),
      endTime: format(
        setHours(setMinutes(endDate, Number(endMins)), Number(endHours)),
        "yyyy-MM-dd'T'HH:mm",
      ),
    })
      .then(() => {
        onEventCreated();
        actions.resetForm();
        setOpen(false);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <div>
      <IconButton
        onClick={() => setOpen(true)}
        aria-label="add event"
        className={classes.buttonAddEvent}
        disabled={!romOid}
      >
        <AddIcon htmlColor="#fff" />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="create-event-dialog-title"
        open={open}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle id="create-event-dialog-title" onClose={handleClose}>
          {t('create_an_event')}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
            validateOnBlur
            initialTouched={{ endTime: true }}
          >
            {({ values, submitForm, isSubmitting, errors }) => (
              <>
                <div className={classes.formContainer}>
                  <Form autoComplete="off">
                    <Field
                      fullWidth
                      component={TextField}
                      name="title"
                      label={t('title')}
                    />
                    <div style={{ width: 20 }} />
                    <Field
                      fullWidth
                      component={TextField}
                      name="description"
                      label={t('description')}
                      multiline
                    />
                    <div className="row" style={{ margin: '12px 0' }}>
                      <div className={classes.timeLabel}>{t('start_time')}</div>
                      <Field
                        fullWidth
                        component={KeyboardDatePicker}
                        name="startDate"
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        InputAdornmentProps={{ position: 'end' }}
                        className={classes.dateInput}
                        maxDateMessage={t('common:date_must_before_2100')}
                        invalidDateMessage={t('common:please_enter_valid_date')}
                        minDateMessage={t('common:date_should_after_2021')}
                        minDate={new Date(2021, 0, 1)}
                      />
                      <div style={{ width: 20 }} />
                      <Field
                        fullWidth
                        component={TextField}
                        name="startTime"
                        type="time"
                        InputProps={{ disabled: values.isWholeDay }}
                      />
                      <Field
                        component={CheckboxWithLabel}
                        name="isWholeDay"
                        Label={{
                          label: t('all_day'),
                          classes: { root: classes.allDay },
                        }}
                        type="checkbox"
                      />
                    </div>
                    <div className="row">
                      <div className={classes.timeLabel}>{t('end_time')}</div>
                      <Field
                        fullWidth
                        component={KeyboardDatePicker}
                        name="endDate"
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        minDate={values.startDate}
                        InputAdornmentProps={{ position: 'end' }}
                        className={classes.dateInput}
                        maxDateMessage={t('common:date_must_before_2100')}
                        minDateMessage={t(
                          'common:start_date_should_before_end_date',
                        )}
                        invalidDateMessage={t('common:please_enter_valid_date')}
                      />
                      <div style={{ width: 20 }} />
                      <Field
                        fullWidth
                        component={TextField}
                        name="endTime"
                        type="time"
                        InputProps={{ disabled: values.isWholeDay }}
                      />
                      <div className={classes.allDay} />
                    </div>
                    <div className={classes.bottomContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        classes={{
                          root: classes.buttonSubmit,
                          label: classes.labelSubmit,
                        }}
                        disableElevation
                        onClick={submitForm}
                        disabled={
                          isSubmitting ||
                          Object.keys(errors).some(
                            (key: any) => (errors as any)[key],
                          ) ||
                          !values.title ||
                          !values.description
                        }
                      >
                        {t('common:submit')}
                      </Button>
                    </div>
                  </Form>
                </div>
              </>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateEvent;

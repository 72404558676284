import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './App.css';
import enLocale from 'date-fns/locale/en-US';
import cnLocale from 'date-fns/locale/zh-CN';
import zhLocale from 'date-fns/locale/zh-TW';
import { useTranslation } from 'react-i18next';
import format from 'date-fns/format';
import Login from './pages/auth/Login';
import ForgetPassword from './pages/auth/ForgetPassword';
import SetPassword from './pages/auth/SetPassword';
import RecoverExpired from './pages/auth/RecoverExpired';
import RecoverSuccess from './pages/auth/RecoverSuccess';
import Dashboard from './pages/client/Dashboard';
import AdminDashboard from './pages/admin/AdminDashboard';
import GoogleAnalytics from './GoogleAnalytics';
import StaffDashboard from './pages/staff/StaffDashboard';
import OTP from './pages/auth/OTP';

class ZhLocalizedUtils extends DateFnsUtils {
  getCalendarHeaderText(date: number | Date) {
    return format(date, 'yyyy年L月', { locale: this.locale });
  }

  getDatePickerHeaderText(date: number | Date) {
    return format(date, 'M月d日, 星期EEEEE', { locale: this.locale });
  }
}

const localeMap = {
  en: enLocale,
  zh: zhLocale,
  cn: cnLocale,
};

const localeUtilsMap = {
  en: DateFnsUtils,
  zh: ZhLocalizedUtils,
  cn: ZhLocalizedUtils,
};

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = localeMap[i18n.language as 'en' | 'zh' | 'cn'] || localeMap.en;
  const utils =
    localeUtilsMap[i18n.language as 'en' | 'zh' | 'cn'] || localeUtilsMap.en;
  const redirectToMaint = false;

  return (
    <MuiPickersUtilsProvider utils={utils} locale={locale}>
      <Router>
        {!redirectToMaint ?
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/otp" component={OTP} />
            <Route path="/recover/code" component={SetPassword} />
            <Route path="/recover/success" component={RecoverSuccess} />
            <Route path="/recover/expire" component={RecoverExpired} />
            <Route path="/recover" exact component={ForgetPassword} />
            <Route path="/admin" component={AdminDashboard} />
            <Route path="/" component={Dashboard} />
          </Switch> : 
          <Route path="/" component={(props: any) => {
            window.location.href = "https://www.tricorglobal.com/systemsuspensionnotice";
            return null;
          }} />
        }
        <GoogleAnalytics />
      </Router>
    </MuiPickersUtilsProvider>
  );
};

export default App;

/* eslint-disable import/no-cycle */
import { makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Fileservice from '../../../api/fileservice';
import ThoughtLeadershipService, {
  CreateArticleValue,
} from '../../../api/thoughtLeadership';
import { DialogContent, DialogTitle } from '../../../components/Dialog';
import ArticleForm from './ArticleForm';

const useStyles = makeStyles((theme: Theme) => ({
  bottomContainer: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  buttonCancel: {
    marginRight: 48,
  },
  buttonSubmit: {
    backgroundColor: '#0909B7',
    '&:hover': {
      backgroundColor: '#001A5B',
    },
    '& .MuiButton-endIcon': {
      marginLeft: 4,
    },
  },
  labelSubmit: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: ' #fff',
  },
  labelCancel: {
    textTransform: 'capitalize',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#707070',
  },
}));

const initialValues = {
  imageUrl: '',
  title: '',
  description: '',
  webLink: '',
  isFeatured: false,
};
interface Props {
  onCreateSuccess: () => void;
}

const CreateArticle: React.FC<Props> = ({ onCreateSuccess }) => {
  const classes = useStyles();
  const { t } = useTranslation('manage_thought_leadership');
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validate = (values: CreateArticleValue) => {
    const errors: any = {};
    if (!values.title) errors.title = 'Required';
    if (!values.description) errors.description = 'Required';
    if (!values.webLink) errors.webLink = 'Required';
    if (!values.imageFile) errors.imageFile = 'Required';

    return errors;
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        endIcon={<AddRoundedIcon />}
        className="button-create"
        onClick={handleClickOpen}
      >
        {t('common:create')}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="create-article-dialog-title"
        open={open}
      >
        <DialogTitle id="create-article-dialog-title" onClose={handleClose}>
          {t('create_article')}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={async (
              values,
              { setSubmitting, resetForm, setErrors },
            ) => {
              const { imageFile, ...others } = values;
              if (!values.imageFile) {
                setErrors({ imageFile: 'Required' });
                setSubmitting(false);
                return;
              }
              const imageUrl = await Fileservice.uploadArticleImage(
                values.imageFile,
              );
              ThoughtLeadershipService.createArticle({ ...others, imageUrl })
                .then(() => {
                  onCreateSuccess();
                  setSubmitting(false);
                  resetForm();
                  handleClose();
                })
                .catch((e) => {
                  setErrors({ title: 'Create article fail.' });
                })
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            {({ values, submitForm, isSubmitting }) => (
              <>
                <ArticleForm values={values} />
                <div className={classes.bottomContainer}>
                  <Button
                    variant="outlined"
                    classes={{
                      root: classes.buttonCancel,
                      label: classes.labelCancel,
                    }}
                    onClick={handleClose}
                    disabled={isSubmitting}
                  >
                    {t('common:cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    classes={{
                      root: classes.buttonSubmit,
                      label: classes.labelSubmit,
                    }}
                    onClick={submitForm}
                    disabled={isSubmitting}
                  >
                    {t('common:create')}
                  </Button>
                </div>
              </>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateArticle;

/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import LoginContainer from './LoginContainer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      lineHeight: 1.25,
      color: '#000',
    },
    description: {
      fontSize: '1.2rem',
      lineHeight: 1.17,
      color: '#575757',
      margin: '10px 0 18px 0',
    },
  }),
);

const RecoverExpired: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <LoginContainer canBack toPath="/">
      <>
        <div className={classes.label}>{t('login:reset_password_expired')}</div>
        <div className={classes.description}>
          {t('login:reset_password_expired_message')}
        </div>
      </>
    </LoginContainer>
  );
};

export default RecoverExpired;

import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Dialog,
  IconButton,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import cnLocale from 'date-fns/locale/zh-CN';
import zhLocale from 'date-fns/locale/zh-TW';
import { useSelector } from 'react-redux';
import { CalendarEvent } from '../../../../models/CalendarEvent';
import { DialogTitle, DialogContent } from '../../../../components/Dialog';
import { edit } from '../../../../assets';
import EditEvent from './EditEvent';
import { selectLang } from '../../../settings/duck/selector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minWidth: '40%',
      [theme.breakpoints.down('sm')]: {
        minWidth: '80%',
      },
    },
    content: {},
    formContainer: {
      flex: 1,
      overflow: 'auto',
    },
    dot: {
      height: 10,
      width: 10,
      borderRadius: 5,
      backgroundColor: '#eb3524',
    },
    title: {
      fontSize: '1.4rem',
      color: '#262626',
      fontWeight: 'bold',
      margin: '0 8px',
    },
    label: {
      marginTop: 8,
      fontSize: '1.2rem',
      lineHeight: 1.17,
      fontWeight: 'bold',
      color: '#262626',
      marginRight: 4,
    },
    value: {
      fontSize: '1.2rem',
      lineHeight: 1.17,
      color: '#262626',
    },
    edit: {
      height: 16,
      width: 16,
    },
  }),
);

interface Props {
  events?: CalendarEvent[];
  onClose?: () => void;
  refreshEvents: () => void;
}

const EventsDetail = ({ events, onClose, refreshEvents }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [editingEvent, setEditingEvent] = React.useState<CalendarEvent>();

  const lang = useSelector(selectLang);
  const { t } = useTranslation('home');

  React.useEffect(() => {
    if (events && events.length > 0) {
      setOpen(true);
    }
  }, [events]);

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const locale = {
    en: enLocale,
    zh: zhLocale,
    cn: cnLocale,
  }[lang];

  return (
    <div>
      <Dialog
        onClose={handleClose}
        onExited={onClose}
        aria-labelledby="up-coming-event-detail-dialog-title"
        open={open}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle
          id="up-coming-event-detail-dialog-title"
          onClose={handleClose}
        >
          {t('upcoming_event_detail')}
        </DialogTitle>
        <DialogContent className={classes.content}>
          {events?.map((event) => (
            <div style={{ marginBottom: 24 }}>
              <div className="row" style={{ alignItems: 'center' }}>
                <div className={classes.dot} />
                <span className={classes.title}>{event.title[lang]}</span>
                {!event.isPublicHoliday && (
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                      setEditingEvent(event);
                    }}
                  >
                    <img src={edit} alt="edit" className={classes.edit} />
                  </IconButton>
                )}
              </div>
              <div style={{ marginTop: 8 }}>
                <span className={classes.label}>{`${t('start')}:`}</span>
                <span className={classes.value}>
                  {format(
                    new Date(event.startTime),
                    lang === 'en'
                      ? `dd MMMM yyyy${event.isWholeDay ? '' : ' hh:mm a'}`
                      : `yyyy年MM月dd日${event.isWholeDay ? '' : ' hh:mm a'}`,
                    { locale },
                  )}
                </span>
              </div>
              <div style={{ marginTop: 8 }}>
                <span className={classes.label}>{`${t('end')}:`}</span>
                <span className={classes.value}>
                  {format(
                    new Date(event.endTime),
                    lang === 'en'
                      ? `dd MMMM yyyy${event.isWholeDay ? '' : ' hh:mm a'}`
                      : `yyyy年MM月dd日${event.isWholeDay ? '' : ' hh:mm a'}`,
                    { locale },
                  )}
                </span>
              </div>
              <div className={classes.label} style={{ marginTop: 8 }}>
                {`${t('description')}:`}
              </div>
              <div className={classes.value}>
                {event.isPublicHoliday
                  ? t('hk_public_holiday')
                  : event.description}
              </div>
            </div>
          ))}
        </DialogContent>
      </Dialog>
      <EditEvent
        event={editingEvent}
        onClose={() => setEditingEvent(undefined)}
        refreshEvents={refreshEvents}
      />
    </div>
  );
};

export default EventsDetail;
